<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="salary" />
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Empoyee Salary</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" />
                            <a class="btn"><img src="@/assets/img/icons/search-normal.svg" alt="" /></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <router-link to="add-salary" class="btn btn-primary add-pluss ms-2"
                            ><img src="@/assets/img/icons/plus.svg" alt=""
                          /></router-link>
                          <a href="javascript:;" class="btn btn-primary doctor-refresh ms-2"
                            ><img src="@/assets/img/icons/re-fresh.svg" alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"><img src="@/assets/img/icons/pdf-icon-01.svg" alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="@/assets/img/icons/pdf-icon-02.svg" alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="@/assets/img/icons/pdf-icon-03.svg" alt="" /></a>
                    <a href="javascript:;"><img src="@/assets/img/icons/pdf-icon-04.svg" alt="" /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->
              <div class="staff-search-table">
                <form>
                  <div class="row">
                    <!-- <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms">
                        <label>Employee Name </label>
                        <input class="form-control" type="text" />
                      </div>
                    </div> -->
                    <!-- <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms">
                        <label>Choose what to pay </label>
                        <vue-select :options="Pharmacist" id="pharmacist" placeholder="Select Payment options" />
                      </div>
                    </div> -->
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms">
                        <label>Choose what to pay </label>
                        <Multiselect
                          v-model="selectedOptions"
                          :options="Pharmacist"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          label="label"
                          track-by="value"
                          :preselect-first="true"
                        >
                          <template #selection="{ values, isOpen }">
                            <div class="d-flex gap-2">
                              <span class="" v-for="val in values" :key="val.value" v-show="!isOpen"
                                >{{ val.label }},</span
                              >
                            </div>
                          </template>
                        </Multiselect>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms cal-icon">
                        <label>Date </label>
                        <datepicker
                          v-model="startdate"
                          :input-format="dateFormat"
                          class="form-control datetimepicker"
                          :editable="true"
                          :clearable="false"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <!-- <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms cal-icon">
                        <label>To </label>
                        <datepicker
                          v-model="startdateOne"
                          :input-format="dateFormat"
                          class="form-control datetimepicker"
                          :editable="true"
                          :clearable="false"
                          placeholder=""
                        />
                      </div>
                    </div> -->
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="doctor-submit">
                        <button
                          @click="$router.push({ path: '/payroll/view-payment' })"
                          type="button"
                          class="btn btn-primary submit-list-form me-2"
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="table-responsive">
                <a-table
                  class="table border-0 custom-table comman-table datatable mb-0"
                  :columns="columns"
                  :data-source="allStaff"
                  :row-selection="{}"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'EmployeeName'">
                      <div class="profile-image">
                        <router-link to="/profile"
                          ><img
                            width="28"
                            height="28"
                            :src="require(`@/assets/img/profiles/${record.Avatar}`)"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          {{ record.EmployeeName }}</router-link
                        >
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Role'">
                      <div>
                        <div class="dropdown action-label">
                          <a
                            class="custom-badge status-purple dropdown-toggle"
                            href="javascript:;"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {{ record.Role }}
                          </a>
                          <div class="dropdown-menu dropdown-menu-end status-staff">
                            <a class="dropdown-item" href="javascript:;">Developer</a>
                            <a class="dropdown-item" href="javascript:;">Accountant</a>
                            <a class="dropdown-item" href="javascript:;">Office Assistant</a>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <div>
                        <router-link class="btn btn-sm btn-danger" to="salary-view">{{ record.Status }}</router-link>
                      </div>
                    </template>

                    <template v-else-if="column.key === 'Salary'">
                      <div>{{ formatAmount(Number(record.Salary)) }}</div>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="javascript:;"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <router-link class="dropdown-item" to="edit-salary"
                              ><i class="fa-solid fa-pen-to-square m-r-5"></i> Edit</router-link
                            >
                            <a
                              class="dropdown-item"
                              href="javascript:;"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <delete></delete>
</template>

<script>
import { helperFunctions } from "@/composables/helperFunctions";
import Multiselect from "vue-multiselect";
import { useProfileStore } from "@/stores/profile.js";

const { formatAmount } = helperFunctions;

const columns = [
  {
    title: "Employee ID",
    dataIndex: "EmployeeID",
    sorter: {
      compare: (a, b) => {
        a = a.EmployeeID.toLowerCase();
        b = b.EmployeeID.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Employee Name",
    dataIndex: "EmployeeName",
    key: "EmployeeName",
    sorter: {
      compare: (a, b) => {
        a = a.EmployeeName.toLowerCase();
        b = b.EmployeeName.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email",
    dataIndex: "Email",
    sorter: {
      compare: (a, b) => {
        a = a.Email.toLowerCase();
        b = b.Email.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Joining Date",
    dataIndex: "JoiningDate",
    sorter: {
      compare: (a, b) => {
        a = a.JoiningDate.toLowerCase();
        b = b.JoiningDate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Role",
    dataIndex: "Role",
    key: "Role",
    sorter: {
      compare: (a, b) => {
        a = a.Role.toLowerCase();
        b = b.Role.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Salary",
    dataIndex: "Salary",
    key: "Salary",
    sorter: {
      compare: (a, b) => {
        a = a.Salary.toLowerCase();
        b = b.Salary.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "",
    key: "action",
    sorter: true,
    class: "text-end",
  },
];

const data = [
  {
    id: "1",
    Avatar: "avatar-01.jpg",
    EmployeeID: "LAWMA-1562",
    EmployeeName: "Michael Anorue",
    Email: "anoruemichael@gmail.com",
    JoiningDate: "22/04/2014",
    Role: "Developer",
    Salary: formatAmount(200000),
    Status: "Unpaid",
  },
  {
    id: "2",
    Avatar: "avatar-03.jpg",
    EmployeeID: "LAWMA-2302",
    EmployeeName: "Daniel Folarinde",
    Email: "michaelchigozie51@gmail.com",
    JoiningDate: "30/03/2024",
    Role: "Engineer",
    Salary: formatAmount(200000),
    Status: "Unpaid",
  },
];
import { ref, computed } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
  components: { Multiselect },
  setup() {
    const profile = useProfileStore();
    const { readUser } = profile;
    const { formatDateString, formatAmount } = helperFunctions;

    const allStaff = computed(() => {
      const data =
        profile.getAllStaff &&
        profile.getAllStaff.map((item) => {
          return {
            id: item._id,
            EmployeeName: `${item.personalInformation.firstName} ${item.personalInformation.lastName}`,
            EmployeeID: item.personalInformation?.userName || "N/A",
            Department: (item.department && item.department.name) || "N/A",
            Salary: item.salary && item.salary.grossSalary,
            Role: item.position,
            Email: item.contactInformation?.email,
            JoiningDate: formatDateString(item.dateOfJoining),
            Avatar: "avatar-01.jpg",
            Status: "Unpaid",
          };
        });
      return data;
    });
    return { formatAmount, readUser, allStaff };
  },
  data() {
    return {
      title: "Payroll",
      text: "Empoyee Salary",
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      Pharmacist: [
        { label: "Net Salary", value: "Net Salary" },
        { label: "Statutory deductions", value: "Statutory deductions" },
        { label: "Bonus", value: "Bonus" },
      ],
      selectedOptions: [],
      Pending: ["Select Leave Status", "Pending", "Approved", "Rejected"],
      columns,
      data,
    };
  },
  methods: {
    async readAllStaff() {
      try {
        const res = await this.readUser({}, "GetAllStaff", "staffs");
        if (res) {
          console.log(res);
          // toaster.success(`Staff read successfully.`);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
    async deleteStaff() {
      try {
        const res = await this.readUser({}, { id: "id" }, "DeleteUser");
        if (res) {
          toaster.success(`Staff deleted successfully.`);
        }
      } catch (error) {
        toaster.error(`Failed to delete staff.`);
      }
    },
  },
  async created() {
    await this.readAllStaff();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
