import placeholdersVue from '../uiinterface/baseui/placeholders.vue';
<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="/appointments" />
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="updateAppointment">
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Visitor Details</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>First Name <span class="login-danger">*</span></label>
                      <input required v-model="args.firstName" class="form-control" type="text" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Last Name <span class="login-danger">*</span></label>
                      <input required v-model="args.lastName" class="form-control" type="text" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block select-gender">
                      <label class="gen-label">Gender<span class="login-danger">*</span></label>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            name="gender"
                            class="form-check-input"
                            value="MALE"
                            :checked="args.gender === 'MALE'"
                            @change="($event) => (args.gender = $event.target.value)"
                          />Male
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            name="gender"
                            class="form-check-input"
                            value="FEMALE"
                            :checked="args.gender === 'FEMALE'"
                            @change="($event) => (args.gender = $event.target.value)"
                          />Female
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Mobile <span class="login-danger">*</span></label>
                      <input v-model="args.phoneNumber" class="form-control" type="text" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Email <span class="login-danger">*</span></label>
                      <input v-model="args.email" class="form-control" type="email" />
                    </div>
                  </div>
                  <!-- <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label>Address <span class="login-danger">*</span></label>
                      <textarea
                        class="form-control"
                        rows="3"
                        cols="30"
                      ></textarea>
                    </div>
                  </div> -->
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Appointment Details</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms cal-icon">
                      <label>Date of Appointment <span class="login-danger">*</span></label>
                      <datepicker
                        v-model="args.appointmentDetails.date"
                        :input-format="dateFormat"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>From <span class="login-danger">*</span></label>
                      <div class="time-icon">
                        <a-time-picker
                          id="datetimepicker3"
                          class="form-control"
                          :placeholder="
                            args.appointmentDetails.startTime ? String(args.appointmentDetails.startTime) : '11:00 AM'
                          "
                          use12-hours
                          format="h:mm a"
                          @change="($event) => (args.appointmentDetails.startTime = $event)"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>To <span class="login-danger">*</span></label>
                      <div class="time-icon">
                        <a-time-picker
                          id="datetimepicker4"
                          class="form-control"
                          :placeholder="
                            args.appointmentDetails.endTime ? String(args.appointmentDetails.endTime) : '11:00 AM'
                          "
                          use12-hours
                          format="h:mm a"
                          @change="
                            ($event) => {
                              console.log($event);

                              args.appointmentDetails.endTime = $event;
                            }
                          "
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Consulting Doctor</label>
                      <vue-select
                        :options="Consulting"
                        id="consulting"
                        placeholder="Select Doctor"
                      />
                    </div>
                  </div> -->
                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Treatment </label>
                      <input class="form-control" type="text" />
                    </div>
                  </div> -->
                  <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label>Notes <span class="login-danger">*</span></label>
                      <textarea
                        class="form-control"
                        rows="3"
                        cols="30"
                        v-model="args.appointmentDetails.note"
                      ></textarea>
                    </div>
                  </div>
                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-top-form">
                      <label class="local-top"
                        >Avatar <span class="login-danger">*</span></label
                      >
                      <div class="settings-btn upload-files-avator">
                        <input
                          type="file"
                          accept="image/*"
                          name="image"
                          id="file"
                          onchange="loadFile(event)"
                          class="hide-input"
                        />
                        <label for="file" class="upload">Choose File</label>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button type="submit" class="btn btn-primary submit-form me-2">Submit</button>
                      <button type="submit" class="btn btn-primary cancel-form">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { router } from "@/router";
import { useProfileStore } from "@/stores/profile.js";

import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-right",
  showCloseButton: true,
  duration: 5000,
});
const currentDate = new Date();
export default {
  setup() {
    const profile = useProfileStore();
    const { createUser, readUser } = profile;

    return {
      createUser,
      readUser,
    };
  },
  data() {
    return {
      title: "Appointment",
      text: "Book Appointment",
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      Consulting: ["Select Doctor", "Dr.Bernardo James", "Dr.Andrea Lalema", "Dr.William Stephin"],
      args: {
        firstName: "",
        lastName: "",
        gender: "",
        phoneNumber: "",
        email: "",
        appointmentDetails: {
          date: currentDate,
          startTime: "",
          endTime: "",
          note: "",
        },
      },
    };
  },
  methods: {
    convertTime(givenTime) {
      // Current date
      const timeAMPM = givenTime.toLocaleTimeString("en-US", { hour12: true }).replace(/:\d{2}\s/, " ");

      return timeAMPM;
    },
    async readAppointments() {
      try {
        const res = await this.readUser(
          { getAppointmentId: this.$route.query.id },
          "GetAppointment",
          "singleAppointment"
        );
        if (res) {
          //assign the response to the args individually
          this.args.firstName = res.firstName;
          this.args.lastName = res.lastName;
          this.args.gender = res.gender;
          this.args.phoneNumber = res.phoneNumber;
          this.args.email = res.email;
          this.args.appointmentDetails.date = new Date(res.appointmentDetails.date);
          this.args.appointmentDetails.startTime = this.convertTime(new Date(res.appointmentDetails.startTime));
          this.args.appointmentDetails.endTime = this.convertTime(new Date(res.appointmentDetails.endTime));
          this.args.appointmentDetails.note = res.appointmentDetails.note;

          // toaster.success(`Staff read successfully.`);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
    async updateAppointment() {
      try {
        // this.args.appointmentDetails.startTime =
        //   this.args.appointmentDetails.startTime.split(" ")[0];
        // this.args.appointmentDetails.endTime =
        //   this.args.appointmentDetails.endTime.split(" ")[0];
        const res = await this.createUser(
          {},
          { updateAppointmentId: this.$route.query.id, input: this.args },
          "UpdateAppointment"
        );
        if (res) {
          toaster.success(`Appointment updated successfully.`);

          router.push("/appointments/appointments-list");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  async created() {
    await this.readAppointments();
  },
};
</script>
