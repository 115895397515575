<template>
  <div class="col-xl-8">
    <div class="card chat-box mb-2">
      <div class="chat-search-group">
        <div class="chat-user-group mb-0 d-flex align-items-center">
          <div class="top-check me-3">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" />
            </div>
          </div>
          <div class="top-inbox-blk comman-flex me-3">
            <a href="javascript:;" data-bs-toggle="dropdown" aria-expanded="false" class="">
              <i class="feather-more-vertical"></i>
            </a>
            <div class="dropdown-menu" style="">
              <a class="dropdown-item" href="javascript:;"> Reply</a>
              <a class="dropdown-item" href="javascript:;"> Forward</a>
              <a class="dropdown-item" href="javascript:;"> Archived</a>
              <a class="dropdown-item" href="javascript:;"> Mark as Read</a>
              <a class="dropdown-item" href="javascript:;"> Mark as Unread</a>
              <a class="dropdown-item" href="javascript:;"> Delete</a>
            </div>
          </div>
          <div class="top-liv-search top-chat-search top-action-search">
            <form>
              <div class="chat-search mb-0">
                <div class="input-block me-2 mb-0">
                  <input type="text" class="form-control" placeholder="Search here" />
                  <a class="btn"><img src="@/assets/img/icons/search-normal.svg" alt="" /></a>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="top-action-right">
          <ul class="nav">
            <li @click="emit('fetch')">
              <a href="javascript:;"><img src="@/assets/img/icons/refresh-icon.svg" alt="img" /></a>
            </li>
            <li>
              <a href="javascript:;"><img src="@/assets/img/icons/tag-icon-04.svg" alt="img" /></a>
            </li>
            <li>
              <a href="javascript:;"><img src="@/assets/img/icons/inbox.svg" alt="img" /></a>
            </li>
            <li>
              <a href="javascript:;"><img src="@/assets/img/icons/folder-icon-05.svg" alt="img" /></a>
            </li>
            <li>
              <a href="javascript:;"><img src="@/assets/img/icons/trash.svg" alt="img" /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div style="height: 100vh; overflow-y: auto" class="email-content">
      <div class="table-responsive">
        <table class="table table-inbox table-hover">
          <tbody>
            <tr
              v-for="(mail, i) in listOfEmails"
              :key="i"
              @click="handleEmit(mail)"
              class="clickable-row"
              :class="mail.read == true ? undefined : 'unread'"
              data-href="mail-view"
            >
              <td>
                <div class="top-check">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" />
                  </div>
                </div>
              </td>
              <!-- <td>
                <span class="mail-important"><i class="fas fa-star starred"></i></span>
              </td> -->
              <td class="name">
                <div class="email-img-blk">
                  <div
                    :style="`background-color:${getAvatar(mail.from.value[0].name || mail.from.text).color}`"
                    style="
                      text-transform: uppercase;
                      text-align: center;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      color: white;
                      width: 48px;
                      height: 48px;
                      margin-right: 10px;
                      border-radius: 50%;
                    "
                    class="email-img"
                  >
                    {{ getAvatar(mail.from.value[0].name || mail.from.text).name }}
                    <!-- <img style="object-fit: cover" src="@/assets/img/person-3.jpeg" alt="img" /> -->
                  </div>
                  <div class="send-user">
                    <h4 :class="mail.read ? 'read-msg' : undefined" v-if="mail && mail.from">
                      {{ mail.from.value[0].name || mail.from.text }}
                      <!-- <span class="email-market">Work</span> -->
                    </h4>
                    <p v-html="mail && mail.subject && truncateString(mail.subject, 50)"></p>
                  </div>
                </div>
              </td>
              <td></td>
              <td class="mail-date">
                <img
                  v-if="mail.attachments && mail.attachments.length"
                  src="@/assets/img/icons/clip.svg"
                  class="me-2"
                  alt="img"
                />{{ convertDate(mail.date) }}
              </td>
            </tr>
            <!-- <tr class="unread clickable-row" data-href="mail-view">
              <td>
                <div class="top-check">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" />
                  </div>
                </div>
              </td>
              <td>
                <span class="mail-important"><img src="@/assets/img/icons/star-empty.svg" alt="img" /></span>
              </td>
              <td class="name">
                <div class="email-img-blk">
                  <div class="email-img">
                    <img style="object-fit: cover" src="@/assets/img/person-2.jpeg" alt="img" />
                  </div>
                  <div class="send-user">
                    <h4>Tobi Ijaye" <span class="email-bills">Bills</span></h4>
                    <p>Fwd: Receipt and invoices for 2016-2020.</p>
                  </div>
                </div>
              </td>
              <td></td>
              <td class="mail-date">8:42</td>
            </tr> -->
            <!-- <tr class="clickable-row" data-href="mail-view">
              <td>
                <div class="top-check">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" />
                  </div>
                </div>
              </td>
              <td>
                <span class="mail-important"><img src="@/assets/img/icons/star-empty.svg" alt="img" /></span>
              </td>
              <td class="name">
                <div class="email-img-blk">
                  <div class="email-img">
                    <img style="object-fit: cover" src="@/assets/img/person-1.jpeg" alt="img" />
                  </div>
                  <div class="send-user">
                    <h4>Kunle Adewale <span class="email-work">Work</span></h4>
                    <p>Fwd: Quarterly report and operations feedback</p>
                  </div>
                </div>
              </td>
              <td></td>
              <td class="mail-date"><img src="@/assets/img/icons/clip.svg" class="me-2" alt="img" />13:14</td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useProfileStore } from "@/stores/profile.js";
import { computed } from "vue";

import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const props = defineProps({
  emailData: { type: Array, default: () => [] },
});
const emit = defineEmits(["read", "fetch"]);
const profile = useProfileStore();

const { readUser } = profile;
const listOfEmails = computed(() => props.emailData || []);
function isToday(date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}
function getColor(index) {
  let n = (index * 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}
function getAvatar(string) {
  let name = "",
    color;
  // Get color
  let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

  const initialOne = String(string.charAt(0));
  const initialTwo = String(string.charAt(1));
  const num = Number(alphabet.indexOf(initialOne.toLowerCase())) + Number(alphabet.indexOf(initialTwo.toLowerCase()));
  color = getColor(num);

  // Get Shortend Name
  // string ? (name = `${string.charAt(0)}${string.charAt(1)}`) : "";
  string ? (name = `${string.charAt(0)}`) : "";

  return {
    color,
    name,
  };
}
function isSameYear(date) {
  const today = new Date();
  return date.getFullYear() === today.getFullYear();
}
async function markAsRead(messageId) {
  try {
    await readUser({ messageId }, "MarkAsRead", "mark");
  } catch (error) {
    console.error(`Error: ${error}`);
  }
}
async function handleEmit(obj) {
  markAsRead(obj.messageId);
  router.push({ path: "/email/inbox", query: { messageId: obj.messageId, oauth_token: route.query.oauth_token } });
  emit("read", true);
}
function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}
function convertDate(str) {
  const dateStr = str;
  const date = new Date(dateStr);
  const bool = isToday(date);
  const yearBool = isSameYear(date);
  const formattedFullDate = date.toLocaleDateString("en-US", {
    // weekday: "long",
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  const formattedDate = date.toLocaleDateString("en-US", {
    // weekday: "long",
    month: "short",
    day: "numeric",
    // year: "numeric",
  });
  const formattedTime = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  if (!yearBool) {
    return formattedFullDate;
  } else if (bool) {
    return formattedTime;
  } else {
    formattedDate;
  }
  return formattedDate;
  // return { date: formattedDate, time: formattedTime, bool, yearBool };
}
</script>

<style></style>
