<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="$route.query.id ? 'Edit task' : text" path="task" />
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="$route.query.id ? updateTask() : createTask()">
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Task Details</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Task Title <span class="login-danger">*</span></label>
                      <input
                        class="form-control"
                        type="text"
                        maxlength="30"
                        required
                        v-model="args.taskName"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Assign To <span class="login-danger">*</span></label>
                      <!-- <input class="form-control" type="text" placeholder="" /> -->
                      <!-- <vue-select :options="allStaff" placeholder="Select Staff" v-model="args.assignedTo" /> -->
                      <multiselect
                        v-model="selectedColleagues"
                        :options="allStaff"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Select Staff"
                        label="label"
                        track-by="value"
                        :preselect-first="false"
                      >
                        <template #selection="{ values, isOpen }">
                          <span
                            style="font-size: 14px"
                            class="multiselect__single"
                            v-if="values.length"
                            v-show="!isOpen"
                            >{{ values.length }} Selected</span
                          >
                        </template>
                      </multiselect>
                    </div>
                  </div>
                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Task Category <span class="login-danger">*</span></label>
                      <vue-select :options="Taskcategory" id="taskcategory" placeholder="Choose Task Category" />
                    </div>
                  </div> -->
                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Task Sub Category <span class="login-danger">*</span></label>
                      <vue-select
                        :options="Tasksubcategory"
                        id="tasksubcategory"
                        placeholder="Choose Sub Task Category"
                      />
                    </div>
                  </div> -->
                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Tags <small>(separated with a comma)</small> <span class="login-danger">*</span></label>
                      <input type="text" data-role="tagsinput" class="form-control" />
                    </div>
                  </div> -->
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Start date <span class="login-danger">*</span></label>
                      <div class="time-icon">
                        <datepicker
                          :input-format="dateFormat"
                          v-model="args.projectStart"
                          class="form-control datetimepicker"
                          :editable="true"
                          :clearable="false"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>End date <span class="login-danger">*</span></label>
                      <div class="time-icon">
                        <datepicker
                          :input-format="dateFormat"
                          v-model="args.projectEnd"
                          class="form-control datetimepicker"
                          :editable="true"
                          :clearable="false"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-12">
                    <div class="input-block local-forms">
                      <!-- <editor api-key="qbuwhmfmq7n3lbo04fxnroyl623by920cmrotjyhe1v6vvwy" v-model="content" /> -->
                      <label>Description <span class="login-danger">*</span></label>

                      <textarea class="form-control" required rows="3" cols="30" v-model="args.taskSummary"></textarea>
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Attach documents <span class="login-danger"></span></label>
                      <!-- <input class="form-control" type="text" placeholder="" /> -->
                      <!-- <vue-select :options="allStaff" placeholder="Select Staff" v-model="args.assignedTo" /> -->
                      <multiselect
                        v-model="selectedDocs"
                        :options="docOptions"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Select document(s)"
                        label="label"
                        track-by="value"
                        :preselect-first="false"
                      >
                        <template #selection="{ values, isOpen }">
                          <span
                            style="font-size: 14px"
                            class="multiselect__single"
                            v-if="values.length"
                            v-show="!isOpen"
                            >{{ values.length }} Selected</span
                          >
                        </template>
                      </multiselect>
                    </div>
                  </div>

                  <div class="col-12 col-md-12 col-xl-12">
                    <div class="input-block select-gender">
                      <label class="gen-label">Add milestones <span class="login-danger">*</span></label>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            name="milestone"
                            v-model="showMilestone"
                            :value="true"
                            class="form-check-input"
                          />Yes
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            name="milestone"
                            v-model="showMilestone"
                            :value="false"
                            class="form-check-input"
                          />No
                        </label>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="col-12"><Notes /></div>
                  ADD notes here -->
                  <!-- <div class="col-12"><Timeline /></div> -->

                  <div v-if="showMilestone" class="col-12 col-sm-12" style="margin-bottom: 72px">
                    <div class="sm-table-responsive">
                      <table class="table border-0 custom-table invoices-table">
                        <thead>
                          <tr>
                            <th style="width: 20px">SN</th>
                            <th class="col-sm-2">Title</th>
                            <th class="col-md-6">Description</th>
                            <th class="col-md-6">Date</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(todo, i) in args.taskTodo" :key="i">
                            <td>{{ i + 1 }}</td>
                            <td>
                              <input
                                v-model="todo.title"
                                class="form-control"
                                required
                                type="text"
                                style="min-width: 150px"
                              />
                            </td>
                            <td>
                              <input
                                v-model="todo.description"
                                class="form-control"
                                type="text"
                                style="min-width: 150px"
                              />
                            </td>
                            <td>
                              <div class="time-icon">
                                <datepicker
                                  :input-format="dateFormat"
                                  class="form-control datetimepicker"
                                  v-model="todo.date"
                                  :editable="true"
                                  :clearable="false"
                                  style="min-width: 100px"
                                  placeholder=""
                                />
                              </div>
                            </td>
                            <td v-show="i !== 0 && i == args.taskTodo.length - 1">
                              <a
                                @click="removeTodo(i, todo)"
                                href="javascript:void(0)"
                                class="text-success font-18 add-table-invoice"
                                title="Remove"
                                ><i class="fa fa-minus"></i
                              ></a>
                            </td>
                            <td v-show="i == args.taskTodo.length - 1">
                              <a
                                @click="addTodo"
                                href="javascript:void(0)"
                                class="text-success font-18 add-table-invoice"
                                title="Add"
                                ><i class="fa fa-plus"></i
                              ></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <!-- <div class="col-12 col-md-6 col-xl-12">
                    <div class="input-block local-top-form">
                      <label class="local-top">Cover image <span class="login-danger"></span></label>
                      <div class="settings-btn upload-files-avator">
                        <input
                          type="file"
                          accept="image/*"
                          name="image"
                          id="file"
                          onchange="loadFile(event)"
                          class="hide-input"
                        />
                        <label for="file" class="upload">Choose File</label>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button type="submit" class="btn btn-primary submit-form me-2">
                        {{ $route.query.id ? "Update Task" : "Create Task" }}
                      </button>
                      <button @click="$router.go(-1)" type="button" class="btn btn-primary cancel-form">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";

import Editor from "@tinymce/tinymce-vue";
import Notes from "@/views/pages/uiinterface/elements/stickynote.vue";
import Timeline from "@/components/docs/horizontal-timeline.vue";
import { useProfileStore } from "@/stores/profile.js";
import Multiselect from "vue-multiselect";
import { createToaster } from "@meforma/vue-toaster";
const currentDate = new Date();

export default {
  components: {
    editor: Editor,
    Notes,
    Multiselect,
    Timeline,
  },
  setup() {
    const profile = useProfileStore();
    const { createUser, readUser } = profile;
    const allDocuments = computed(() => profile.getDocuments);
    const toaster = createToaster({
      position: "top-right",
      showCloseButton: true,
      duration: 5000,
    });
    const singleTask = computed(() => profile.getSingleTask);

    const allStaff = computed(() => {
      const data =
        profile.getAllStaff &&
        profile.getAllStaff.map((item) => {
          return {
            // id: item._id,
            // text: `${item.personalInformation.firstName} ${item.personalInformation.lastName}`,

            value: item._id,
            label: `${item.personalInformation.firstName} ${item.personalInformation.lastName}`,
            // email: item.contactInformation.email,
          };
        });
      return data;
    });

    const docOptions = computed(() => {
      const data = allDocuments.value.map((doc) => {
        return {
          value: doc._id,
          label: doc.docName,
          type: doc.docType,
        };
      });
      return data;
    });

    async function fetchDocs() {
      try {
        const res = await readUser({}, "FetchDocs", "documents");
        if (res) {
          //   console.log(res, "res");
        }
      } catch (error) {
        console.log(error);
      }
    }

    return {
      allStaff,
      createUser,
      readUser,
      fetchDocs,
      docOptions,
      toaster,
      singleTask,
    };
  },
  data() {
    return {
      showMilestone: true,
      showAttach: true,
      title: "Text Editor",
      content: "Description",
      title: "Task",
      text: "Add Tasks",
      args: { head: "" },
      Tasksubcategory: ["Choose Sub Task Category", "Health Care", "Corona Virus"],
      Taskcategory: ["Choose Task Category", "Health Care", "Child", "Safety"],
      dateFormat: "dd-MM-yyyy",
      args: {
        assignedTo: [],
        attachFile: null,
        projectEnd: currentDate,
        projectStart: currentDate,
        taskLogo: null,
        taskName: "",
        taskSummary: "",
        taskTodo: [{ title: "", description: "", date: currentDate }],
        taskTimer: null,
        documents: [],
      },
      selectedColleagues: [],
      selectedDocs: [],
    };
  },
  methods: {
    addTodo() {
      this.args.taskTodo.push({ title: "", description: "", date: currentDate });
    },
    removeTodo(i, todo) {
      const index = this.args.taskTodo.indexOf(todo);
      if (index > -1) {
        this.args.taskTodo.splice(index, 1);
      }
    },
    async readStaff() {
      try {
        const res = await this.readUser({}, "GetAllStaff", "staffs");
        if (res) {
          console.log(res);
        }
      } catch (error) {
        this.toaster.error(`Error: ${error}`);
      }
    },
    async createTask() {
      this.args.assignedTo = this.selectedColleagues.map((emp) => emp.value);
      this.args.documents = this.selectedDocs.map((doc) => doc.value);
      try {
        this.args.taskTodo = [];

        const res = await this.createUser({}, { input: this.args }, "CreateTask");
        console.log(res, "create-res");
        if (res) {
          this.args = {
            assignedTo: [],
            attachFile: null,
            projectEnd: "",
            projectStart: "",
            taskLogo: null,
            taskName: "",
            taskSummary: "",
            taskTodo: [{ title: "", description: "", date: currentDate }],
            taskTimer: null,
            documents: [],
          };
          this.toaster.success(`Task created successfully.`);
          this.$router.push({ path: "/task/task" });

          // this.$emit("close");
        }
      } catch (error) {
        console.log(error);
      }
    },

    async updateTask() {
      this.args.assignedTo = this.selectedColleagues.map((emp) => emp.value);
      this.args.documents = this.selectedDocs.map((doc) => doc.value);
      try {
        this.args.taskTodo = [];
        const res = await this.createUser({}, { updateTaskId: this.$route.query.id, input: this.args }, "UpdateTask");
        if (res) {
          this.toaster.success(`Task updated successfully.`);
          this.$router.push({ path: "/task/task" });

          // this.$emit("close");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async readTask() {
      try {
        const res = await this.readUser({ fetchSingleTaskId: this.$route.query.id }, "FetchSingleTask", "task");
        if (res) {
          console.log(res);
          this.args.assignedTo = res.assignedTo.map((item) => item._id);
          this.selectedColleagues = res.assignedTo.map((col) => {
            return {
              value: col._id,
              label: `${col.personalInformation.firstName} ${col.personalInformation.lastName}`,
              // email: col.contactInformation.email,
            };
          });
          this.args.documents = res.documents.map((item) => item._id);
          this.selectedDocs = res.documents.map((doc) => {
            return {
              value: doc._id,
              label: doc.docName,
              type: doc.docType,
            };
          });
          this.args.taskName = res.taskName;
          this.args.taskSummary = res.taskSummary;
          this.args.projectStart = new Date(res.projectStart);

          this.args.projectEnd = new Date(res.projectEnd);

          this.args.taskTodo = res.taskTodo.map((task) => {
            return {
              title: task.title,
              description: task.description,
              date: new Date(task.date),
            };
          });
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
  },
  async created() {
    if (this.$route.query.id) {
      await this.readTask();
    }
    // await this.readDept();
    await this.readStaff();
    await this.fetchDocs();
  },
};
</script>
