<template>
  <div class="page-wrapper">
    <!-- Page Content -->
    <div class="content container-fluid">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="/dashboard" />
      <!-- /Page Header -->

      <settingtab></settingtab>

      <div class="row">
        <div class="col-lg-8">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="editTheme">
                <h4 class="page-title">Theme Settings</h4>
                <div class="input-block row">
                  <label class="col-lg-3 col-form-label">Website Name</label>
                  <div class="col-lg-9">
                    <input
                      name="website_name"
                      class="form-control"
                      readonly
                      :value="companyDetails.companyName"
                      type="text"
                    />
                  </div>
                </div>
                <div class="input-block theme-set row">
                  <label class="col-lg-3 col-form-label">Logo</label>
                  <div class="col-lg-7">
                    <input
                      ref="input"
                      :accept="allowedTypes.toString()"
                      @change="previewFiles($event)"
                      class="form-control"
                      type="file"
                    />
                    <span class="form-text text-muted">Recommended image size is 40px x 40px</span>
                  </div>
                  <div class="col-lg-2">
                    <div class="img-thumbnail float-end">
                      <img
                        :src="
                          url && url.length
                            ? url
                            : companyDetails &&
                              companyDetails.theme &&
                              companyDetails.theme.companyLogo &&
                              companyDetails.theme.companyLogo[0]
                        "
                        alt=""
                        width="40"
                        height="40"
                      />
                    </div>
                  </div>
                </div>
                <!-- <div class="input-block theme-set row">
                  <label class="col-lg-3 col-form-label">Favicon</label>
                  <div class="col-lg-7">
                    <input class="form-control" type="file" />
                    <span class="form-text text-muted"
                      >Recommended image size is 16px x 16px</span
                    >
                  </div>
                  <div class="col-lg-2">
                    <div class="settings-image img-thumbnail float-end">
                      <img
                        src="@/assets/img/favicon.png"
                        class="img-fluid"
                        width="16"
                        height="16"
                        alt=""
                      />
                    </div>
                  </div>
                </div> -->
                <div class="m-t-20 text-center">
                  <button type="submit" class="btn btn-primary submit-btn">
                    <span
                      :class="loading ? 'spinner-border spinner-border-sm' : undefined"
                      class="me-2"
                      role="status"
                    ></span
                    >Save
                  </button>
                  <!-- <b-button type="submit" variant="primary me-1"
                      ><span
                        :class="loading ? 'spinner-border spinner-border-sm' : undefined"
                        class="me-2"
                        role="status"
                      ></span
                      >Save</b-button
                    > -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from "vue";
import { helperFunctions } from "@/composables/helperFunctions";
import { useProfileStore } from "@/stores/profile.js";
import { createToaster } from "@meforma/vue-toaster";

const profile = useProfileStore();
const { createUser, readUser } = profile;

const { uploadFileToServer, convertFileToBase64 } = helperFunctions;
const toaster = createToaster({
  position: "top-right",
  showCloseButton: true,
  duration: 5000,
});
const title = ref("Dashboard");
const text = ref("Settings");

const message = ref("");
const fileName = ref("");
const fileSize = ref("");
const url = ref([]);
const loading = ref(false);
const error = ref(false);
const hide = ref(true);
const inputFile = ref(null);
const input = ref(null);
// const dateInput = ref("");
const uploadFile = ref([]);
const props = defineProps({
  type: {
    type: String,
    default: "",
  },
  allowedTypes: { type: Array, default: () => ["jpg"] },
});

const companyDetails = computed(() => profile.getCompanyDetails);
const args = ref({
  theme: {
    companyLogo: "",
    primaryColor: "",
    secondaryColor: "",
  },
});
const allowedTypes = computed(() => {
  let allowedTypes = [];
  if (props.type === "video") {
    allowedTypes = ["image/*;capture=camera", "video/*", "video/mp4", "video/quicktime"];
  } else {
    if (props.allowedTypes) {
      props.allowedTypes.forEach((el) => {
        if (el == "pdf") {
          allowedTypes.push("application/pdf");
        } else if (el == "jpg") {
          allowedTypes.push("image/png", "image/jpeg", "image/gift");
        } else if (el == "excel") {
          allowedTypes.push(
            "text/csv",
            "application/csv",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel"
          );
        } else if (el == "word") {
          allowedTypes.push("application/msword");
        }
      });
    } else {
      allowedTypes = [
        "text/csv",
        "application/msword",
        "application/vnd.ms-excel",
        "application/vnd.ms-powerpoint",
        "text/plain",
        "application/pdf",
        "image/png",
        "image/jpeg",
        "image/gif",
        "application/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
    }
  }
  return allowedTypes;
});

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

function readBlobAsDataURL(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const dataURL = event.target.result;
      url.value.push(dataURL);
      resolve();
    };
    reader.onerror = function (event) {
      reject(event.target.error);
    };
    reader.readAsDataURL(blob);
  });
}
async function readFilesSequentially(files) {
  for (const b of files) {
    if (b instanceof Blob) {
      await readBlobAsDataURL(b);
    }
  }
}

async function previewFiles(e) {
  //   console.log(e.target.files[0], "FILE TARGET");
  if (e) {
    error.value = false;
    uploadFile.value = [];
    message.value = "";
    const files = e.target.files;
    inputFile.value = files;
    // uploadFile.value = e.target.files;
    for (let file of files) {
      const { name, size } = file;
      fileName.value = name;

      fileSize.value = formatBytes(size);
      const base64 = await convertFileToBase64(file);
      uploadFile.value.push(base64);
    }
    readFilesSequentially(files);
    // let reader = new FileReader();

    // reader.onloadend = (e) => {
    //   // data url
    //   url.value = e.target.result;
    // };
    // for (let b of files) {
    //   if (b instanceof Blob) reader.readAsDataURL(b);
    // }
    // console.log(url.value);
  }
}
async function fetchCompany() {
  try {
    const res = await readUser({}, "GetCompany", "companyDetails");
  } catch (error) {
    console.log(error);
  }
}
onMounted(async () => {
  await fetchCompany();
});
async function editTheme() {
  loading.value = true;
  try {
    args.value.theme.companyLogo = await uploadFileToServer([...uploadFile.value]);
    if (!args.value.theme.companyLogo) {
      toast.error("upload failed");
      return;
    }
    const res = await createUser({}, { input: args.value }, "UpdateCompany");
    if (res) {
      await fetchCompany();
      toaster.success(`Theme updated successfully.`);

      // router.push("/departments/department-list");
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}
</script>
