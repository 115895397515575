import axios from "axios";
import { AUTH_CONTEXT } from "@/api/api";
import { createToaster } from "@meforma/vue-toaster";

import { ref } from "vue";

const toast = createToaster({
  position: "top-right",
  showCloseButton: true,
  duration: 5000,
});

const screens = ref({
  sm: {
    size: 640,
    point: false,
  },
  md: {
    size: 1000,
    point: false,
  },
  lg: {
    size: 1024,
    point: false,
  },
  xl: {
    size: 1280,
    point: false,
  },
  xs: {
    size: 400,
    point: false,
  },
});

const updateScreenSize = () => {
  Object.keys(screens.value).forEach((key) => {
    screens.value[key].point = window.innerWidth < screens.value[key].size;
  });
};

window.addEventListener("resize", updateScreenSize);

export const helperFunctions = {
  searchArrayOfObjects(arr, searchString, key) {
    const results = [];
    const lowerSearchString = searchString.toLowerCase();

    for (let i = 0; i < arr.length; i++) {
      if (arr[i][key].toLowerCase().includes(lowerSearchString)) {
        results.push(arr[i]);
      }
    }

    return results;
  },
  breakPoints(arg) {
    return arg ? screens.value[arg].point : null;
  },

  truncateAmount(amount) {
    try {
      // Truncate finalAmount to 2 decimal places without rounding up
      if (amount === undefined || amount === null) {
        return 0;
      }
      const truncatedAmount = Math.trunc(amount * 100) / 100;
      return truncatedAmount;
    } catch (error) {
      console.error("error:", error);
      return 0;
    }
  },
  formatAmount(amount) {
    try {
      if (
        amount === undefined ||
        amount === null ||
        amount === 0 ||
        (typeof amount !== "number" && typeof amount !== "string") ||
        isNaN(Number(amount))
      ) {
        return "₦0.00";
      }
      let formatedAmount = parseInt(amount);
      formatedAmount = amount.toLocaleString("en-NG", {
        style: "currency",
        code: "NG",
        currency: "NGN",
      });

      return formatedAmount;
    } catch (error) {
      console.error("error:", error);
      return 0;
    }
  },
  processNumber(phoneNumber) {
    if (phoneNumber) {
      let phone = phoneNumber;
      let firstDigit = phone.charAt(0);
      if (firstDigit == "0") {
        let formatted = phone.replace("0", "234").replace(/\s+/g, "");
        return String(formatted);
      } else if (firstDigit == 2) {
        let formatted = phone.replace(/\s+/g, "");
        return String(formatted);
      } else if (firstDigit == "+") {
        let formatted = phone.substring(1).replace(/\s+/g, "");
        return String(formatted);
      } else {
        return phoneNumber;
      }
    } else {
      return "";
    }
  },
  truncateObj(obj) {
    try {
      if (obj === undefined || obj === null) {
        return obj;
      }

      let keysArr = [];

      keysArr = Object.keys(obj);

      keysArr.map((b) => {
        //loop through the keys contained in the object
        if (typeof obj[b] === "number") {
          //if its value is a number, truncuate it to 2 decimal places
          obj[b] = this.truncateAmount(obj[b]).toLocaleString();
        }
      });
      return obj;
    } catch (error) {
      throw error;
    }
  },
  resetObj(obj, objName) {
    obj = Object.keys(obj).reduce((acc, curr) => {
      if (obj[curr] === null) return { ...acc, [curr]: null };
      if (typeof obj[curr] === "undefined") return { ...acc, [curr]: undefined };
      // if (typeof obj[curr] === "boolean") return { ...acc, [curr]: false }; //Handle Booleans separately
      if (typeof obj[curr] === "string") return { ...acc, [curr]: "" };
      if (typeof obj[curr] === "number") return { ...acc, [curr]: 0 };
      if (obj[curr] instanceof Array) return { ...acc, [curr]: [] };
      if (typeof obj[curr] === "object") return { ...acc, [curr]: {} };
    }, {});
    return obj;
  },
  processDate() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    return today;
  },

  validateEmail(strn) {
    if (strn) {
      let bool;
      function containsSpecialChars(strn) {
        const specialChars = /[ `!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
        // const num = strn.replace(/[^.]/g, "").length;
        // const num1 = strn.replace(/[^@]/g, "").length;
        bool = specialChars.test(strn);
      }
      let validRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      containsSpecialChars(strn);
      if (strn) {
        if (strn.match(validRegex) && !bool) {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  validatePhone(phone) {
    // if (phone.trim() == "") {
    //   return false;
    // }
    if (phone.length) {
      if (phone.charAt(0) == 0 && phone.length !== 11) {
        return false;
      } else if (phone.charAt(0) == 2 && phone.length !== 13) {
        return false;
      } else if (phone.length < 11) {
        return false;
      } else {
        // errorRules.email = false;
        // errorRules.pin = false;

        return true;
      }
    }
  },
  calculatePercentage(num, per) {
    const result = (Number(num) / 100) * Number(per);
    return result;
  },

  formatDateString(date, arg) {
    let formattedDate;
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (date && date.length > 0 && date !== "N/A") {
      let dd = date.slice(8, 10);
      let mm = date.slice(5, 7);

      let yyyy = date.slice(0, 4);
      // if (dd < 10) {
      //   dd = "0" + dd;
      // }
      // if (mm < 10) {
      //   mm = "0" + mm;
      // }
      formattedDate =
        arg === "name" ? months[parseInt(mm.toString()) - 1] + " " + dd + ", " + yyyy : dd + "/" + mm + "/" + yyyy;
    } else {
      formattedDate = "N/A";
    }

    return formattedDate;
  },
  convertTime(givenTime) {
    // Current date
    const timeAMPM = new Date(givenTime).toLocaleTimeString("en-US", { hour12: true }).replace(/:\d{2}\s/, " ");

    return timeAMPM;
  },
  truncateString(str, maxLength) {
    return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
  },

  //write a function to get relative time
  getRelativeTime(date) {
    const now = new Date();
    const diff = now - new Date(date);

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 1) {
      return `${days} days ago`;
    } else if (days === 1) {
      return "yesterday";
    } else if (hours > 1) {
      return `${hours} hours ago`;
    } else if (hours === 1) {
      return "an hour ago";
    } else if (minutes > 1) {
      return `${minutes} minutes ago`;
    } else if (minutes === 1) {
      return "a minute ago";
    } else {
      return "just now";
    }
  },
  viewPDF(base64String) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl, "_blank");
  },

  printPDF(base64Pdf) {
    // Create a Blob from the base64 PDF string
    const pdfData = atob(base64Pdf);
    const arrayBuffer = new ArrayBuffer(pdfData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < pdfData.length; i++) {
      uint8Array[i] = pdfData.charCodeAt(i);
    }
    const pdfBlob = new Blob([arrayBuffer], { type: "application/pdf" });

    // Generate a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new browser tab
    const newTab = window.open(pdfUrl, "_blank");

    // Trigger print dialog when new tab is loaded
    newTab.onload = function () {
      newTab.print();
    };
  },

  // async uploadFileToServer(fileToUpload) {
  //   //   return true;
  //   // console.log(fileToUpload, "TOBEUPLOADED");
  //   if (fileToUpload && typeof fileToUpload === "string") {
  //     return fileToUpload;
  //   }
  //   if (fileToUpload) {
  //     const formData = new FormData();
  //     const operation = "gcpUpload";
  //     formData.append(
  //       "operations",
  //       `{
  //           "query": "mutation ${operation}($file:Upload!) { ${operation}(file: $file){message data} }"
  //         }`
  //     );
  //     formData.append("map", `{"0": ["variables.file"]}`);
  //     formData.append("0", fileToUpload);
  //     try {
  //       const response = await axios.post(
  //         "https://employeemgtservice-5a2d98243525f6bf.onporter.run/graphql",
  //         // "http://localhost:4001/graphql",
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //             authorization: AUTH_CONTEXT(),
  //             "Apollo-Require-Preflight": "true",
  //           },
  //         }
  //       );
  //       // console.log(response.data.data.gcpUpload.data.url, "FILEURL");
  //       if (response.data && response.data.data) {
  //         return response.data.data.gcpUpload.data.url;
  //       } else {
  //         throw new Error(response.data.errors[0].message);
  //       }
  //       // return response.data && response.data.data
  //       //   ? response.data.data.gcpUpload.data.url
  //       //   : undefined;
  //     } catch (err) {
  //       toast.error(err.message);
  //       console.log(err.message);
  //       return null;
  //     }
  //   }
  //   return "";
  // },
  checkTaskStatus(dueDateString) {
    // Parse the due date string to a Date object
    const dueDate = new Date(dueDateString);

    // Get the current date (today's date)
    const today = new Date();

    // Set the time of today's date to 00:00:00 to compare only the date part
    today.setHours(0, 0, 0, 0);

    // Compare the dates
    if (dueDate >= today) {
      return { text: "In progress", class: "text-purple" };
    } else {
      return { text: "Overdue", class: "text-danger" };
    }
  },
  getDaysElapsed(deadline) {
    // Parse the deadline date string to a Date object
    const deadlineDate = new Date(deadline);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in time (milliseconds)
    const timeDifference = currentDate - deadlineDate;

    // Convert time difference from milliseconds to days
    const daysElapsed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysElapsed;
  },
  getAvatar(string) {
    function getColor(index) {
      let n = (index * 0xfffff * 1000000).toString(16);
      return "#" + n.slice(0, 6);
    }
    let name = "",
      color;
    // Get color
    let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

    const initialOne = String(string.charAt(0));
    const initialTwo = String(string.charAt(1));
    const num = Number(alphabet.indexOf(initialOne.toLowerCase())) + Number(alphabet.indexOf(initialTwo.toLowerCase()));
    color = getColor(num);

    // Get Shortend Name
    // string ? (name = `${string.charAt(0)}${string.charAt(1)}`) : "";
    string ? (name = `${string.charAt(0)}`) : "";

    return {
      color,
      name,
    };
  },

  flattenObj(ob) {
    // The object which contains the
    // final result
    let result = {};

    // loop through the object "ob"
    for (const i in ob) {
      // We check the type of the i using
      // typeof() function and recursively
      // call the function again
      if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
        const temp = flattenObj(ob[i]);
        for (const j in temp) {
          // Store temp in result
          result[i + "." + j] = temp[j];
        }
      }

      // Else store ob[i] in result directly
      else {
        result[i] = ob[i];
      }
    }
    return result;
  },
  convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]); // Extract the base64 string from the Data URL
      };

      reader.onerror = () => {
        reject(new Error("Failed to read the file."));
      };

      reader.readAsDataURL(file);
    });
  },
  async uploadFileToServer(base64Strings) {
    if (base64Strings && base64Strings.length > 0) {
      const operation = "uploadImages";
      const query = `mutation ${operation}($files: [String!]!) {
        ${operation}(files: $files) 
      }`;

      const variables = {
        files: base64Strings,
      };

      try {
        const response = await axios.post(
          "http://localhost:4000/graphql",
          // "https://torfserver.onrender.com/graphql",
          {
            query,
            variables,
          },
          {
            headers: {
              "Content-Type": "application/json",
              //   authorization: AUTH_CONTEXT(),
            },
          }
        );

        // console.log(response.data.data.uploadImages, "FILEURL");
        if (response && response.data.data.uploadImages) {
          return response.data.data.uploadImages;
        } else if (response.data.errors) {
          throw new Error(response.data.errors[0].message);
        } else {
          throw new Error("Failed to upload files.");
        }
      } catch (err) {
        toast.error(err.message);
        console.log(err.message);
        return null;
      }
    }
    return "";
  },

  // getAvatar(employee) {
  //   function getColor(index) {
  //     let n = (index * 0xfffff * 1000000).toString(16);
  //     return "#" + n.slice(0, 6);
  //   }

  //   let name = "",
  //     color;

  //   if (employee) {
  //     let letters = "abcdefghijklmnopqrstuvwxyz";
  //     const alphabet = letters.split("");
  //     const empId = employee.job && employee.job.userName;
  //     const thenum = empId ? Number(empId.replace(/\D/g, "")) : 1;
  //     const initialOne = String(
  //       employee && employee.profile && employee.profile.firstName && employee.profile.firstName.charAt(0)
  //     );
  //     const initialTwo = String(
  //       employee && employee.profile && employee.profile.lastName && employee.profile.lastName.charAt(0)
  //     );
  //     const num =
  //       Number(alphabet.indexOf(initialOne.toLowerCase())) + Number(alphabet.indexOf(initialTwo.toLowerCase()));
  //     color = getColor(num);
  //     // Get color

  //     employee
  //       ? (name = `${
  //           employee && employee.profile && employee.profile.firstName ? employee.profile.firstName.charAt(0) : ""
  //         }${employee && employee.profile && employee.profile.lastName ? employee.profile.lastName.charAt(0) : ""}`)
  //       : "N/A";
  //   }

  //   return {
  //     color,
  //     name,
  //   };
  // },

  // Get rid of the proxy when console.log
  log(arg) {
    console.log(JSON.parse(JSON.stringify(arg)));
  },

  lastDateOfMonth(month, year) {
    if (year && month) {
      const date = new Date(year, month + 1, 0); // Set the date to the first day of the next month
      date.setDate(-1); // Decrement the date by one day
      return date.getDate();
    }
  },

  downloadBase64(base64String, fileName, type, zip = null) {
    let link = document.createElement("a");
    if (zip) {
      link.href = `data:application/zip;base64,${base64String}`;
      link.download = fileName;
      link.click();
      return;
    }
    if (!type) {
      link.href = `data:application/pdf;base64,${base64String}`;
      link.download = fileName;
      link.click();
      return;
    }
    link.href = `data:application/vnd.ms-excel;base64,${base64String}`;

    link.download = fileName;
    link.click();
  },
  downloadExcelFromBase64(base64Data, fileName) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  viewPDF(base64String) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const pdfUrl = URL.createObjectURL(blob);
    window.open(pdfUrl, "_blank");
  },

  printPDF(base64Pdf) {
    // Create a Blob from the base64 PDF string
    const pdfData = atob(base64Pdf);
    const arrayBuffer = new ArrayBuffer(pdfData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < pdfData.length; i++) {
      uint8Array[i] = pdfData.charCodeAt(i);
    }
    const pdfBlob = new Blob([arrayBuffer], { type: "application/pdf" });

    // Generate a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new browser tab
    const newTab = window.open(pdfUrl, "_blank");

    // Trigger print dialog when new tab is loaded
    newTab.onload = function () {
      newTab.print();
    };
  },

  // relativeTime(date) {
  //   const timeAgo = new TimeAgo("en-US");
  //   return timeAgo.format(new Date(date));
  // },
  jsonToXlsx(data) {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert workbook to an array buffer
    const arrayBuffer = XLSX.write(workbook, { type: "array" });

    // Convert array buffer to Blob
    const blob = new Blob([arrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link and trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "output.xlsx";
    downloadLink.click();
  },

  async makeApiRequest(method, url, data = {}, headers = {}) {
    if (!headers["Content-Type"]) {
      headers["Content-Type"] = "application/json";
    }
    try {
      const options = {
        method,
        url,
        headers,
        data,
      };

      const response = await axios(options);
      return response.data;
    } catch (error) {
      console.error("Error making API request:", error);
      throw error;
    }
  },
};
