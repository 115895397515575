<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="schedule" />
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Schedule List</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search here"
                            />
                            <a class="btn"
                              ><img src="@/assets/img/icons/search-normal.svg" alt=""
                            /></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <router-link
                            to="add-schedule"
                            class="btn btn-primary add-pluss ms-2"
                            ><img src="@/assets/img/icons/plus.svg" alt=""
                          /></router-link>
                          <a
                            href="javascript:;"
                            class="btn btn-primary doctor-refresh ms-2"
                            ><img src="@/assets/img/icons/re-fresh.svg" alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"
                      ><img src="@/assets/img/icons/pdf-icon-01.svg" alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img src="@/assets/img/icons/pdf-icon-02.svg" alt=""
                    /></a>
                    <a href="javascript:;" class="me-2"
                      ><img src="@/assets/img/icons/pdf-icon-03.svg" alt=""
                    /></a>
                    <a href="javascript:;"
                      ><img src="@/assets/img/icons/pdf-icon-04.svg" alt=""
                    /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->

              <div class="table-responsive">
                <a-table
                  class="table border-0 custom-table comman-table datatable mb-0"
                  :columns="columns"
                  :data-source="data"
                  :row-selection="{}"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'DoctorName'">
                      <div class="profile-image">
                        <router-link to="/profile"
                          ><img
                            width="28"
                            height="28"
                            :src="require(`@/assets/img/profiles/${record.Avatar}`)"
                            class="rounded-circle m-r-5"
                            alt=""
                          />
                          {{ record.DoctorName }}</router-link
                        >
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <div>
                        <button :class="record.Class">{{ record.Status }}</button>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="text-end">
                        <div class="dropdown dropdown-action">
                          <a
                            href="javascript:;"
                            class="action-icon dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            ><i class="fa fa-ellipsis-v"></i
                          ></a>
                          <div class="dropdown-menu dropdown-menu-end">
                            <router-link class="dropdown-item" to="edit-schedule"
                              ><i class="fa-solid fa-pen-to-square m-r-5"></i>
                              Edit</router-link
                            >
                            <a
                              class="dropdown-item"
                              href="javascript:;"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_patient"
                              ><i class="fa fa-trash-alt m-r-5"></i> Delete</a
                            >
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <delete></delete>
</template>

<script>
const columns = [
  {
    title: "Doctor Name",
    dataIndex: "DoctorName",
    key: "DoctorName",
    sorter: {
      compare: (a, b) => {
        a = a.DoctorName.toLowerCase();
        b = b.DoctorName.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Department",
    dataIndex: "Department",
    sorter: {
      compare: (a, b) => {
        a = a.Department.toLowerCase();
        b = b.Department.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Available Days",
    dataIndex: "AvailableDays",
    sorter: {
      compare: (a, b) => {
        a = a.AvailableDays.toLowerCase();
        b = b.AvailableDays.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Available Time",
    dataIndex: "AvailableTime",
    sorter: {
      compare: (a, b) => {
        a = a.AvailableTime.toLowerCase();
        b = b.AvailableTime.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "",
    key: "action",
    sorter: true,
    class: "text-end",
  },
];
const data = [
  {
    id: "1",
    Avatar: "avatar-01.jpg",
    DoctorName: "Dr.Andrea Lalema",
    Department: "Cardiology",
    AvailableDays: "Mon - Sun",
    AvailableTime: "09:00 AM - 06:00 PM",
    Status: "Active",
    Class: "custom-badge status-green ",
  },
  {
    id: "2",
    Avatar: "avatar-02.jpg",
    DoctorName: "Dr.Smith Bruklin",
    Department: "Urology",
    AvailableDays: "Mon,Tue,Sat,Sun",
    AvailableTime: "09:00 AM - 04:00 PM",
    Status: "Active",
    Class: "custom-badge status-green ",
  },
  {
    id: "3",
    Avatar: "avatar-03.jpg",
    DoctorName: "Dr.William Stephin",
    Department: "Radiology",
    AvailableDays: "Mon,Fri",
    AvailableTime: "09:00 AM - 06:00 PM",
    Status: "Active",
    Class: "custom-badge status-green ",
  },
  {
    id: "4",
    Avatar: "avatar-04.jpg",
    DoctorName: "Dr.Bernardo James",
    Department: "Dentist",
    AvailableDays: "Mon - fri",
    AvailableTime: "010:00 AM - 05:00 PM",
    Status: "In Active",
    Class: "custom-badge status-pink ",
  },
  {
    id: "5",
    Avatar: "avatar-06.jpg",
    DoctorName: "Dr.Cristina Groves",
    Department: "Gynocolgy",
    AvailableDays: "Mon - Sun",
    AvailableTime: "09:00 AM - 06:00 PM",
    Status: "In Active",
    Class: "custom-badge status-pink ",
  },
  {
    id: "6",
    Avatar: "avatar-05.jpg",
    DoctorName: "Mark Hay Smith",
    Department: "Gynocolgy",
    AvailableDays: "Sat, Sun",
    AvailableTime: "09:00 AM - 12:00 PM",
    Status: "Active",
    Class: "custom-badge status-green ",
  },
  {
    id: "7",
    Avatar: "avatar-01.jpg",
    DoctorName: "Dr.Andrea Lalema",
    Department: "Otolaryngology",
    AvailableDays: "Mon - Sun",
    AvailableTime: "09:00 AM - 06:00 PM",
    Status: "Active",
    Class: "custom-badge status-green ",
  },
  {
    id: "8",
    Avatar: "avatar-02.jpg",
    DoctorName: "Dr.Smith Bruklin",
    Department: "Urology",
    AvailableDays: "Mon - wed",
    AvailableTime: "11:00 AM - 09:00 PM",
    Status: "In Active",
    Class: "custom-badge status-pink ",
  },
];
export default {
  data() {
    return {
      title: "Doctor Shedule",
      text: "Schedule List",
      columns,
      data,
    };
  },
};
</script>
