<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="handleSubmit" class="was-validated">
            <div class="row">
              <div class="col-12">
                <div class="form-heading">
                  <h4>{{ $route.query.id || $route.params.id ? "Edit" : "Add" }} Employee Salary</h4>
                </div>
              </div>
              <div v-if="$route.query.id || $route.params.id" class="col-12 col-md-6 col-xl-6">
                <div class="input-block local-forms">
                  <label>Select Staff </label>
                  <vue-select v-model="selectedStaff" :options="daniel" id="daniel" placeholder="Select Staff" />
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-6">
                <div class="input-block local-forms">
                  <label>Gross Salary </label>
                  <MaskedInput
                    :format="true"
                    :mask="'999,999,999.99'"
                    class="form-control"
                    v-model="args.grossSalary"
                  />
                </div>
              </div>
              <!-- <div class="col-12 col-md-6 col-xl-6">
                <div class="input-block local-forms">
                  <label>Net Salary </label>
                  <input class="form-control" type="text" v-model="args.netSalary" />
                </div>
              </div> -->
            </div>
            <div class="row">
              <div class="col-12 col-md-6 col-xl-6">
                <div class="pay-head-roll">
                  <h5>Earnings</h5>

                  <div class="row">
                    <div class="col-12 col-md-6 col-xl-6">
                      <div class="input-block select-gender">
                        <label class="gen-label">Input as<span class="login-danger">*</span></label>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              name="percent"
                              class="form-check-input"
                              :value="true"
                              :checked="args.usePercentage == true"
                              @change="($event) => (args.usePercentage = true)"
                            />Percentage
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              name="percent"
                              class="form-check-input"
                              :value="false"
                              :checked="args.usePercentage == false"
                              @change="($event) => (args.usePercentage = false)"
                            />Amount
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-for="allowance in allowanceData" :key="allowance._id" class="input-block local-forms">
                  <label style="text-transform: capitalize">{{ allowance.name }}</label>
                  <MaskedInput
                    v-model="allowanceInput[allowance._id]"
                    :format="args.usePercentage ? false : true"
                    :type="args.usePercentage ? 'number' : 'text'"
                    :max="args.usePercentage ? '100' : undefined"
                    class="form-control"
                  />
                  <div class="invalid-feedback">Percentage can't be more than 100</div>
                </div>
                <!-- <div class="input-block local-forms">
                  <label>Basic Allowance (50%)</label>
                  <MaskedInput :format="true" :mask="'999,999,999.99'" class="form-control" type="text" />
                </div>
                <div class="input-block local-forms">
                  <label>Housing Allowance (30%)</label>
                  <MaskedInput :format="true" :mask="'999,999,999.99'" class="form-control" type="text" />
                </div>
                <div class="input-block local-forms">
                  <label>Transport Allowance (20%)</label>
                  <MaskedInput :format="true" :mask="'999,999,999.99'" class="form-control" type="text" />
                </div> -->

                <a
                  class="btn btn-primary"
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#allowance_details"
                  ><i class="fas fa-plus-circle me-2"></i>Add allowance</a
                >
              </div>
              <div class="col-12 col-md-6 col-xl-6">
                <div class="pay-head-roll">
                  <h5>Deductions</h5>
                </div>
                <div id="accordion" class="custom-faq mb-3">
                  <div
                    v-for="(ded, i) in deductionList"
                    :key="i"
                    class="card mb-1"
                    style="border-bottom: 1px solid #f62d51"
                  >
                    <div class="card-header" :id="`heading${i}`">
                      <h5 class="accordion-faq m-0">
                        <a
                          class="text-dark"
                          data-bs-toggle="collapse"
                          :href="`#collapse${i}`"
                          :aria-expanded="i == active ? 'true' : 'false'"
                          @click="active = i"
                        >
                          <i class="mdi mdi-help-circle me-1 text-primary"></i>
                          {{ ded.label }}
                        </a>
                      </h5>
                    </div>

                    <div
                      :id="`#collapse${i}`"
                      class="collapse"
                      :class="i == active ? 'show' : undefined"
                      :aria-labelledby="`heading${i}`"
                      data-bs-parent="#accordion"
                    >
                      <div class="">
                        <div v-if="['nhf', 'nsitf', 'itf', 'wht'].includes(ded.value)" class="row">
                          <span style="font-size: 12px" class="">{{ ded.label }} is automatically calculated </span>
                          <div class="col-12 col-md-6 col-xl-6">
                            <div class="input-block select-gender">
                              <div class="form-check-inline">
                                <label style="font-size: 12px" class="form-check-label login-danger">
                                  <input
                                    type="checkbox"
                                    name="input"
                                    class="form-check-input"
                                    :value="true"
                                    :checked="typeBool[ded.value] == true"
                                    @change="($event) => (typeBool[ded.value] = true)"
                                  />input manually
                                </label>
                              </div>

                              <div class="form-check-inline">
                                <label style="font-size: 12px" class="form-check-label login-danger">
                                  <input
                                    type="checkbox"
                                    name="input"
                                    class="form-check-input"
                                    :value="false"
                                    :checked="typeBool[ded.value] == false"
                                    @change="($event) => (typeBool[ded.value] = false)"
                                  />auto calculate
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="!['nhf', 'nsitf', 'itf', 'wht'].includes(ded.value) || typeBool[ded.value]"
                          class="input-block local-forms"
                        >
                          <label>{{ "Amount" }} </label>
                          <MaskedInput
                            :format="true"
                            :mask="'999,999,999.99'"
                            class="form-control"
                            v-model="args.statutoryDeductions[ded.value].amount"
                          />
                        </div>

                        <div v-if="ded.value === 'paye'" class="input-block local-forms">
                          <label>State for PAYE </label>
                          <vue-select
                            :options="statesData"
                            placeholder="Select state"
                            v-model="args.statutoryDeductions.paye.stateForPaye"
                          />
                        </div>

                        <div v-if="ded.value === 'paye'" class="input-block local-forms">
                          <label>PAYE ID </label>
                          <input
                            required
                            name="taxId"
                            v-model="args.statutoryDeductions.paye.taxId"
                            class="form-control"
                            type="text"
                          />
                        </div>

                        <div v-if="ded.value === 'pension'" class="input-block local-forms">
                          <label>Select pension provider </label>
                          <vue-select
                            :options="pensionOptions"
                            placeholder="Select provider"
                            v-model="args.statutoryDeductions.pension.provider"
                          />
                        </div>

                        <div v-if="ded.value === 'pension'" class="input-block local-forms">
                          <label>Employer pension contribution </label>
                          <MaskedInput
                            :format="true"
                            :mask="'999,999,999.99'"
                            class="form-control"
                            v-model="args.statutoryDeductions.pension.employerPensionContribution"
                          />
                        </div>

                        <div v-if="ded.value === 'pension'" class="input-block local-forms">
                          <label>Employee pension contribution </label>
                          <MaskedInput
                            :format="true"
                            :mask="'999,999,999.99'"
                            class="form-control"
                            v-model="args.statutoryDeductions.pension.employeePensionContribution"
                          />
                        </div>

                        <div v-if="ded.value === 'pension'" class="input-block local-forms">
                          <label>Employee pension pin </label>
                          <input
                            name="pensionpin"
                            v-model="args.statutoryDeductions.pension.employeePensionPin"
                            class="form-control"
                            type="text"
                          />
                        </div>

                        <div v-if="ded.value === 'hmo'" class="input-block local-forms">
                          <label>Select HMO provider </label>
                          <vue-select
                            :options="hmoOptions"
                            placeholder="Select provider"
                            v-model="args.statutoryDeductions.hmo.provider"
                          />
                        </div>

                        <div v-if="ded.value === 'hmo'" class="input-block local-forms">
                          <label>Select HMO plan </label>
                          <vue-select
                            :options="[
                              {
                                text: 'Bronze',
                                id: 'BRONZE',
                              },
                              {
                                text: 'Silver',
                                id: 'SILVER',
                              },
                              {
                                text: 'Gold',
                                id: 'GOLD',
                              },
                              {
                                text: 'Gold Plus',
                                id: 'GOLD_PLUS',
                              },
                              {
                                text: 'Platinum',
                                id: 'PLATINUM',
                              },
                            ]"
                            placeholder="Select provider"
                            v-model="args.statutoryDeductions.hmo.provider"
                          />
                        </div>

                        <div v-if="ded.value === 'hmo'" class="input-block local-forms">
                          <label>HMO amount </label>
                          <MaskedInput
                            :format="true"
                            :mask="'999,999,999.99'"
                            class="form-control"
                            v-model="args.statutoryDeductions.hmo.amount"
                          />
                        </div>

                        <div v-if="ded.value === 'hmo'" class="row">
                          <div class="col-12 col-md-6 col-xl-6">
                            <div class="input-block select-gender">
                              <label class="gen-label">Charge to<span class="login-danger">*</span></label>
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input
                                    type="radio"
                                    name="charge"
                                    class="form-check-input"
                                    value="EMPLOYEE"
                                    @change="($event) => (args.statutoryDeductions.hmo.chargeTo = $event.target.value)"
                                  />Employee
                                </label>
                              </div>
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input
                                    type="radio"
                                    name="charge"
                                    class="form-check-input"
                                    value="EMPLOYER"
                                    @change="($event) => (args.statutoryDeductions.hmo.chargeTo = $event.target.value)"
                                  />Employer
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-if="ded.value === 'lifeInsurance'" class="input-block local-forms">
                          <label>Select insurance provider </label>
                          <vue-select
                            :options="lifeInsuranceBodies"
                            placeholder="Select provider"
                            v-model="args.statutoryDeductions.lifeInsurance.provider"
                          />
                        </div>

                        <div v-if="ded.value === 'lifeInsurance'" class="input-block local-forms">
                          <label>Life insurance amount </label>
                          <MaskedInput
                            :format="true"
                            :mask="'999,999,999.99'"
                            class="form-control"
                            v-model="args.statutoryDeductions.lifeInsurance.amount"
                          />
                        </div>

                        <div v-if="ded.value === 'lifeAssurance'" class="input-block local-forms">
                          <label>Select assurance provider </label>
                          <vue-select
                            :options="lifeAssuranceBodies"
                            placeholder="Select provider"
                            v-model="args.statutoryDeductions.lifeAssurance.provider"
                          />
                        </div>

                        <div v-if="ded.value === 'lifeAssurance'" class="input-block local-forms">
                          <label>Life assurance amount </label>
                          <MaskedInput
                            :format="true"
                            :mask="'999,999,999.99'"
                            class="form-control"
                            v-model="args.statutoryDeductions.lifeAssurance.amount"
                          />
                        </div>

                        <div v-if="ded.value === 'lifeAssurance'" class="row">
                          <div class="col-12 col-md-6 col-xl-6">
                            <div class="input-block select-gender">
                              <label class="gen-label">Charge to<span class="login-danger">*</span></label>
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input
                                    type="radio"
                                    name="chargeto"
                                    class="form-check-input"
                                    value="EMPLOYEE"
                                    @change="
                                      ($event) =>
                                        (args.statutoryDeductions.lifeAssurance.chargeTo = $event.target.value)
                                    "
                                  />Employee
                                </label>
                              </div>
                              <div class="form-check-inline">
                                <label class="form-check-label">
                                  <input
                                    type="radio"
                                    name="chargeto"
                                    class="form-check-input"
                                    value="EMPLOYER"
                                    @change="
                                      ($event) =>
                                        (args.statutoryDeductions.lifeAssurance.chargeTo = $event.target.value)
                                    "
                                  />Employer
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="input-block local-forms">
                  <label>PAYE </label>
                  <MaskedInput
                    :format="true"
                    :mask="'999,999,999.99'"
                    class="form-control"
                    v-model="args.statutoryDeductions.paye.amount"
                  />
                </div>
                <div class="input-block local-forms">
                  <label>Pension </label>
                  <MaskedInput
                    :format="true"
                    :mask="'999,999,999.99'"
                    class="form-control"
                    v-model="args.statutoryDeductions.pension.amount"
                  />
                </div>
                <div class="input-block local-forms">
                  <label>NHF </label>
                  <MaskedInput :format="true" :mask="'999,999,999.99'" class="form-control" type="text" />
                </div>
                <div class="input-block local-forms">
                  <label>NSITF </label>
                  <MaskedInput :format="true" :mask="'999,999,999.99'" class="form-control" type="text" />
                </div>
                <div class="input-block local-forms">
                  <label>ITF </label>
                  <MaskedInput :format="true" :mask="'999,999,999.99'" class="form-control" type="text" />
                </div>
                <div class="input-block local-forms">
                  <label>WHT</label>
                  <MaskedInput :format="true" :mask="'999,999,999.99'" value="2000" class="form-control" type="text" />
                </div> -->
              </div>
              <div class="col-12">
                <div class="doctor-submit text-end">
                  <button type="submit" class="btn btn-primary submit-form me-2">Submit</button>
                  <button type="button" class="btn btn-primary cancel-form">Cancel</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal custom-modal fade bank-details" id="allowance_details" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div class="form-header text-start mb-0">
              <h4 class="mb-0">Add Allowance</h4>
            </div>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="bank-inner-details">
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="input-block">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="allowanceName" placeholder="Add Name" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="bank-details-btn">
              <a href="javascript:void(0);" ref="dismissBtn" data-bs-dismiss="modal" class="btn bank-cancel-btn me-2"
                >Cancel</a
              >
              <a @click="createAllowance" href="javascript:void(0);" class="btn bank-save-btn">Save</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaskedInputVue from "@/views/pages/uiinterface/form/formmask/MaskedInput.vue";
import benefits from "@/utils/statutory-deductions.js";
import NigerianStates from "@/utils/states";
import pensionProviders from "@/utils/pensionProviders";
import HmoProviders from "@/utils/HmoProviders";
import lifeInsuranceBodies from "@/utils/lifeInsuranceBodies";
import { computed } from "vue";
import { useProfileStore } from "@/stores/profile.js";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-right",
  showCloseButton: true,
  duration: 5000,
});
export default {
  setup() {
    const profile = useProfileStore();
    const { createUser, readUser } = profile;
    const statesData = computed(() => {
      const stateArray = NigerianStates.map((state) => {
        return {
          text: state,
          id: state,
        };
      });
      return stateArray;
    });

    const pensionOptions = computed(() => {
      const data = pensionProviders.map((pp) => {
        return {
          id: pp,
          text: pp,
        };
      });
      return data;
    });

    const hmoOptions = computed(() => {
      const data = HmoProviders.map((pp) => {
        return {
          id: pp,
          text: pp,
        };
      });
      return data;
    });

    const insuranceOptions = computed(() => {
      const data = lifeInsuranceBodies.map((pp) => {
        return {
          id: pp,
          text: pp,
        };
      });
      return data;
    });

    return { statesData, pensionOptions, hmoOptions, insuranceOptions, createUser, readUser };
  },
  components: { MaskedInput: MaskedInputVue },
  emits: ["input"],
  data() {
    return {
      deductionList: benefits,
      typeBool: {
        nhf: false,
        nsitf: false,
        itf: false,
      },
      active: 0,
      title: "Payroll",
      text: "Edit Employee Salary",
      daniel: ["Select Staff", "Michael Anorue", "Daniel Folarinde"],
      selectedStaff: "Daniel Folarinde",
      allowanceName: "",
      allowanceData: [],
      allowanceInput: {},
      args: {
        grossSalary: 0,
        netSalary: 0,
        allowanceDetails: [],
        statutoryDeductions: {
          hmo: {
            provider: "",
            amount: 0,
            duration: "",
            plan: "",
            chargeTo: "EMPLOYEE",
          },
          lifeInsurance: {
            provider: "",
            amount: 0,
            duration: "",
          },
          lifeAssurance: {
            provider: "",
            amount: 0,
            duration: "",
            chargeTo: "EMPLOYEE",
          },
          itf: {
            amount: 0,
          },
          nsitf: {
            amount: 0,
          },
          pension: {
            amount: 0,
            provider: "",
            employerPensionContribution: 0,
            employeePensionContribution: 0,
            providerPin: "",
            usePercentage: false,
            manually_inputted: false,
            employeePensionPin: "",
          },
          paye: {
            amount: 0,
            stateForPaye: "",
            taxId: "",
          },
          nhf: {
            amount: 0,
          },
          gratuity: {
            salaryAmount: 0,
            yearsWorked: 0,
            amount: 0,
          },
          businessExpense: { amount: 0, expenseName: "" },
          wht: {
            amount: 0,
            paymentTypes: [],
            percentage: 0,
          },
        },
        usePercentage: true,
      },
    };
  },
  methods: {
    transformAllowances() {
      for (var item in this.allowanceInput) {
        if (!this.args.usePercentage) {
          this.args.allowanceDetails.push({
            allowanceId: item,
            amount: this.removeSymbolsFromString(this.allowanceInput[item]),
          });
        } else {
          this.args.allowanceDetails.push({
            allowanceId: item,
            percentage: this.removeSymbolsFromString(this.allowanceInput[item]),
          });
        }
      }
    },
    removeSymbolsFromString(string) {
      if (string && String(string).length) {
        let result = String(string).replace(/[₦%,]/g, "");
        return Number(result);
      }
      return 0;
    },
    validateAllowanceThenNext() {
      console.log(this.allowanceInput, "all input");
      try {
        this.args.allowanceDetails = [];
        if (this.args.usePercentage) {
          var total = 0;

          for (let field of Object.keys(this.allowanceInput)) {
            total += this.removeSymbolsFromString(this.allowanceInput[field]);
          }

          if (total == 0) {
            let temp;
            let temp2;
            let temp3;
            for (let field of this.allowanceData) {
              if (field.name.toLowerCase() == "basic") {
                temp = field._id;
              }
              if (field.name.toLowerCase() == "housing") {
                temp2 = field._id;
              }
              if (field.name.toLowerCase() == "transport") {
                temp3 = field._id;
              }
            }
            this.args.allowanceDetails.push(
              {
                allowanceId: temp,
                percentage: 50,
              },
              {
                allowanceId: temp2,
                percentage: 30,
              },
              {
                allowanceId: temp3,
                percentage: 20,
              }
            );
          }
        } else {
          var total = 0;

          for (let field of Object.keys(this.allowanceInput)) {
            total += this.removeSymbolsFromString(this.allowanceInput[field]);
            console.log(total, "TOtal Amount");
          }

          if (total === 0) {
            let temp;
            for (let field of this.allowanceData) {
              if (field.name == "basic") {
                temp = field._id;
              }
            }

            this.args.allowanceDetails.push({
              allowanceId: temp,
              amount: this.removeSymbolsFromString(this.args.salary.grossAmount),
            });
          }
        }
        return true;
      } catch (e) {
        console.log(e, "total error");

        return false;
      }

      // bonus = true;
      // allowance = false;
    },
    handleSubmit() {
      const validate = this.validateAllowanceThenNext();
      if (validate) {
        this.transformAllowances();
        this.$emit("input", this.args);
        console.log(this.args, "sal args");
      } else {
        toaster.error(
          this.args.usePercentage
            ? "Allowances must be equal to 100% of gross salary"
            : "Allowances must add up to gross salary"
        );
      }
    },
    async createAllowance() {
      try {
        const res = await this.createUser({}, { input: { name: this.allowanceName } }, "CreateAllowance");
        if (res) {
          await this.readAllowance();
          toaster.success(`allowance created successfully.`);
          this.$refs.dismissBtn.click();
          //   this.$router.push("/staff/staff-list");
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },

    async readAllowance() {
      try {
        const res = await this.readUser({}, "GetAllowances", null);
        if (res) {
          console.log(res);
          this.allowanceData = res;
          // toaster.success(`Staff read successfully.`);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
  },

  async created() {
    await this.readAllowance();
  },
};
</script>
