<template>
  <div v-if="text" class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="blog" />
      <div class="card">
        <div class="card-body pb-0">
          <div class="row">
            <div class="col-sm-12 col-md-9">
              <div class="d-flex flex-row justify-content-start gap-2 align-items-center">
                <div class="top-liv-search top-chat-search">
                  <form>
                    <div class="chat-search">
                      <div class="input-block me-2 mb-0">
                        <input
                          type="text"
                          @keyup="searchFunction"
                          v-model="searchStr"
                          class="form-control"
                          placeholder="Search here"
                        />
                        <a class="btn"><img src="@/assets/img/icons/search-normal.svg" alt="" /></a>
                      </div>
                      <!-- <div @click="$router.push({ path: '/task/add-task' })" class="add-search">
                        <a href="javascript:;"><i class="feather-plus"></i></a>
                      </div> -->
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-3">
              <div class="invoices-settings-btn invoices-settings-btn-one">
                <button
                  @click="resolveTask"
                  v-show="deleteArray.length"
                  :style="{
                    cursor: deleteArray.length === 0 ? 'not-allowed' : 'pointer',

                    background: 'none',
                    border: 'none',
                  }"
                  :disabled="deleteArray.length === 0"
                  class="icons-items"
                  type="button"
                >
                  <ul class="list-unstyled icons-list">
                    <li>
                      <i class="fa fa-check"></i>
                    </li>
                  </ul>
                </button>
                <button
                  v-show="deleteArray.length"
                  :style="{
                    cursor: deleteArray.length === 0 ? 'not-allowed' : 'pointer',

                    background: 'none',
                    border: 'none',
                  }"
                  :disabled="deleteArray.length === 0"
                  class="icons-items"
                  data-bs-toggle="modal"
                  data-bs-target="#delete_task"
                  type="button"
                >
                  <ul class="list-unstyled icons-list">
                    <li>
                      <i class="fa fa-trash"></i>
                    </li>
                  </ul>
                </button>
                <router-link to="/task/add-task" class="btn">
                  <i class="feather feather-plus-circle"></i> New Task
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
      <div v-if="!newScr" class="row">
        <div class="col-sm-6 col-md-6 col-xl-4" v-for="record in visibleData" :key="record._id">
          <div class="blog grid-blog">
            <div class="d-flex justify-content-between mb-2">
              <input
                @change="handleRecordForDelete(record._id, true)"
                type="checkbox"
                name="select_specialist"
                :checked="deleteArray.includes(record._id)"
              />
              <div class="dropdown dropdown-action">
                <a href="javascript:void(0);" class="action-icon" data-bs-toggle="dropdown" aria-expanded="false"
                  ><i class="fas fa-ellipsis-v"></i
                ></a>
                <div class="dropdown-menu dropdown-menu-end">
                  <router-link class="dropdown-item" :to="`/task/add-task?id=${record._id}`"
                    ><i class="far fa-edit me-2"></i>Edit</router-link
                  >
                  <router-link class="dropdown-item" :to="`/task/task-details?id=${record._id}`"
                    ><i class="far fa-eye me-2"></i>View</router-link
                  >
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#delete_task"
                    class="btn delete-invoice-btn dropdown-item"
                    href="javascript:void(0);"
                    @click="handleRecordForDelete(record._id)"
                    ><i class="far fa-trash-alt me-2"></i>Delete</a
                  >
                </div>
              </div>
            </div>

            <div @click="$router.push({ path: '/task/task-details', query: { id: record._id } })" class="blog-image1">
              <router-link :to="`/task/task-details?id=${record._id}`"
                ><img class="img-fluid" :src="`${record.attachFile.full}`" alt=""
              /></router-link>
              <div class="blog-views">
                <h5 :class="record.resolved ? 'text-success' : checkTaskStatus(record.projectEnd).class">
                  {{ record.resolved ? "Completed" : checkTaskStatus(record.projectEnd).text }}
                </h5>
              </div>
              <ul v-if="record.documents && record.documents.length" class="nav view-blog-list blog-views">
                <li><i class="feather-link"></i> {{ record.documents.length }}</li>
                <!-- <li><i class="feather-message-square me-1"></i>{{ record.assignedTo.length }}</li> -->
                <!-- <li><i class="feather-eye me-1"></i>{{ record.Views }}</li> -->
              </ul>
            </div>
            <div class="blog-content">
              <div class="blog-grp-blk">
                <div class="blog-img-blk">
                  <router-link to="/profile"
                    ><img
                      class="img-fluid"
                      style="object-fit: cover"
                      :src="require(`@/assets/img/user-img.png`)"
                      alt=""
                  /></router-link>
                  <div class="content-blk-blog ms-2">
                    <h4>
                      <router-link to="/profile">{{ record.createdBy.fullName }}</router-link>
                    </h4>
                    <h5>{{ record.createdBy.email }}</h5>
                  </div>
                </div>
                <div class="content-blk-blog ms-2">
                  <h5>Due date</h5>
                  <span><i class="feather-calendar me-1"></i>{{ formatDateString(record.projectEnd) }}</span>
                </div>
              </div>
              <h3 class="blog-title">
                <router-link :to="`/task/task-details?id=${record._id}`">{{ record.taskName }}</router-link>
              </h3>
              <p>
                {{ truncateString(record.taskSummary, 84) }}
              </p>

              <div class="row gap-2">
                <div class="col-sm-12 col-md-8">
                  <router-link :to="`/task/task-details?id=${record._id}`" class="read-more d-flex">
                    {{ "View Details" }}<i class="fa fa-long-arrow-right ms-2"></i
                  ></router-link>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="avatar-group">
                    <!-- <div class="avatar">
                      <img
                        class="avatar-img rounded-circle border border-white"
                        alt="User Image"
                        src="@/assets/img/profiles/avatar-03.jpg"
                      />
                    </div> -->
                    <div v-for="person in record.assignedTo" :key="person._id" class="avatar popover-list">
                      <a-popover trigger="hover" placement="top">
                        <template #content>
                          <span>{{
                            `${person.personalInformation.firstName} ${person.personalInformation.lastName}`
                          }}</span>
                        </template>

                        <div
                          :style="`background-color:${getAvatar(person.personalInformation.firstName).color}`"
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            justify-self: end;
                            cursor: pointer;
                          "
                          class="avatar border border-white"
                        >
                          <span class="avatar-title rounded-circle">
                            {{ getAvatar(person.personalInformation.firstName).name }}</span
                          >
                        </div>
                      </a-popover>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="row">
        <div class="col-sm-6 col-lg-4 col-xl-3 d-flex" v-for="record in visibleData" :key="record._id">
          <div class="card invoices-grid-card w-100">
            <div style="position: relative" class="">
              <div
                :style="{
                  backgroundImage: ' url(' + record.attachFile.full + ')',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }"
                style="height: 130px"
                class="card-header d-flex justify-content-between align-items-center image-container"
              >
                <!-- <router-link class="invoice-grid-link">{{
                  record && record.createdBy && record.createdBy.fullName
                }}</router-link> -->
                <div
                  style="position: absolute; top: 0; left: 0; z-index: 50; margin: 5px 0 0 8px"
                  class="dropdown dropdown-action"
                >
                  <input
                    @change="handleRecordForDelete(record._id, true)"
                    type="checkbox"
                    name="select_specialist"
                    :checked="deleteArray.includes(record._id)"
                  />
                </div>
                <div
                  style="position: absolute; top: 0; right: 0; z-index: 50; margin: 5px 8px 0 0"
                  class="dropdown dropdown-action"
                >
                  <a
                    href="javascript:void(0);"
                    class="action-icon-white"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ><i class="fas fa-ellipsis-v"></i
                  ></a>
                  <div class="dropdown-menu dropdown-menu-end">
                    <router-link class="dropdown-item" :to="`/task/add-task?id=${record._id}`"
                      ><i class="far fa-edit me-2"></i>Edit</router-link
                    >
                    <router-link class="dropdown-item" :to="`/task/task-details?id=${record._id}`"
                      ><i class="far fa-eye me-2"></i>View</router-link
                    >
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#delete_task"
                      class="btn delete-invoice-btn dropdown-item"
                      href="javascript:void(0);"
                      @click="handleRecordForDelete(record._id)"
                      ><i class="far fa-trash-alt me-2"></i>Delete</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- <div style="margin-top: -20px" class="card-body">
              <div class="row align-items-center">
                <router-link :to="`/task/task-details?id=${record._id}`"
                  ><img style="height: 100px; width: 100%" class="img-fluid" :src="`${record.attachFile.full}`" alt=""
                /></router-link>
              </div>
            </div> -->
            <div class="card-middle1">
              <h2 style="font-size: 14px" class="card-middle1-avatar">
                <router-link :to="`/task/task-details?id=${record._id}`">
                  <!-- <img
                    class="avatar avatar-sm me-2 avatar-img rounded-circle"
                    :src="`${record.attachFile.full}`"
                    alt="User Image"
                  /> -->
                  {{ record.taskName }}</router-link
                >
              </h2>
            </div>

            <div class="card-body">
              <div class="row align-items-center">
                <span style="font-size: 14px">
                  {{ truncateString(record.taskSummary, 60) }}
                </span>
              </div>
            </div>

            <div style="margin-top: -20px" class="card-body">
              <div class="row align-items-center">
                <div v-if="record.documents && record.documents.length" class="col d-flex gap-2">
                  <span><i class="feather-link"></i></span>
                  <span class="mb-0">{{ record.documents.length }}</span>
                </div>
                <div class="col-auto">
                  <span><i class="far fa-calendar-alt"></i> {{ "Due date" }}</span>
                  <h6 style="font-weight: 400" class="mb-0">{{ formatDateString(record.projectEnd) }}</h6>
                </div>
              </div>
            </div>
            <div style="padding: 5px 10px">
              <div class="col-sm-12 col-md-4">
                <div class="avatar-group">
                  <!-- <div class="avatar">
                      <img
                        class="avatar-img rounded-circle border border-white"
                        alt="User Image"
                        src="@/assets/img/profiles/avatar-03.jpg"
                      />
                    </div> -->
                  <div v-for="person in record.assignedTo" :key="person._id" class="avatar popover-list">
                    <a-popover trigger="hover" placement="top">
                      <template #content>
                        <span>{{
                          `${person.personalInformation.firstName} ${person.personalInformation.lastName}`
                        }}</span>
                      </template>

                      <div
                        :style="`background-color:${getAvatar(person.personalInformation.firstName).color}`"
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          text-align: center;
                          justify-self: end;
                          cursor: pointer;
                        "
                        class="avatar border border-white"
                      >
                        <span class="avatar-title rounded-circle">
                          {{ getAvatar(person.personalInformation.firstName).name }}</span
                        >
                      </div>
                    </a-popover>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="badge bg-success-dark" v-if="record.resolved">{{ "Completed" }}</span>
                  <span
                    class="badge bg-danger-dark"
                    v-else-if="checkTaskStatus(record.projectEnd).text === 'Overdue'"
                    >{{ checkTaskStatus(record.projectEnd).text }}</span
                  >
                  <!-- <span
                    class="badge bg-secondary-dark"
                    v-else-if="item.status === 'Cancelled'"
                    >{{ item.status }}</span
                  > -->
                  <span
                    class="badge bg-primary-dark"
                    v-else-if="checkTaskStatus(record.projectEnd).text === 'In progress'"
                    >{{ checkTaskStatus(record.projectEnd).text }}</span
                  >
                </div>
                <div class="col text-end" v-if="checkTaskStatus(record.projectEnd).text === 'Overdue'">
                  <span class="text-danger text-sm">Overdue {{ getDaysElapsed(record.projectEnd) }} days</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-12">
          <div class="invoice-load-btn">
            <a href="javascript:void(0);" class="btn"> <span class="spinner-border text-primary"></span> Load more </a>
          </div>
        </div> -->
      </div>
    </div>

    <div class="modal custom-modal modal-bg fade" id="delete_task" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Task</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <b-button
                    @click="deleteTask"
                    type="submit"
                    style="width: 100%"
                    variant="btn btn-primary paid-continue-btn "
                    ><span
                      :class="loading ? 'spinner-border spinner-border-sm' : undefined"
                      class="me-2"
                      role="status"
                    ></span
                    >Delete</b-button
                  >
                  <!-- <a @click="deleteTask" href="javascript:void(0);" class="btn btn-primary paid-continue-btn">Delete</a> -->
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    ref="cancelBtn"
                    class="btn btn-primary paid-cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { helperFunctions } from "@/composables/helperFunctions";
import { useProfileStore } from "@/stores/profile.js";
import { computed, ref } from "vue";
import Task from "@/assets/json/task.json";
import Excel from "@/components/docs/Excel.vue";
import Word from "@/components/docs/Word.vue";
import { Tooltip } from "ant-design-vue";

export default {
  setup() {
    const profile = useProfileStore();
    const { readUser, createUser } = profile;
    const { truncateString, formatDateString, getAvatar, checkTaskStatus, getDaysElapsed, searchArrayOfObjects } =
      helperFunctions;

    const allTasks = computed(() => profile.getAllTasks);
    const visibleData = ref([]);
    const searchStr = ref("");
    function searchFunction() {
      if (!searchStr.value) {
        visibleData.value = allTasks.value;
        return;
      }
      visibleData.value = searchArrayOfObjects(allTasks.value, searchStr.value, "taskName");
    }

    return {
      truncateString,
      formatDateString,
      getAvatar,
      checkTaskStatus,
      getDaysElapsed,
      readUser,
      createUser,
      allTasks,
      searchFunction,
      visibleData,
      searchStr,
    };
  },
  components: { Excel, Word, "a-tooltip": Tooltip },
  data() {
    return {
      Blog: Task,
      title: "Task",
      text: "Tasks",
      deleteArray: [],
      loading: false,
      newScr: true,
    };
  },
  methods: {
    async readTasks() {
      try {
        const res = await this.readUser({}, "FetchTasks", "tasks");
        if (res) {
          // console.log(res);
          this.visibleData = res;
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
    async deleteTask() {
      this.loading = true;
      try {
        const res = await this.createUser({}, { taskIds: this.deleteArray }, "DeleteTask");
        if (res && res.success) {
          this.deleteArray = [];
          await this.readTasks();
          this.$refs.cancelBtn.click();
          this.toaster.success(`Task deleted successfully.`);

          // this.$emit("close");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async resolveTask() {
      this.loading = true;
      try {
        const res = await this.createUser({}, { taskIds: this.deleteArray }, "ResolveTasks");
        if (res && res.success) {
          this.deleteArray = [];
          await this.readTasks();
          this.$refs.cancelBtn.click();
          this.toaster.success(res.message);

          // this.$emit("close");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    handleRecordForDelete(id, multiple = false) {
      if (multiple) {
        if (this.deleteArray.includes(id)) {
          this.deleteArray.splice(this.deleteArray.indexOf(id), 1);
          return;
        }
        this.deleteArray.push(id);

        return;
      }
      this.deleteArray = [];
      this.deleteArray.push(id);
    },
  },

  async created() {
    await this.readTasks();
  },
};
</script>
<style scoped>
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inline-no-wrap {
  display: inline-block;
  white-space: nowrap;
}
.wrap-text-inline {
  display: inline;
  max-width: 100%; /* Adjust the width to control wrapping */
  padding: 5px;
  word-wrap: break-word; /* Ensure long words break correctly */
  text-align: center;
}

.wrap-text {
  float: left;
  width: 100%; /* Set to the desired width */
  padding: 5px;
  border: 1px solid #ccc; /* Optional: for visual reference */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}
.clear {
  clear: both;
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3); /* Black overlay with 50% opacity */
  z-index: 1;
}
</style>
