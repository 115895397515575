<template>
  <div id="app">
    <Modal v-if="checkLoading" />
    <div style="position: relative" class="main-wrapper">
      <layout-header v-if="showHeader"></layout-header>

      <sidebar v-if="showSidebar"></sidebar>

      <router-view />

      <!-- Long Content Scroll Modal -->
      <div
        class="modal fade"
        id="scrollable-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="scrollableModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-scrollable" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="scrollableModalTitle">
                Staff Details
              </h4>
              <button
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="personal-list-out">
                <h4>Personal Information</h4>
                <div class="row">
                  <div class="mt-2 mb-4 col-xl-12 col-md-6">
                    <div class="detail-personal">
                      <h2>Full Name</h2>
                      <h3>
                        {{
                          userData &&
                          userData.personalInformation &&
                          userData.personalInformation.firstName
                        }}
                        {{
                          userData &&
                          userData.personalInformation &&
                          userData.personalInformation.lastName
                        }}
                      </h3>
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-6">
                    <div class="detail-personal">
                      <h2>Staff ID</h2>
                      <h3>
                        {{
                          (userData &&
                            userData.personalInformation &&
                            userData.personalInformation.userName) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-6">
                    <div class="detail-personal">
                      <h2>Gender</h2>
                      <h3>
                        {{
                          (userData &&
                            userData.personalInformation &&
                            userData.personalInformation.gender) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-6">
                    <div class="detail-personal">
                      <h2>Date of Birth</h2>
                      <h3>
                        {{
                          (userData &&
                            userData.personalInformation &&
                            userData.personalInformation.birthDate) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>

                  <!-- <div class="col-xl-4 col-md-6">
                    <div class="detail-personal">
                      <h2>Position</h2>
                      <h3>cox</h3>
                    </div>
                  </div> -->
                </div>

                <h4 class="mt-4 mb-2">Job Information</h4>
                <div class="row">
                  <div class="mt-2 mb-4 col-xl-12 col-md-6">
                    <div class="detail-personal">
                      <h2>Position</h2>
                      <h3>{{ userData.position || "N/A" }}</h3>
                    </div>
                  </div>
                  <!-- <div class="col-xl-4 col-md-6">
                    <div class="detail-personal">
                      <h2>Staff ID</h2>
                      <h3>Lc8390</h3>
                    </div>
                  </div> -->

                  <div class="col-xl-6 col-md-6">
                    <div class="detail-personal">
                      <h2>Department</h2>
                      <h3>{{ userData.department || "N/A" }}</h3>
                    </div>
                  </div>

                  <div class="col-xl-6 col-md-6">
                    <div class="detail-personal">
                      <h2>Date of joining</h2>
                      <h3>{{ userData.dateOfJoining }}</h3>
                    </div>
                  </div>
                </div>

                <h4 class="mt-4 mb-2">Education Information</h4>

                <div class="row">
                  <div class="mt-2 mb-4 col-xl-12 col-md-6">
                    <div class="detail-personal">
                      <h2>University</h2>
                      <h3>
                        {{
                          (userData.educationInformation &&
                            userData.educationInformation[0] &&
                            userData.educationInformation[0].university) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>
                  <div class="mt-2 mb-4 col-xl-12 col-md-6">
                    <div class="detail-personal">
                      <h2>Degree</h2>
                      <h3>
                        {{
                          (userData.educationInformation &&
                            userData.educationInformation[0] &&
                            userData.educationInformation[0].degree) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>

                  <div class="col-xl-4 mb-4 col-md-6">
                    <div class="detail-personal">
                      <h2>Result</h2>
                      <h3>
                        {{
                          (userData.educationInformation &&
                            userData.educationInformation[0] &&
                            userData.educationInformation[0].result) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>

                  <div class="col-xl-4 mb-4 col-md-6">
                    <div class="detail-personal">
                      <h2>Start year</h2>
                      <h3>
                        {{
                          (userData.educationInformation &&
                            userData.educationInformation[0] &&
                            userData.educationInformation[0].startYear) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>
                  <div class="col-xl-4 mb-4 col-md-6">
                    <div class="detail-personal">
                      <h2>Graduation Year</h2>
                      <h3>
                        {{
                          (userData.educationInformation &&
                            userData.educationInformation[0] &&
                            userData.educationInformation[0].graduationYear) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>
                  <!-- 
                  <div class="col-xl-4 col-md-6">
                    <div class="detail-personal">
                      <h2>State</h2>
                      <h3> {{
                          (userData.contactInformation &&
                            userData.contactInformation &&
                            userData.contactInformation.state) ||
                          "N/A"
                        }}</h3>
                    </div>
                  </div> -->

                  <!-- <div class="col-xl-4 col-md-6">
                    <div class="detail-personal">
                      <h2>Country</h2>
                      <h3>{{
                          (userData.contactInformation &&
                            userData.contactInformation &&
                            userData.contactInformation.country) ||
                          "N/A"
                        }}</h3>
                    </div>
                  </div> -->

                  <!-- <div class="col-xl-4 col-md-6">
                    <div class="detail-personal">
                      <h2>Postal code</h2>
                      <h3>{{
                          (userData.contactInformation &&
                            userData.contactInformation &&
                            userData.contactInformation.postalCode) ||
                          "N/A"
                        }}</h3>
                    </div>
                  </div> -->
                </div>

                <h4 class="mt-4 mb-2">Contact Information</h4>

                <div class="row">
                  <div class="mt-2 mb-4 col-xl-12 col-md-6">
                    <div class="detail-personal">
                      <h2>Address</h2>
                      <h3>
                        {{
                          (userData.contactInformation &&
                            userData.contactInformation &&
                            userData.contactInformation.address) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>
                  <div class="col-xl-6 mb-4 col-md-6">
                    <div class="detail-personal">
                      <h2>Phone</h2>
                      <h3>
                        {{
                          (userData.contactInformation &&
                            userData.contactInformation &&
                            userData.contactInformation.phoneNumber) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>
                  <div class="col-xl-6 mb-4 col-md-6">
                    <div class="detail-personal">
                      <h2>Email</h2>
                      <h3>
                        {{
                          (userData.contactInformation &&
                            userData.contactInformation &&
                            userData.contactInformation.email) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>
                  <div class="col-xl-4 mb-4 col-md-6">
                    <div class="detail-personal">
                      <h2>City</h2>
                      <h3>
                        {{
                          (userData.contactInformation &&
                            userData.contactInformation &&
                            userData.contactInformation.city) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>

                  <div class="col-xl-4 col-md-6">
                    <div class="detail-personal">
                      <h2>State</h2>
                      <h3>
                        {{
                          (userData.contactInformation &&
                            userData.contactInformation &&
                            userData.contactInformation.state) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>

                  <div class="col-xl-4 col-md-6">
                    <div class="detail-personal">
                      <h2>Country</h2>
                      <h3>
                        {{
                          (userData.contactInformation &&
                            userData.contactInformation &&
                            userData.contactInformation.country) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>

                  <div class="col-xl-4 col-md-6">
                    <div class="detail-personal">
                      <h2>Postal code</h2>
                      <h3>
                        {{
                          (userData.contactInformation &&
                            userData.contactInformation &&
                            userData.contactInformation.postalCode) ||
                          "N/A"
                        }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <b-button variant="secondary me-1" data-bs-dismiss="modal">
                Close
              </b-button>
              <b-button variant="primary"> Verify Details </b-button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->

      <b-button
        style="visibility: hidden"
        id="scrollable-modal-button"
        variant="secondary mt-1"
        data-bs-toggle="modal"
        data-bs-target="#scrollable-modal"
      >
        Scrollable Modal
      </b-button>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import gql from "graphql-tag";
import { computed, ref, watch, watchEffect } from "vue";
import { useProfileStore } from "@/stores/profile.js";
import Modal from "@/components/Modal.vue";
import { useSubscription } from "@vue/apollo-composable";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const router = useRouter();
    const store = useProfileStore();
    const { getIsLoading } = storeToRefs(store);
    const userData = ref({});
    const loading = ref([]);
    const checkLoading = ref(false);
    watch(
      loading,
      (val) => {
        if (val.length > 0) {
          checkLoading.value = true;
        } else {
          checkLoading.value = false;
        }
      },
      { deep: true }
    );
    store.$subscribe((mutation, state) => {
      loading.value = state.isLoading;
      // if (state.isLoading.length > 0) {
      //   loading.value = true;
      // } else {
      //   loading.value = false;
      // }

      // persist the whole state to the local storage whenever it changes
      // localStorage.setItem("cart", JSON.stringify(state));
    });

    const { result, onResult } = useSubscription(
      gql`
        subscription Subscription {
          tagAdded
        }
      `
    );
    const { result: messageRes, onResult: onMessageRes } = useSubscription(
      gql`
        subscription Subscription {
          newMessage
        }
      `
    );

    const { result: attendanceRes, onResult: onAttendanceRes } =
      useSubscription(
        gql`
          subscription Subscription {
            newAttendance
          }
        `
      );

    onAttendanceRes(function (data) {
      if (data.data.newAttendance) {
        // eslint-disable-line
        console.log(data.data.newAttendance);
        store.$patch((state) => {
          state.attendance.push(data.data.newAttendance);
        });

        // console.log("New message received:", data);
      }
    });

    onMessageRes(function (data) {
      if (data.data.newMessage) {
        // eslint-disable-line
        store.$patch((state) => {
          state.messages.push(data.data.newMessage);
        });

        // console.log("New message received:", data);
      }
    });

    onResult(function (data) {
      if (data.data.tagAdded) {
        // eslint-disable-line
        userData.value = data.data.tagAdded;
        document.getElementById("scrollable-modal-button").click();
      }
    });

    // watch(
    //   result,
    //   (data) => {
    //     console.log("New message received:", data);
    //   },
    //   {
    //     lazy: true, // Don't immediately execute handler
    //   }
    // );
    return {
      router,
      userData,
      loading,
      checkLoading,
    };
  },
  components: { Modal },
  computed: {
    showHeader() {
      return (
        this.$route.path !== "/" &&
        this.$route.path !== "/register" &&
        this.$route.path !== "/login" &&
        this.$route.path !== "/forgot-password" &&
        this.$route.path !== "/change-password2" &&
        this.$route.path !== "/lock-screen" &&
        this.$route.path !== "/error-404" &&
        this.$route.path !== "/error-500"
      );
    },
    showSidebar() {
      return (
        this.$route.path !== "/" &&
        this.$route.path !== "/register" &&
        this.$route.path !== "/login" &&
        this.$route.path !== "/forgot-password" &&
        this.$route.path !== "/change-password2" &&
        this.$route.path !== "/lock-screen" &&
        this.$route.path !== "/error-404" &&
        this.$route.path !== "/error-500"
      );
    },
  },
  // mounted() {
  //   // When the component is mounted, open the scrollable-modal modal
  //   this.$nextTick(() => {
  //     // eslint-disable-line
  //     document.getElementById("scrollable-modal-button").click();
  //   });
  // },
};
</script>

<style></style>
