<template>
  <!-- Page Header -->
  <!-- /Page Header -->
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="activity">
            <div class="activity-box">
              <ul v-if="singleTask && singleTask.taskTodo" class="activity-list">
                <li v-for="(ms, i) in singleTask.taskTodo" :key="i">
                  <div class="activity-user">
                    <router-link to="/profile" title="Lesley Grauer" data-bs-toggle="tooltip" class="avatar">
                      <img alt="Lesley Grauer" src="@/assets/img/user-02.jpg" class="img-fluid rounded-circle" />
                    </router-link>
                  </div>
                  <div class="activity-content timeline-group-blk">
                    <div class="timeline-group flex-shrink-0">
                      <h4>{{ getFormattedDate(ms.date).shortDate }}</h4>
                      <span class="time">{{ getFormattedDate(ms.date).time }}</span>
                    </div>
                    <div class="comman-activitys flex-grow-1">
                      <h5 style="font-size: 14px">
                        {{ ms.title }}
                      </h5>
                      <p>{{ ms.description }}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useProfileStore } from "@/stores/profile.js";
import { computed } from "vue";
export default {
  setup() {
    const profile = useProfileStore();

    const singleTask = computed(() => profile.getSingleTask);

    function getFormattedDate(dateStr) {
      const date = new Date(dateStr);

      const formattedFullDate = date.toLocaleDateString("en-US", {
        // weekday: "long",
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      const formattedTime = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return { shortDate: formattedFullDate, time: formattedTime };
    }

    return { getFormattedDate, singleTask };
  },
  data() {
    return {
      title: "Activites",
      text: "User Activity",
    };
  },
};
</script>
