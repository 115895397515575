<template>
  <div class="col-xl-6">
    <div class="card">
      <div class="card-body">
        <h4 class="header-title mb-4">Tabs Bordered Justified</h4>

        <ul class="nav nav-tabs nav-bordered nav-justified">
          <li class="nav-item">
            <a
              href="#home-b2"
              data-bs-toggle="tab"
              aria-expanded="false"
              class="nav-link"
            >
              Home
            </a>
          </li>
          <li class="nav-item">
            <a
              href="#profile-b2"
              data-bs-toggle="tab"
              aria-expanded="true"
              class="nav-link active"
            >
              Profile
            </a>
          </li>
          <li class="nav-item">
            <a
              href="#messages-b2"
              data-bs-toggle="tab"
              aria-expanded="false"
              class="nav-link"
            >
              Messages
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane" id="home-b2">
            <p>
              Lorem ipsum is placeholder text commonly used in the graphic, print, and
              publishing industries for previewing layouts and visual mockups.Lorem ipsum
              is placeholder text commonly used in the graphic, print, and publishing
              industries for previewing layouts and visual mockups.
            </p>
            <p class="mb-0">
              Lorem ipsum is placeholder text commonly used in the graphic, print, and
              publishing industries for previewing layouts and visual mockups.Lorem ipsum
              is placeholder text commonly used in the graphic, print, and publishing
              industries for previewing layouts and visual mockups.
            </p>
          </div>
          <div class="tab-pane active" id="profile-b2">
            <p>
              Lorem ipsum is placeholder text commonly used in the graphic, print, and
              publishing industries for previewing layouts and visual mockups.Lorem ipsum
              is placeholder text commonly used in the graphic, print, and publishing
              industries for previewing layouts and visual mockups.
            </p>
            <p class="mb-0">
              Lorem ipsum is placeholder text commonly used in the graphic, print, and
              publishing industries for previewing layouts and visual mockups.Lorem ipsum
              is placeholder text commonly used in the graphic, print, and publishing
              industries for previewing layouts and visual mockups.
            </p>
          </div>
          <div class="tab-pane" id="messages-b2">
            <p>
              Lorem ipsum is placeholder text commonly used in the graphic, print, and
              publishing industries for previewing layouts and visual mockups.Lorem ipsum
              is placeholder text commonly used in the graphic, print, and publishing
              industries for previewing layouts and visual mockups.
            </p>
            <p class="mb-0">
              Lorem ipsum is placeholder text commonly used in the graphic, print, and
              publishing industries for previewing layouts and visual mockups.Lorem ipsum
              is placeholder text commonly used in the graphic, print, and publishing
              industries for previewing layouts and visual mockups.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- end card-->
  </div>
</template>
