<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="department-list" />
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="createDept">
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Add Department</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Department Name <span class="login-danger">*</span></label>
                      <input v-model="args.name" class="form-control" type="text" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Department Head <span class="login-danger">*</span></label>
                      <vue-select :options="allStaff" placeholder="Select Staff" v-model="args.head" />
                    </div>
                  </div>
                  <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label>Description <span class="login-danger">*</span></label>
                      <textarea class="form-control" rows="3" cols="30" v-model="args.description"></textarea>
                    </div>
                  </div>
                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms cal-icon">
                      <label
                        >Department Date
                        <span class="login-danger">*</span></label
                      >
                      <datepicker
                        v-model="startdate"
                        :input-format="dateFormat"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        placeholder=""
                      />
                    </div>
                  </div> -->
                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block select-gender">
                      <label class="gen-label"
                        >Status <span class="login-danger">*</span></label
                      >
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            name="gender"
                            class="form-check-input"
                          />Active
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="radio"
                            name="gender"
                            class="form-check-input"
                          />In Active
                        </label>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button type="submit" class="btn btn-primary submit-form me-2">Create Department</button>
                      <button type="submit" class="btn btn-primary cancel-form">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { router } from "@/router";
import { useProfileStore } from "@/stores/profile.js";

import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-right",
  showCloseButton: true,
  duration: 5000,
});
const currentDate = ref(new Date());
export default {
  setup() {
    const profile = useProfileStore();
    const { createUser, readUser } = profile;

    const allStaff = computed(() => {
      const data =
        profile.getAllStaff &&
        profile.getAllStaff.map((item) => {
          return {
            id: item._id,
            text: `${item.personalInformation.firstName} ${item.personalInformation.lastName}`,
          };
        });
      return data;
    });

    return {
      allStaff,
      createUser,
      readUser,
    };
  },
  data() {
    return {
      title: "Department",
      text: "Add Department",
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      args: {
        name: "",
        head: "", //add staffHead and doctorHead later
        description: "",
      },
    };
  },

  methods: {
    async createDept() {
      try {
        const res = await this.createUser({}, { input: this.args }, "CreateDepartment");
        if (res) {
          toaster.success(`Department created successfully.`);

          router.push("/departments/department-list");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async readStaff() {
      try {
        const res = await this.readUser({}, "GetAllStaff", "staffs");
        if (res) {
          console.log(res);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
  },
  async created() {
    // await this.readDept();
    await this.readStaff();
  },
};
</script>
