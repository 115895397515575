<template>
  <main>
    <div
      @click.self="clickOutSide"
      style="z-index: 999; position: fixed; top: 0; left: 0; bottom: 0; right: 0; background: rgba(255, 255, 255, 0.5)"
      class="inset-0 -z-5 w-full overflow-y-auto py-16"
    >
      <div style="position: absolute; top: 0; width: 100vw; margin: 20% 50%; left: 0; right: 0; z-index: 999">
        <!-- <img style="width: 100px" src="@/assets/css/loader.gif" alt="" /> -->
        <div class="col-lg-12">
          <b-spinner label="Large Spinner" class="avatar-lg text-primary m-2"></b-spinner>
          <!-- <b-spinner type="grow" label="Large Spinner" class="avatar-lg text-secondary m-2"></b-spinner> -->
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
const props = defineProps({
  isHeader: {
    type: Boolean,
    default: true,
  },
  isBorder: {
    type: Boolean,
    default: true,
  },
  apiDashboard: {
    type: Boolean,
    default: false,
  },
  compliance: {
    type: Boolean,
    default: false,
  },
  payroll: {
    type: Boolean,
    default: false,
  },

  lite: {
    type: Boolean,
    default: false,
  },
  terms: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: String,
    default: "lg",
  },
  color: {
    type: String,
    default: "bg-background-100",
  },
  persistence: {
    type: Boolean,
    default: true,
  },
  showCloseButton: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["update", "close"]);

function close() {
  emit("close");
}

const clickOutSide = () => {
  if (!props.persistence) {
    close();
  }
};
</script>

<style></style>
