<template>
  <div class="row">
    <div class="col-xl-6">
      <div class="card">
        <div class="card-body card-buttons">
          <!-- <h4 class="header-title">Static backdrop</h4> -->

          <!-- <p>
            When backdrop is set to static, the modal will not close when clicking outside
            it. Click the button below to try it.
          </p> -->

          <button
            style="visibility: hidden"
            ref="launch"
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            Launch static backdrop modal
          </button>

          <!-- Modal -->
          <div
            class="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header-share align-items-center">
                  <div class="d-flex align-items-center">
                    <div style="border: none; background: none; margin-top: 10px" class="icons-items">
                      <ul class="list-unstyled icons-list">
                        <li>
                          <i class="fa fa-users"></i>
                        </li>
                      </ul>
                    </div>
                    <h5 class="modal-title" id="staticBackdropLabel">Collaborate with others</h5>
                  </div>

                  <button
                    @click="$emit('close')"
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <form action="">
                    <div class="col-12 col-md-6 col-xl-12">
                      <div class="input-block local-forms">
                        <label>Add people <span class="login-danger">*</span></label>
                        <!-- <vue-select
                          :options="allStaff"
                          placeholder="Select Staff"
                          v-model="args.head"
                        /> -->
                        <multiselect
                          v-model="value"
                          :options="allStaff"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          label="label"
                          track-by="value"
                          :preselect-first="true"
                        >
                          <template #selection="{ values, isOpen }">
                            <span class="multiselect__single" v-if="values.length" v-show="!isOpen"
                              >{{ values.length }} options selected</span
                            >
                          </template>
                        </multiselect>
                      </div>
                    </div>
                    <div v-if="value && value.length" style="height: 200px; overflow-y: auto" class="email-content">
                      <!-- <div class="table-responsive"> remove table-responsiveness class -->
                      <div class="">
                        <table class="table table-inbox table-hover">
                          <tbody>
                            <tr v-for="(person, i) in value" :key="i" data-href="person-view" style="height: auto">
                              <td class="name">
                                <div class="email-img-blk">
                                  <div
                                    :style="`background-color:${getAvatar(person.label).color}`"
                                    style="
                                      text-transform: uppercase;
                                      text-align: center;
                                      display: flex;
                                      flex-direction: column;
                                      justify-content: center;
                                      color: white;
                                      width: 36px;
                                      height: 36px;
                                      margin-right: 10px;
                                      border-radius: 50%;
                                    "
                                    class="email-img"
                                  >
                                    {{ getAvatar(person.label).name }}
                                    <!-- <img style="object-fit: cover" src="@/assets/img/person-3.jpeg" alt="img" /> -->
                                  </div>
                                  <div class="send-user">
                                    <h4>
                                      {{ person.label }}
                                      <!-- <span class="email-market">Work</span> -->
                                    </h4>
                                    <p>{{ person.email }}</p>
                                  </div>
                                </div>
                              </td>

                              <td class="">
                                <div class="dropdown">
                                  <a
                                    class="dropdown-toggle"
                                    href="javascript:;"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    {{ typesOfAccess[person.accessType] }}
                                  </a>
                                  <div style="z-index: 999" class="dropdown-menu">
                                    <a @click="person.accessType = 'READ'" class="dropdown-item" href="javascript:;"
                                      >Viewer</a
                                    >
                                    <a @click="person.accessType = 'WRITE'" class="dropdown-item" href="javascript:;"
                                      >Editor</a
                                    >
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button @click="$emit('close')" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button>
                  <!-- <button @click="updateDoc" type="button" class="btn btn-primary ms-2">Share</button> -->
                  <b-button @click="updateDoc" type="button" variant="primary me-1"
                    ><span
                      :class="loading ? 'spinner-border spinner-border-sm' : undefined"
                      class="me-2"
                      role="status"
                    ></span
                    >Share</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { router } from "@/router";
import { useProfileStore } from "@/stores/profile.js";

import { createToaster } from "@meforma/vue-toaster";
import Multiselect from "vue-multiselect";

const toaster = createToaster({
  position: "top-right",
  showCloseButton: true,
  duration: 5000,
});
const currentDate = ref(new Date());
export default {
  components: { Multiselect },
  emits: ["close"],
  props: ["docId"],
  setup() {
    const profile = useProfileStore();
    const { createUser, readUser } = profile;
    const typesOfAccess = { READ: "Viewer", WRITE: "Editor" };

    const allStaff = computed(() => {
      const data =
        profile.getAllStaff &&
        profile.getAllStaff.map((item) => {
          return {
            value: item._id,
            label: `${item.personalInformation.firstName} ${item.personalInformation.lastName}`,
            email: item.contactInformation.email,
            accessType: "READ",
          };
        });
      return data;
    });
    function getColor(index) {
      let n = (index * 0xfffff * 1000000).toString(16);
      return "#" + n.slice(0, 6);
    }
    function getAvatar(string) {
      let name = "",
        color;
      // Get color
      let alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

      const initialOne = String(string.charAt(0));
      const initialTwo = String(string.charAt(1));
      const num =
        Number(alphabet.indexOf(initialOne.toLowerCase())) + Number(alphabet.indexOf(initialTwo.toLowerCase()));
      color = getColor(num);

      // Get Shortend Name
      // string ? (name = `${string.charAt(0)}${string.charAt(1)}`) : "";
      string ? (name = `${string.charAt(0)}`) : "";

      return {
        color,
        name,
      };
    }
    return {
      allStaff,
      createUser,
      readUser,
      getAvatar,
      typesOfAccess,
    };
  },
  data() {
    return {
      title: "Department",
      text: "Add Department",
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      value: [],
      args: {
        name: "",
        head: "", //add staffHead and doctorHead later
        description: "",
      },
      loading: false,
    };
  },

  methods: {
    async updateDoc() {
      this.loading = true;
      const access = this.value.map((col) => {
        return {
          staffId: col.value,
          accessType: col.accessType,
        };
      });
      try {
        const res = await this.createUser({}, { updateDocumentId: this.docId, input: { access } }, "UpdateDocument");
        if (res) {
          toaster.success(`Document shared successfully.`);

          this.$emit("close");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async readStaff() {
      try {
        const res = await this.readUser({}, "GetAllStaff", "staffs");
        if (res) {
          console.log(res);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
  },
  async created() {
    // await this.readDept();
    await this.readStaff();
    this.$refs.launch.click();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
