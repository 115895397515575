<template>
  <div class="row">
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card inovices-card">
        <div class="card-body">
          <div class="inovices-widget-header">
            <span class="inovices-widget-icon">
              <img src="@/assets/img/invoices-icon1.svg" alt="" />
            </span>
            <div class="inovices-dash-count">
              <div class="inovices-amount">$8,78,797</div>
            </div>
          </div>
          <p class="inovices-all">All Invoices <span>50</span></p>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card inovices-card">
        <div class="card-body">
          <div class="inovices-widget-header">
            <span class="inovices-widget-icon">
              <img src="@/assets/img/invoices-icon2.svg" alt="" />
            </span>
            <div class="inovices-dash-count">
              <div class="inovices-amount">$4,5884</div>
            </div>
          </div>
          <p class="inovices-all">Paid Invoices <span>60</span></p>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card inovices-card">
        <div class="card-body">
          <div class="inovices-widget-header">
            <span class="inovices-widget-icon">
              <img src="@/assets/img/invoices-icon3.svg" alt="" />
            </span>
            <div class="inovices-dash-count">
              <div class="inovices-amount">$2,05,545</div>
            </div>
          </div>
          <p class="inovices-all">Unpaid Invoices <span>70</span></p>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 col-12">
      <div class="card inovices-card">
        <div class="card-body">
          <div class="inovices-widget-header">
            <span class="inovices-widget-icon">
              <img src="@/assets/img/invoices-icon4.svg" alt="" />
            </span>
            <div class="inovices-dash-count">
              <div class="inovices-amount">$8,8,797</div>
            </div>
          </div>
          <p class="inovices-all">Cancelled Invoices <span>80</span></p>
        </div>
      </div>
    </div>
  </div>
</template>
