<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="video-call" />
      <!-- /Page Header -->
      <div class="row">
        <div class="col-xl-12">
          <Home />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Home from "@/components/Home.vue";
export default {
  data() {
    return {
      title: "App",
      text: "Video Call",
    };
  },
  components: { Home },
};
</script>

<style scoped>
.page-wrapper {
  --grey-lightest: #f7f9fa;
  --white: #ffffff;
  --grey: #c8d1dc;
  --dark-grey: #6b7785;
  --dark-blue: #1f2d3d;
  --dark-blue-border: #2b3f56;
  --teal: #009efb;
  --red-dark: #bb0c0c;
  --font-family: GraphikRegular, "Helvetica Neue", Sans-Serif;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
