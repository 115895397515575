<template>
  <main>
    <form @submit.prevent="$route.query.id ? editDoc() : createDoc()" action="">
      <div class="row">
        <div
          style="margin-bottom: 10px; position: relative"
          class="col-12 gap-5"
        >
          <input
            style="
              position: absolute;
              bottom: -10;
              text-transform: capitalize;
              border: none;
              background: none;
            "
            type="text"
            v-model="args.docName"
          />

          <div class="doctor-submit text-end">
            <button
              @click="$emit('share')"
              style="border: none; background: none"
              class="icons-items"
              type="button"
            >
              <ul class="list-unstyled icons-list">
                <li>
                  <i class="fa fa-share-alt"></i>
                </li>
              </ul>
            </button>

            <button type="button" class="btn btn-primary cancel-form me-2">
              Cancel
            </button>
            <button
              name="submitbtn"
              :disabled="!isChanged"
              type="submit"
              class="btn btn-primary submit-form"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <editor
        api-key="qbuwhmfmq7n3lbo04fxnroyl623by920cmrotjyhe1v6vvwy"
        :init="{
          plugins:
            'lists link image paste help wordcount save searchreplace table autosave fullscreen',
          toolbar:
            'undo redo save cancel restoredraft fullscreen | blocks fontsize bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent image | searchreplace | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol help',
          autosave_ask_before_unload: true,
          autosave_interval: '30s',
          autosave_restore_when_empty: true,
          autosave_retention: '30m',
          fullscreen_native: true,

          save_oncancelcallback: () => {
            $router.go(-1);
            console.log('Save canceled');
          },
          save_onsavecallback: () => {
            console.log('Saved');
          },
        }"
        model-events="change keydown blur focus paste"
        @change="handleChange($event)"
        v-model="args.content.html"
      />
    </form>
  </main>
</template>

<script setup>
import Editor from "@tinymce/tinymce-vue";
import { useProfileStore } from "@/stores/profile.js";
import { createToaster } from "@meforma/vue-toaster";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

const profile = useProfileStore();
const { createUser, readUser } = profile;
const router = useRouter();
const route = useRoute();
const toaster = createToaster({
  position: "top-right",
  showCloseButton: true,
  duration: 5000,
});
const args = reactive({
  docType: "word",
  docNumber: "",
  docName: "untitled",
  content: { html: "" },
  // access: [
  //   {
  //     staffId: "",
  //     accessType: "",
  //   },
  // ],
});
const content = computed(() => args.content.html);
const isChanged = ref(false);

const firstTwoWords = (paragraphContent) =>
  paragraphContent
    .trim()
    .replace(/<[^>]*>/g, "")
    .split(/\s+/)
    .slice(0, 3)
    .join(" ");

// watch(content, (val) => {
//   console.log(val);
//   if (val) {
//     isChanged.value = false;
//   }
// });
function containsSpecialCharacters(str) {
  // Regular expression to match any character that is not alphanumeric or whitespace
  var regex = /[^a-zA-Z0-9\s]/;
  // Test the string against the regular expression
  return regex.test(str);
}
function handleChange(e) {
  if (isChanged.value == false) {
    isChanged.value = true;
  }
  if (
    args.content.html == "&Nbsp;" ||
    args.content.html == "&nbsp;" ||
    containsSpecialCharacters(args.content.html)
  ) {
    return;
  }
  if (args.docName.length == 0 || args.docName == "untitled") {
    args.docName = firstTwoWords(args.content.html);
  }
}

async function createDoc() {
  try {
    const res = await createUser({}, { input: args }, "CreateDocument");
    if (res) {
      isChanged.value = false;
      toaster.success(`FIle saved successfully.`);
      router.push({
        path: "/docs/edit-doc",
        query: { id: res._id, type: res.docType },
      });

      // router.push("/departments/department-list");
    }
  } catch (error) {
    console.log(error);
  }
}

async function editDoc() {
  try {
    const res = await createUser(
      {},
      { updateDocumentId: route.query.id, input: args },
      "UpdateDocument"
    );
    if (res) {
      isChanged.value = false;
      toaster.success(`FIle saved successfully.`);

      // router.push("/departments/department-list");
    }
  } catch (error) {
    console.log(error);
  }
}
// const allDocuments = computed(() => profile.getDocuments);
const singleDoc = ref({});
async function fetchDoc() {
  try {
    const res = await readUser(
      { fetchSingleDocId: route.query.id },
      "FetchSingleDoc",
      null
    );
    if (res) {
      singleDoc.value = res;
    }
  } catch (error) {
    console.log(error);
  }
}

onMounted(async () => {
  if (route.query.id) {
    await fetchDoc();
    args.docType = singleDoc.value.docType;
    args.docNumber = singleDoc.value.docNumber;
    args.docName = singleDoc.value.docName;
    args.content = singleDoc.value.content;
    args.access = singleDoc.value.access;
  }
});
</script>

<style></style>
