<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="salary" />
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Edit Employee Salary</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Select Staff </label>
                      <vue-select v-model="selectedStaff" :options="daniel" id="daniel" placeholder="Select Staff" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Net Salary </label>
                      <input class="form-control" type="text" value="₦200000" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="pay-head-roll">
                      <h5>Earnings</h5>
                    </div>
                    <div class="input-block local-forms">
                      <label>Basic Salary </label>
                      <input class="form-control" type="text" value="₦200000" />
                    </div>
                    <div class="input-block local-forms">
                      <label>Basic Allowance (50%)</label>
                      <input class="form-control" type="text" value="₦100000" />
                    </div>
                    <div class="input-block local-forms">
                      <label>Housing Allowance (30%)</label>
                      <input class="form-control" type="text" value="₦60000" />
                    </div>
                    <div class="input-block local-forms">
                      <label>Transport Allowance (20%)</label>
                      <input class="form-control" type="text" value="₦40000" />
                    </div>
                    <!-- <div class="input-block local-forms">
                      <label>PAYE</label>
                      <input class="form-control" type="text" value="₦40" />
                    </div> -->
                    <!-- <div class="input-block local-forms">
                      <label>Pension</label>
                      <input class="form-control" type="text" value="₦20" />
                    </div> -->
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="pay-head-roll">
                      <h5>Deductions</h5>
                    </div>
                    <div class="input-block local-forms">
                      <label>PAYE </label>
                      <input class="form-control" type="text" value="₦0" />
                    </div>
                    <div class="input-block local-forms">
                      <label>Pension </label>
                      <input class="form-control" type="text" value="₦0" />
                    </div>
                    <div class="input-block local-forms">
                      <label>NHF </label>
                      <input class="form-control" type="text" value="₦0" />
                    </div>
                    <div class="input-block local-forms">
                      <label>NSITF </label>
                      <input class="form-control" type="text" value="₦0" />
                    </div>
                    <div class="input-block local-forms">
                      <label>ITF </label>
                      <input class="form-control" type="text" value="₦0" />
                    </div>
                    <div class="input-block local-forms">
                      <label>WHT</label>
                      <input class="form-control" type="text" value="₦0" />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button type="submit" class="btn btn-primary submit-form me-2">Submit</button>
                      <button type="submit" class="btn btn-primary cancel-form">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Payroll",
      text: "Edit Employee Salary",
      daniel: ["Select Staff", "Michael Anorue", "Daniel Folarinde"],
      selectedStaff: "Daniel Folarinde",
    };
  },
};
</script>
