<template>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <breadcrumb :title="title" :text="text" path="/dashboard" />
      <div class="row">
        <!-- Chart -->
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Material Icon</h5>
            </div>
            <div class="card-body">
              <div class="icons-items">
                <ul class="icons-list">
                  <li><MDBIcon icon="camera-retro" /></li>
                  <li><MDBIcon icon="adjust" /></li>
                  <li><MDBIcon icon="align-center" /></li>
                  <li><MDBIcon icon="align-justify" /></li>
                  <li><MDBIcon icon="align-left" /></li>
                  <li><MDBIcon icon="align-right" /></li>
                  <li><MDBIcon icon="ambulance" /></li>
                  <li><MDBIcon icon="atom" /></li>
                  <li><MDBIcon icon="ban" /></li>
                  <li><MDBIcon icon="atlas" /></li>
                  <li><MDBIcon icon="at" /></li>
                  <li><MDBIcon icon="award" /></li>
                  <li><MDBIcon icon="archive" /></li>
                  <li><MDBIcon icon="barcode" /></li>
                  <li><MDBIcon icon="bible" /></li>
                  <li><MDBIcon icon="blind" /></li>
                  <li><MDBIcon icon="brain" /></li>
                  <li><MDBIcon icon="book" /></li>
                  <li><MDBIcon icon="book-open" /></li>
                  <li><MDBIcon icon="book-dead" /></li>
                  <li><MDBIcon icon="briefcase" /></li>
                  <li><MDBIcon icon="briefcase-medical" /></li>
                  <li><MDBIcon icon="broom" /></li>
                  <li><MDBIcon icon="brush" /></li>
                  <li><MDBIcon icon="bullhorn" /></li>
                  <li><MDBIcon icon="bus" /></li>
                  <li><MDBIcon icon="burn" /></li>
                  <li><MDBIcon icon="bath" /></li>
                  <li><MDBIcon icon="code" /></li>
                  <li><MDBIcon icon="cat" /></li>
                  <li><MDBIcon icon="certificate" /></li>
                  <li><MDBIcon icon="chart-pie" /></li>
                  <li><MDBIcon icon="chart-area" /></li>
                  <li><MDBIcon icon="chart-line" /></li>
                  <li><MDBIcon icon="chair" /></li>
                  <li><MDBIcon icon="chess" /></li>
                  <li><MDBIcon icon="chess-king" /></li>
                  <li><MDBIcon icon="chess-queen" /></li>
                  <li><MDBIcon icon="chess-pawn" /></li>
                  <li><MDBIcon icon="chess-knight" /></li>
                  <li><MDBIcon icon="chess-rook" /></li>
                  <li><MDBIcon icon="chess-board" /></li>
                  <li><MDBIcon icon="chess-bishop" /></li>
                  <li><MDBIcon icon="calculator" /></li>
                  <li><MDBIcon icon="divide" /></li>
                  <li><MDBIcon icon="equals" /></li>
                  <li><MDBIcon icon="not-equal" /></li>
                  <li><MDBIcon icon="plus" /></li>
                  <li><MDBIcon icon="times" /></li>
                  <li><MDBIcon icon="subscript" /></li>
                  <li><MDBIcon icon="superscript" /></li>
                  <li><MDBIcon icon="minus" /></li>
                  <li><MDBIcon icon="percentage" /></li>
                  <li><MDBIcon icon="infinity" /></li>
                  <li><MDBIcon icon="check" /></li>
                  <li><MDBIcon icon="check-double" /></li>
                  <li><MDBIcon icon="bug" /></li>
                  <li><MDBIcon icon="code-branch" /></li>
                  <li><MDBIcon icon="coffee" /></li>
                  <li><MDBIcon icon="filter" /></li>
                  <li><MDBIcon icon="fire-extinguisher" /></li>
                  <li><MDBIcon icon="microchip" /></li>
                  <li><MDBIcon icon="qrcode" /></li>
                  <li><MDBIcon icon="shield-alt" /></li>
                  <li><MDBIcon icon="project-diagram" /></li>
                  <li><MDBIcon icon="file" /></li>
                  <li><MDBIcon icon="file-alt" /></li>
                  <li><MDBIcon icon="file-code" /></li>
                  <li><MDBIcon icon="folder" /></li>
                  <li><MDBIcon icon="folder-open" /></li>
                  <li><MDBIcon icon="window-close" /></li>
                  <li><MDBIcon icon="window-maximize" /></li>
                  <li><MDBIcon icon="window-minimize" /></li>
                  <li><MDBIcon icon="window-restore" /></li>
                  <li><MDBIcon icon="stream" /></li>
                  <li><MDBIcon icon="user-secret" /></li>
                  <li><MDBIcon icon="terminal" /></li>
                  <li><MDBIcon icon="desktop" /></li>
                  <li><MDBIcon icon="database" /></li>
                  <li><MDBIcon icon="tablet" /></li>
                  <li><MDBIcon icon="hdd" /></li>
                  <li><MDBIcon icon="save" /></li>
                  <li><MDBIcon icon="tv" /></li>
                  <li><MDBIcon icon="arrow-circle-down" /></li>
                  <li><MDBIcon icon="arrow-circle-left" /></li>
                  <li><MDBIcon icon="arrow-circle-right" /></li>
                  <li><MDBIcon icon="arrow-circle-up" /></li>
                  <li><MDBIcon icon="arrow-down" /></li>
                  <li><MDBIcon icon="arrow-up" /></li>
                  <li><MDBIcon icon="arrow-left" /></li>
                  <li><MDBIcon icon="arrow-right" /></li>
                  <li><MDBIcon icon="arrows-alt" /></li>
                  <li><MDBIcon icon="arrows-alt-h" /></li>
                  <li><MDBIcon icon="arrows-alt-v" /></li>
                  <li><MDBIcon icon="caret-down" /></li>
                  <li><MDBIcon icon="caret-up" /></li>
                  <li><MDBIcon icon="caret-left" /></li>
                  <li><MDBIcon icon="caret-right" /></li>
                  <li><MDBIcon icon="chart-line" /></li>
                  <li><MDBIcon icon="chevron-circle-down" /></li>
                  <li><MDBIcon icon="chevron-circle-up" /></li>
                  <li><MDBIcon icon="chevron-circle-left" /></li>
                  <li><MDBIcon icon="chevron-circle-right" /></li>
                  <li><MDBIcon icon="chevron-down" /></li>
                  <li><MDBIcon icon="chevron-up" /></li>
                  <li><MDBIcon icon="chevron-left" /></li>
                  <li><MDBIcon icon="chevron-right" /></li>
                  <li><MDBIcon icon="cloud-download-alt" /></li>
                  <li><MDBIcon icon="cloud-upload-alt" /></li>
                  <li><MDBIcon icon="download" /></li>
                  <li><MDBIcon icon="history" /></li>
                  <li><MDBIcon icon="level-down-alt" /></li>
                  <li><MDBIcon icon="level-up-alt" /></li>
                  <li><MDBIcon icon="location-arrow" /></li>
                  <li><MDBIcon icon="play" /></li>
                  <li><MDBIcon icon="mouse-pointer" /></li>
                  <li><MDBIcon icon="long-arrow-alt-down" /></li>
                  <li><MDBIcon icon="long-arrow-alt-up" /></li>
                  <li><MDBIcon icon="long-arrow-alt-left" /></li>
                  <li><MDBIcon icon="long-arrow-alt-right" /></li>
                  <li><MDBIcon icon="reply" /></li>
                  <li><MDBIcon icon="reply-all" /></li>
                  <li><MDBIcon icon="share-alt" /></li>
                  <li><MDBIcon icon="share" /></li>
                  <li><MDBIcon icon="sign-in-alt" /></li>
                  <li><MDBIcon icon="cut" /></li>
                  <li><MDBIcon icon="paste" /></li>
                  <li><MDBIcon icon="font" /></li>
                  <li><MDBIcon icon="paragraph" /></li>
                  <li><MDBIcon icon="eraser" /></li>
                  <li><MDBIcon icon="indent" /></li>
                  <li><MDBIcon icon="outdent" /></li>
                  <li><MDBIcon icon="link" /></li>
                  <li><MDBIcon icon="list" /></li>
                  <li><MDBIcon icon="list-ul" /></li>
                  <li><MDBIcon icon="list-ol" /></li>
                  <li><MDBIcon icon="italic" /></li>
                  <li><MDBIcon icon="heading" /></li>
                  <li><MDBIcon icon="highlighter" /></li>
                  <li><MDBIcon icon="marker" /></li>
                  <li><MDBIcon icon="print" /></li>
                  <li><MDBIcon icon="table" /></li>
                  <li><MDBIcon icon="trash" /></li>
                  <li><MDBIcon icon="th" /></li>
                  <li><MDBIcon icon="th-large" /></li>
                  <li><MDBIcon icon="th-list" /></li>
                  <li><MDBIcon icon="underline" /></li>
                  <li><MDBIcon icon="text-height" /></li>
                  <li><MDBIcon icon="text-width" /></li>
                  <li><MDBIcon icon="tasks" /></li>
                  <li><MDBIcon icon="paperclip" /></li>

                  <li><MDBIcon icon="sort" /></li>
                  <li><MDBIcon icon="sort-up" /></li>
                  <li><MDBIcon icon="sort-down" /></li>
                  <li><MDBIcon icon="sort-alpha-down" /></li>
                  <li><MDBIcon icon="sort-alpha-up" /></li>
                  <li><MDBIcon icon="sort-numeric-up" /></li>
                  <li><MDBIcon icon="sort-numeric-down" /></li>
                  <li><MDBIcon icon="sort-amount-down" /></li>
                  <li><MDBIcon icon="sort-amount-up" /></li>
                  <li><MDBIcon icon="sync" /></li>
                  <li><MDBIcon icon="sync-alt" /></li>
                  <li><MDBIcon icon="undo" /></li>
                  <li><MDBIcon icon="upload" /></li>
                  <li><MDBIcon icon="arrow-alt-circle-down" /></li>
                  <li><MDBIcon icon="arrow-alt-circle-up" /></li>
                  <li><MDBIcon icon="arrow-alt-circle-left" /></li>
                  <li><MDBIcon icon="arrow-alt-circle-right" /></li>
                  <li><MDBIcon icon="caret-square-down" /></li>
                  <li><MDBIcon icon="caret-square-up" /></li>
                  <li><MDBIcon icon="caret-square-left" /></li>
                  <li><MDBIcon icon="caret-square-right" /></li>
                  <li><MDBIcon icon="hand-point-down" /></li>
                  <li><MDBIcon icon="hand-point-up" /></li>
                  <li><MDBIcon icon="hand-point-left" /></li>
                  <li><MDBIcon icon="hand-point-right" /></li>
                  <li><MDBIcon icon="hand-pointer" /></li>
                  <li><MDBIcon icon="baby" /></li>
                  <li><MDBIcon icon="backspace" /></li>
                  <li><MDBIcon icon="barcode" /></li>
                  <li><MDBIcon icon="battery-full" /></li>
                  <li><MDBIcon icon="battery-half" /></li>
                  <li><MDBIcon icon="battery-quarter" /></li>
                  <li><MDBIcon icon="car-battery" /></li>
                  <li><MDBIcon icon="yin-yang" /></li>
                  <li><MDBIcon icon="wrench" /></li>
                  <li><MDBIcon icon="volume-off" /></li>
                  <li><MDBIcon icon="volume-mute" /></li>
                  <li><MDBIcon icon="video" /></li>
                  <li><MDBIcon icon="undo" /></li>
                  <li><MDBIcon icon="truck" /></li>
                  <li><MDBIcon icon="subway" /></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Chart -->
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
<script>
import { MDBIcon } from "mdb-vue-ui-kit";

export default {
  components: {
    MDBIcon,
  },
  data() {
    return {
      title: "Dashboard",
      text: "Material Icon",
    };
  },
};
</script>
