//configure eslint to make my wrap lines 120

// Sample array of chat messages with "from" and "to" properties and timestamps

// Object to store messages grouped by users
const groupedConversations = {};

const user = JSON.parse(localStorage.getItem("user"));
// Function to insert message node in order based on createdAt
export const chatLogic = {
  groupChatMessages(chatMessages) {
    function insertMessageInOrder(conversationArray, message) {
      const createdAt = new Date(message.createdAt);
      let i = 0;
      while (i < conversationArray.length && new Date(conversationArray[i].createdAt) < createdAt) {
        i++;
      }
      //remove duplicates
      if (conversationArray[i] && conversationArray[i].createdAt === message.createdAt) {
        return;
      }
      conversationArray.splice(i, 0, message);
    }
    chatMessages.forEach((message) => {
      const { sender, receiver, message: userMessage, createdAt } = message;

      // If user.staffId is the sender
      if (sender === user.staffId) {
        if (!groupedConversations[receiver]) {
          groupedConversations[receiver] = [];
        }
        insertMessageInOrder(groupedConversations[receiver], { sender, receiver, message: userMessage, createdAt });
      }

      // If user.staffId is the receiver
      if (receiver === user.staffId) {
        if (!groupedConversations[sender]) {
          groupedConversations[sender] = [];
        }
        insertMessageInOrder(groupedConversations[sender], { sender, receiver, message: userMessage, createdAt });
      }
    });

    // Sort object keys based on the most recent message createdAt
    const sortedKeys = Object.keys(groupedConversations).sort((a, b) => {
      const aTimestamp = new Date(groupedConversations[a][0].createdAt);
      const bTimestamp = new Date(groupedConversations[b][0].createdAt);
      return bTimestamp - aTimestamp;
    });

    return { groupedConversations, sortedKeys };
  },
  // Group messages by users and maintain order using linked list
};
