<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="staff-list" />
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="updateStaff">
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Staff Details</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>First Name <span class="login-danger">*</span></label>
                      <input
                        required
                        class="form-control"
                        v-model="staffArgs.personalInformation.firstName"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Last Name <span class="login-danger">*</span></label>
                      <input
                        required
                        v-model="staffArgs.personalInformation.lastName"
                        class="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>User Name/Staff ID <span class="login-danger">*</span></label>
                      <input
                        required
                        v-model="staffArgs.personalInformation.userName"
                        class="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Mobile <span class="login-danger">*</span></label>
                      <input
                        required
                        class="form-control"
                        v-model="staffArgs.contactInformation.phoneNumber"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Email <span class="login-danger">*</span></label>
                      <input required class="form-control" v-model="staffArgs.contactInformation.email" type="email" />
                    </div>
                  </div>
                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Password <span class="login-danger">*</span></label>
                      <input
                      required class="form-control" type="password" />
                    </div>
                  </div> -->
                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Confirm Password <span class="login-danger">*</span></label>
                      <input
                      required class="form-control" type="password" />
                    </div>
                  </div> -->
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms cal-icon">
                      <label>Date Of Birth <span class="login-danger">*</span></label>
                      <datepicker
                        v-model="staffArgs.personalInformation.birthDate"
                        :input-format="dateFormat"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        :required="true"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block select-gender">
                      <label class="gen-label">Gender<span class="login-danger">*</span></label>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            required
                            type="radio"
                            name="gender"
                            class="form-check-input"
                            value="MALE"
                            :checked="staffArgs.personalInformation.gender === 'MALE'"
                            @change="($event) => (staffArgs.personalInformation.gender = $event.target.value)"
                          />Male
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            required
                            type="radio"
                            name="gender"
                            class="form-check-input"
                            value="FEMALE"
                            :checked="staffArgs.personalInformation.gender === 'FEMALE'"
                            @change="($event) => (staffArgs.personalInformation.gender = $event.target.value)"
                          />Female
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Degree <span class="login-danger">*</span></label>
                      <input
                        required
                        v-model="staffArgs.educationInformation[0].degree"
                        class="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Institution <span class="login-danger">*</span></label>
                      <input
                        required
                        v-model="staffArgs.educationInformation[0].university"
                        class="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms cal-icon">
                      <label>Start Year <span class="login-danger">*</span></label>
                      <datepicker
                        v-model="staffArgs.educationInformation[0].startYear"
                        :input-format="dateFormat"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        :required="true"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms cal-icon">
                      <label>End Year <span class="login-danger">*</span></label>
                      <datepicker
                        v-model="staffArgs.educationInformation[0].graduationYear"
                        :input-format="dateFormat"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        :required="true"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Result <span class="login-danger">*</span></label>
                      <input
                        required
                        v-model="staffArgs.educationInformation[0].result"
                        class="form-control"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Designation <span class="login-danger">*</span></label>
                      <input required v-model="staffArgs.position" class="form-control" type="text" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-4">
                    <div class="input-block local-forms">
                      <label>Department <span class="login-danger">*</span></label>
                      <vue-select
                        :options="allDepartments"
                        placeholder="Select Department"
                        v-model="staffArgs.department"
                      />
                    </div>
                  </div>

                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms cal-icon">
                      <label>Start Date <span class="login-danger">*</span></label>
                      <datepicker
                        v-model="staffArgs.dateOfJoining"
                        :input-format="dateFormat"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        :required="true"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label>Address <span class="login-danger">*</span></label>
                      <textarea
                        class="form-control"
                        rows="3"
                        cols="30"
                        v-model="staffArgs.contactInformation.address"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="input-block local-forms">
                      <label>City <span class="login-danger">*</span></label>
                      <vue-select
                        :options="StaffCity"
                        id="Staffcity"
                        placeholder="Select City"
                        v-model="staffArgs.contactInformation.city"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="input-block local-forms">
                      <label>Country <span class="login-danger">*</span></label>
                      <vue-select
                        :options="StaffCountry"
                        id="Staffcountry"
                        placeholder="Select Country"
                        v-model="staffArgs.contactInformation.country"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="input-block local-forms">
                      <label>State/Province <span class="login-danger">*</span></label>
                      <vue-select
                        :options="StaffState"
                        id="Staffstate"
                        placeholder="Select State"
                        v-model="staffArgs.contactInformation.state"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="input-block local-forms">
                      <label>Postal Code <span class="login-danger">*</span></label>
                      <input
                        required
                        class="form-control"
                        v-model="staffArgs.contactInformation.postalCode"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label>Start Biography <span class="login-danger">*</span></label>
                      <textarea
                        class="form-control"
                        rows="3"
                        cols="30"
                        v-model="staffArgs.personalInformation.aboutMe"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-top-form">
                      <label class="local-top">Avatar </label>
                      <div class="settings-btn upload-files-avator">
                        <input
                          type="file"
                          accept="image/*"
                          name="image"
                          id="file"
                          onchange="loadFile(event)"
                          class="hide-input"
                        />
                        <label for="file" class="upload">Choose File</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block select-gender">
                      <label class="gen-label">Status <span class="login-danger">*</span></label>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input required type="radio" name="gender" class="form-check-input" />Active
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input required type="radio" name="gender" class="form-check-input" />In Active
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button type="submit" class="btn btn-primary submit-form me-2">Submit</button>
                      <button type="submit" class="btn btn-primary cancel-form">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useProfileStore } from "@/stores/profile.js";

import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-right",
  showCloseButton: true,
  duration: 5000,
});
const currentDate = new Date();
export default {
  setup() {
    const profile = useProfileStore();
    const { createUser, readUser } = profile;

    const allDepartments = computed(() => {
      const data =
        profile.getAllDepartments &&
        profile.getAllDepartments.map((item) => {
          return { id: item._id, text: item.name };
        });

      return data;
    });
    return {
      createUser,
      readUser,
      allDepartments,
    };
  },
  data() {
    return {
      title: "Staffs",
      text: "Add Staffs",
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      StaffDepartment: ["Select Department", "Orthopedics", "Radiology", "Dentist"],
      StaffCountry: ["Select County", "Usa", "Uk", "Italy"],
      StaffCity: ["Select City", "Alaska", "Los Angeles"],
      StaffState: ["Select State", "Alaska", "California"],
      staffArgs: {
        personalInformation: {
          firstName: "",
          lastName: "",
          userName: "",
          gender: "",
          aboutMe: "",
          birthDate: currentDate,
        },
        educationInformation: [
          {
            degree: "",
            university: "",
            startYear: currentDate,
            graduationYear: currentDate,
            result: "",
          },
        ],
        contactInformation: {
          address: "",
          phoneNumber: "",
          email: "",
          city: "",
          state: "",
          country: "",
          postalCode: "",
        },
        department: "",
        position: "",
        salary: 0,
        dateOfJoining: currentDate,
        accountType: "STAFF",
      },
    };
  },

  methods: {
    async updateStaff() {
      try {
        const res = await this.createUser(
          {},
          { updateStaffId: this.$route.query.id, input: this.staffArgs },
          "UpdateStaff"
        );
        if (res) {
          toaster.success(`Staff edited successfully.`);
          this.$router.push("/staff/staff-list");
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },

    async readStaff() {
      try {
        const res = await this.readUser({ getStaffId: this.$route.query.id }, "GetStaff", "singleStaff");
        if (res) {
          const staffData = res;
          this.staffArgs.personalInformation.firstName = staffData.personalInformation?.firstName;
          this.staffArgs.personalInformation.lastName = staffData.personalInformation?.lastName;
          this.staffArgs.personalInformation.userName = staffData.personalInformation?.userName;
          this.staffArgs.personalInformation.gender = staffData.personalInformation?.gender;
          this.staffArgs.personalInformation.aboutMe = staffData.personalInformation?.aboutMe;
          this.staffArgs.personalInformation.birthDate =
            (staffData.personalInformation?.birthDate && new Date(staffData.personalInformation?.birthDate)) ||
            currentDate;
          this.staffArgs.educationInformation[0].degree = staffData.educationInformation[0]?.degree;
          this.staffArgs.educationInformation[0].university = staffData.educationInformation[0]?.university;
          this.staffArgs.educationInformation[0].startYear =
            (staffData.educationInformation[0]?.startYear && new Date(staffData.educationInformation[0]?.startYear)) ||
            currentDate;
          this.staffArgs.educationInformation[0].graduationYear =
            (staffData.educationInformation[0]?.graduationYear &&
              new Date(staffData.educationInformation[0]?.graduationYear)) ||
            currentDate;
          // console.log(
          //   this.staffArgs.educationInformation[0].graduationYear,
          //   "year"
          // );
          this.staffArgs.educationInformation[0].result = staffData.educationInformation[0]?.result;
          this.staffArgs.contactInformation.address = staffData.contactInformation?.address;
          this.staffArgs.contactInformation.phoneNumber = staffData.contactInformation?.phoneNumber;
          this.staffArgs.contactInformation.email = staffData.contactInformation?.email;
          this.staffArgs.contactInformation.city = staffData.contactInformation?.city;
          this.staffArgs.contactInformation.state = staffData.contactInformation?.state;
          this.staffArgs.contactInformation.country = staffData.contactInformation?.country;
          this.staffArgs.contactInformation.postalCode = staffData.contactInformation?.postalCode;
          this.staffArgs.department = staffData?.department;
          this.staffArgs.position = staffData?.position;
          this.staffArgs.salary = staffData?.salary;
          this.staffArgs.dateOfJoining = (staffData.dateOfJoining && new Date(staffData.dateOfJoining)) || currentDate;
          this.staffArgs.accountType = staffData.accountType;
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },

    async readDepartment() {
      try {
        const res = await this.readUser({}, "GetAllDepartment", "departments");
        if (res) {
          console.log(res);
          // toaster.success(`Staff read successfully.`);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
  },

  async created() {
    await this.readStaff();

    await this.readDepartment();
  },
};
</script>
