const HmoProviders = [
  "Reliance HMO Limited",
  "Hygeia HMO Limited",
  "Total health Trust limited",
  "clearline Health International limited",
  "Healthcare international Limited",
  "Mediplan Healthcare limited",
  "Multi shield Nigeria limited",
  "United Healthcare International limited",
  "Premium private health Trust limited",
  "Ronsberger Nigeria limited",
  "International health management services limited",
  "Expatcare Health International limited",
  "Songhai health International limited",
  "Integrated Healthcare limited",
  "Premier Medical limited",
  "Managed Healthcare services limited",
  "Princeton Health group",
  "Maayoit Healthcare limited",
  "Wise health services limited",
  "Wetlands Health Services limited",
  "Zenith Medicare Limited",
  "Defence Health Management Limited",
  "United Comprehensive Health Managers LTD",
  "Healthcare Security LTD",
  "Strategic Health Planners Co.  LTD",
  "Royal Health maintenance services",
  "Zuma Health Trust",
  "Arewa Health maintenance services",
  "Markafema Nigeria LTD",
  "Prepaid Medicare services limited",
  "Cignet Health Limited",
  "Fortecare Limited",
  "PHB Healthcare limited",
  "Sterling Health Managed care services Limited",
  "Health Partners Limited",
  "Precious Healthcare limited",
  "Kaduna GMD Healthcare limited",
  "Diamond shield Health Services limited",
  "Oceanic Health Management Limited",
  "UNIC Health Managed care services LTD",
  "Investcorp Medicare LTD",
  "Complete medicare LTD",
  "Greenbay Healthcare services LTD",
  "Medexia LTD",
  "Paramount Healthcare serv.  LTD",
  "Royal Exchange LTD",
  "Emerald Healthcare LTD",
  "Marina Medical Serv.  HMO LTD",
  "Oluma Health Trust LTD",
  "Capex Medicare LTD",
  "Nonsuch Medicare LTD",
  "Healthstone HMO LTD",
  "Healthwyse Global services LTD",
  "Salus Trust GTE LTD",
];

export default HmoProviders;
