<template>
  <div class="card-body">
    <b-form action="javascript:;">
      <div class="form-group row">
        <label class="col-lg-3 col-form-label">Address 1</label>
        <div class="col-lg-9 mb-3">
          <b-form-input type="text" class="form-control"></b-form-input>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-lg-3 col-form-label">Address 2</label>
        <div class="col-lg-9 mb-3">
          <b-form-input type="text" class="form-control"></b-form-input>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-lg-3 col-form-label">City</label>
        <div class="col-lg-9 mb-3">
          <b-form-input type="text" class="form-control"></b-form-input>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-lg-3 col-form-label">State</label>
        <div class="col-lg-9 mb-3">
          <b-form-input type="text" class="form-control"></b-form-input>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-lg-3 col-form-label">Country</label>
        <div class="col-lg-9 mb-3">
          <b-form-input type="text" class="form-control"></b-form-input>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-lg-3 col-form-label">Postal Code</label>
        <div class="col-lg-9 mb-3">
          <b-form-input type="text" class="form-control"></b-form-input>
        </div>
      </div>
      <div class="text-end">
        <b-button variant="primary">Submit</b-button>
      </div>
    </b-form>
  </div>
</template>
