<template>
  <div class="col-xl-8">
    <div class="card chat-box">
      <div class="mailview-content view-content-bottom">
        <div class="mail-view-list">
          <ul class="nav icons-list">
            <li @click="emit('back', false)"><MDBIcon icon="arrow-circle-left" /></li>
            <li>
              <a href="javascript:;"><img alt="" src="@/assets/img/icons/warning.svg" /></a>
            </li>
            <li>
              <a href="javascript:;"><img alt="" src="@/assets/img/icons/tag-icon-04.svg" /></a>
            </li>
            <li>
              <a href="javascript:;"><img alt="" src="@/assets/img/icons/inbox.svg" /></a>
            </li>
            <li>
              <a href="javascript:;"><img alt="" src="@/assets/img/icons/folder-icon-05.svg" /></a>
            </li>
          </ul>
        </div>
        <div class="mailview-header comman-space-flex">
          <div class="sender-info comman-flex">
            <div class="sender-img">
              <img alt="" src="@/assets/img/profiles/avatar-01.jpg" class="rounded-circle me-2" />
            </div>
            <div v-if="mail.from && mail.from.value && mail.from.value[0]" class="send-user send-user-name">
              <h4>
                {{ mail.from.value[0].name || mail.from.text }}
                <!-- <span class="email-market">Marketting</span> -->
              </h4>
              <p><span>From:</span> {{ mail.from.value[0].address }}</p>
            </div>
          </div>
          <div class="mail-reports">
            <ul class="nav">
              <li>
                <a href="javascript:;" class="ford-angle star-bg"><i class="fas fa-star starred"></i></a>
              </li>
              <li>
                <a href="javascript:;" class="ford-angle"
                  ><img alt="" src="@/assets/img/icons/forward-icon-01.svg"
                /></a>
              </li>
              <li>
                <a href="javascript:;" class="ford-angle"
                  ><img alt="" src="@/assets/img/icons/forward-icon-02.svg"
                /></a>
              </li>
              <li>
                <a href="javascript:;" class="ford-angle"
                  ><img alt="" src="@/assets/img/icons/forward-icon-03.svg"
                /></a>
              </li>
              <li>
                <a href="javascript:;" class="ford-angle" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="feather-more-vertical"></i>
                </a>
                <div class="dropdown-menu" style="">
                  <a class="dropdown-item" href="javascript:;"
                    ><i class="feather-user me-2 text-primary"></i> Profile</a
                  >
                  <a class="dropdown-item" href="javascript:;"
                    ><i class="feather-plus-circle me-2 text-success"></i> Archive</a
                  >
                  <a class="dropdown-item" href="javascript:;"
                    ><i class="feather-trash-2 me-2 text-danger"></i> Delete</a
                  >
                  <a class="dropdown-item" href="javascript:;"
                    ><i class="feather-slash me-2 text-secondary"></i> Block</a
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div style="width: 100%" class="mailview-inner">
          <iframe :srcdoc="decodedHtml" frameborder="0" style="width: 100%; height: 80vh" class="w-full"></iframe>
        </div>

        <div v-if="mail.attachments && mail.attachments.length" style="margin-top: 20px" class="mail-attach">
          <h2>Attachments<a href="javascript:;">Download All</a></h2>
          <ul v-for="(att, i) in mail.attachments" :key="i" class="msg-sub-list nav">
            <li>
              <img src="@/assets/img/icons/document-icon.svg" alt="" class="me-1" />{{ att.filename }}
              <span class="ms-1">{{ bytesToHumanReadable(att.size) }}</span
              ><img
                @click="downloadAttachment(att.content.data, att.contentType, att.filename)"
                src="@/assets/img/icons/chat-icon-07.svg"
                alt=""
                class="ms-2"
              /><i class="feather-x ms-2"></i>
            </li>
            <!-- <li>
              <img src="@/assets/img/icons/gallery-icon.svg" alt="" class="me-1" />Expense.img
              <span class="ms-1">4.0 MB</span><img src="@/assets/img/icons/chat-icon-07.svg" alt="" class="ms-2" /><i
                class="feather-x ms-2"
              ></i>
            </li> -->
          </ul>
        </div>
        <div style="margin-top: 20px" class="forward-send">
          <a href="javascript:void(0);" class="btn btn-primary replay-btn me-2"
            ><img src="@/assets/img/icons/replay.svg" class="me-2" alt="img" />Reply</a
          >
          <a href="javascript:void(0);" class="btn btn-primary forwrd-btn"
            ><img src="@/assets/img/icons/replay-01.svg" class="me-2" alt="img" />Forward</a
          >
          <a href="javascript:void(0);"><img src="@/assets/img/icons/printer.svg" class="ms-2 me-2" alt="img" /></a>
          <a href="javascript:void(0);"><img src="@/assets/img/icons/trash.svg" class="me-2" alt="img" /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useProfileStore } from "@/stores/profile.js";
import { useRoute } from "vue-router";
import { MDBIcon } from "mdb-vue-ui-kit";

const route = useRoute();

const profile = useProfileStore();
const { createUser, readUser, markReadInStore } = profile;

const emit = defineEmits(["back"]);

const mail = computed(
  () => (profile.getMailData && profile.getMailData.find((item) => item.messageId === route.query.messageId)) || {}
);

const decodedHtml = ref("");

const htmlContent = computed(() => (mail.value && mail.value.html) || (mail.value && mail.value.textAsHtml));

watch(
  htmlContent,
  (val) => {
    decodedHtml.value = decodeQuotedPrintable(val);
  },
  { deep: true }
);
function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded Unicode,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode("0x" + p1);
    })
  );
}
function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}
// Decode the quoted-printable HTML content
function decodeQuotedPrintable(input) {
  return b64DecodeUnicode(b64EncodeUnicode(input));
  // return input.replace(/=[0-9A-F]{2}/g, (match) =>
  //   String.fromCharCode(parseInt(match.substr(1), 16))
  // );
}

function bytesToHumanReadable(bytes) {
  if (bytes < 1024 * 1024) {
    // Convert to kilobytes if less than 1MB
    return (bytes / 1024).toFixed(2) + " KB";
  } else {
    // Convert to megabytes if 1MB or more
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  }
}
function downloadAttachment(data, contentType, filename) {
  // Assume attachmentData is the JSON object you provided

  // Convert binary data to a Blob
  const blob = new Blob([Uint8Array.from(data)], { type: contentType });

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);

  // Append the link to the document body
  document.body.appendChild(link);

  // Programmatically click the link to start the download
  link.click();

  // Remove the link from the document body
  document.body.removeChild(link);
}
onMounted(() => {
  // Ensure htmlContent is not empty before decoding
  if (htmlContent.value) {
    // Decode the quoted-printable HTML content
    decodedHtml.value = decodeQuotedPrintable(htmlContent.value);
  }
  markReadInStore(route.query.messageId);
  profile.$patch((state) => {
    for (let mail of state.mailData) {
      if (mail.messageId === route.query.messageId) {
        mail.read = true;
        break;
      }
    }
  });
});
// async function fetchMails() {
//   try {
//     await readUser({}, "FetchMail", "mailData");
//   } catch (error) {
//     toaster.error(`Error: ${error}`);
//   }
// }

// onMounted(async () => {
//   if (route.query.oauth_token) {
//     window.localStorage.setItem("oauth_token", route.query.oauth_token);
//     // await fetchMails();
//   }
// });
</script>
