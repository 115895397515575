<template>
  <div class="col-md-8">
    <div class="blog-view">
      <article class="blog blog-single-post">
        <h3 class="blog-title">Eye Care Routine To Get Rid Of Under Eye Circles And Puffiness</h3>
        <div class="blog-info clearfix">
          <div class="post-right read-blks">
            <a href="#.">Read more in 8 Minutes</a>
          </div>
          <div class="post-left date-blks">
            <ul>
              <li>
                <a href="#."><i class="feather-calendar"></i> <span>05 Jul 2022</span></a>
              </li>
              <li>
                <a href="#."><i class="feather-message-square"></i> <span>58</span></a>
              </li>
              <li>
                <a href="#."><i class="feather-eye"></i> <span>2.8k</span></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="blog-image">
          <a href="#."><img alt="" src="@/assets/img/blog/blog-detail.jpg" class="img-fluid" /></a>
        </div>
        <div class="blog-content">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum.
          </p>
          <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
            aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
            dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
            sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore
            magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
            suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in
            ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
            nulla pariatur?
          </p>
        </div>
        <div class="blog-share">
          <div class="share-blogs d-flex align-items-center">
            <a href="javascript:;"><img alt="" src="@/assets/img/icons/social-01.svg" /></a>
            <span class="ms-2">2.8k</span>
          </div>
          <ul class="social-share nav">
            <li>
              <a href="javascript:;"><img alt="" src="@/assets/img/icons/social-01.svg" /></a>
            </li>
            <li>
              <a href="javascript:;"><img alt="" src="@/assets/img/icons/social-02.svg" /></a>
            </li>
            <li>
              <a href="javascript:;"><img alt="" src="@/assets/img/icons/social-03.svg" /></a>
            </li>
            <li>
              <a href="javascript:;"><img alt="" src="@/assets/img/icons/social-04.svg" /></a>
            </li>
          </ul>
        </div>
        <div class="list-add-blogs">
          <ul class="nav">
            <li># Ophthalmology</li>
            <li># Beauty</li>
            <li># Prevention</li>
          </ul>
        </div>
      </article>
      <div class="widget author-widget">
        <div class="authr-blog-group text-center">
          <div class="authr-blg-img mb-2">
            <img class="avatar" alt="" src="@/assets/img/profiles/avatar-03.jpg" />
          </div>
          <h2>Markhay smith</h2>
          <span>Dentist</span>
          <p>
            Integer enim neque volutpat ac tincidunt vitae semper quis. Orci sagittis eu volutpat odio facilisis mauris
            sit. Sed risus ultricies tristique nulla aliquet enim tortor at auctor.
          </p>
          <ul class="nav social-blk">
            <li>
              <a href="javascript:;"><img alt="" src="@/assets/img/icons/social-01.svg" /></a>
            </li>
            <li>
              <a href="javascript:;"><img alt="" src="@/assets/img/icons/social-02.svg" /></a>
            </li>
            <li>
              <a href="javascript:;"><img alt="" src="@/assets/img/icons/social-03.svg" /></a>
            </li>
            <li>
              <a href="javascript:;"><img alt="" src="@/assets/img/icons/social-04.svg" /></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="widget blog-comments clearfix">
        <h3>Comments</h3>
        <ul class="comments-list">
          <li v-for="record in Comments" :key="record.id">
            <div class="comment">
              <div class="comment-author">
                <router-link to="/profile"
                  ><img class="avatar" alt="" :src="require(`@/assets/img/profiles/${record.Image}`)"
                /></router-link>
              </div>
              <div class="comment-block">
                <div class="comment-by">
                  <div class="week-group">
                    <h5 class="blog-author-name">Diana Bailey</h5>
                    <span class="week-list">2 Weeks ago</span>
                  </div>
                  <span class="float-end">
                    <span class="blog-reply"
                      ><a href="#."><i class="fa fa-reply"></i> Reply</a></span
                    >
                  </span>
                </div>
                <p>
                  Integer enim neque volutpat ac tincidunt vitae semper quis. Orci sagittis eu volutpat odio facilisis
                  mauris sit. Sed risus ultricies tristique nulla aliquet enim tortor at auctor.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Comments from "@/assets/json/comments.json";
export default {
  data() {
    return {
      Comments: Comments,
    };
  },
};
</script>
