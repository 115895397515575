<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="task" />
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Task Details</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Task Title <span class="login-danger">*</span></label>
                      <input class="form-control" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Assign To <span class="login-danger">*</span></label>
                      <!-- <input class="form-control" type="text" placeholder="" /> -->
                      <vue-select :options="allStaff" placeholder="Select Staff" v-model="args.head" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Task Category <span class="login-danger">*</span></label>
                      <vue-select :options="Taskcategory" id="taskcategory" placeholder="Choose Task Category" />
                    </div>
                  </div>
                  <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Task Sub Category <span class="login-danger">*</span></label>
                      <vue-select
                        :options="Tasksubcategory"
                        id="tasksubcategory"
                        placeholder="Choose Sub Task Category"
                      />
                    </div>
                  </div> -->
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Tags <small>(separated with a comma)</small> <span class="login-danger">*</span></label>
                      <input type="text" data-role="tagsinput" class="form-control" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block select-gender">
                      <label class="gen-label">Task Status <span class="login-danger">*</span></label>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" name="gender" class="form-check-input" />Active
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input type="radio" name="gender" class="form-check-input" />In Active
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-12">
                    <div class="input-block summer-mail">
                      <editor api-key="qbuwhmfmq7n3lbo04fxnroyl623by920cmrotjyhe1v6vvwy" v-model="content" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-12">
                    <div class="input-block local-top-form">
                      <label class="local-top">Avatar <span class="login-danger">*</span></label>
                      <div class="settings-btn upload-files-avator">
                        <input
                          type="file"
                          accept="image/*"
                          name="image"
                          id="file"
                          onchange="loadFile(event)"
                          class="hide-input"
                        />
                        <label for="file" class="upload">Choose File</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button type="submit" class="btn btn-primary submit-form me-2">Create Task</button>
                      <button type="submit" class="btn btn-primary cancel-form">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";

import Editor from "@tinymce/tinymce-vue";
import { useProfileStore } from "@/stores/profile.js";

export default {
  components: {
    editor: Editor,
  },
  setup() {
    const profile = useProfileStore();
    const { createUser, readUser } = profile;

    const allStaff = computed(() => {
      const data =
        profile.getAllStaff &&
        profile.getAllStaff.map((item) => {
          return {
            id: item._id,
            text: `${item.personalInformation.firstName} ${item.personalInformation.lastName}`,
          };
        });
      return data;
    });

    return {
      allStaff,
      createUser,
      readUser,
    };
  },
  data() {
    return {
      title: "Text Editor",
      content: "Description",
      title: "Task",
      text: "Edit Tasks",
      args: { head: "" },
      Tasksubcategory: ["Choose Sub Task Category", "Health Care", "Corona Virus"],
      Taskcategory: ["Choose Task Category", "Technical", "Administrative", "Other"],
    };
  },
  methods: {
    async readStaff() {
      try {
        const res = await this.readUser({}, "GetAllStaff", "staffs");
        if (res) {
          console.log(res);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
  },
  async created() {
    // await this.readDept();
    await this.readStaff();
  },
};
</script>
