<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="voice-call" />
      <Share v-if="showShare" @close="showShare = false" :docId="editDocId" />

      <!-- /Page Header -->
      <div class="d-flex flex-row justify-content-end gap-2 align-items-center" style="margin-left: 20px">
        <div style="margin-bottom: -18px" class="top-liv-search top-chat-search">
          <form>
            <div class="chat-search">
              <div class="input-block me-2 mb-0">
                <input
                  type="text"
                  @keyup="searchFunction"
                  v-model="searchStr"
                  class="form-control"
                  placeholder="Search here"
                />
                <a class="btn"><img src="@/assets/img/icons/search-normal.svg" alt="" /></a>
              </div>
              <div @click="$router.push({ path: '/docs/edit-doc' })" class="add-search">
                <a href="javascript:;"><i class="feather-plus"></i></a>
              </div>
            </div>
          </form>
        </div>
        <button style="background: none; border: none" @click="list = true" class="icons-items">
          <ul class="list-unstyled" :class="list ? 'icons-list' : undefined">
            <li>
              <i class="fa fa-tasks"></i>
            </li>
          </ul>
        </button>

        <button style="background: none; border: none" @click="list = false" class="icons-items">
          <ul class="list-unstyled" :class="!list ? 'icons-list' : undefined">
            <li>
              <i class="fa fa-th"></i>
            </li>
          </ul>
        </button>
      </div>
      <div v-show="list" class="row">
        <!-- <call-sidebar></call-sidebar> -->
        <div class="col-xl-12">
          <div class="card chat-box">
            <div
              v-for="doc in visibleData"
              :key="doc._id"
              style="border-bottom: 0.5px solid #888; padding: 10px 0"
              class="chat-search-group1"
            >
              <div class="chat-user-group mb-0 d-flex align-items-center">
                <div class="img-users call-user">
                  <router-link :to="`/docs/edit-doc/?id=${doc._id}`">
                    <img style="width: 30px" :src="docIcon[doc.docType]" alt=""
                  /></router-link>
                </div>
                <div class="chat-users">
                  <div class="user-text">
                    <p>Name</p>
                  </div>
                  <div class="user-titles">
                    <h5>{{ doc.docName }}</h5>
                  </div>
                </div>
              </div>
              <div class="chat-user-group mb-0 d-flex align-items-center">
                <!-- <div class="img-users call-user">
                  <router-link to="/profile">
                    <img src="@/assets/img/icons/pdf-icon-02.svg" alt=""
                  /></router-link>
                </div> -->
                <div class="chat-users">
                  <div class="user-text">
                    <p>Modified</p>
                  </div>
                  <div class="user-titles">
                    <h4>
                      {{ convertDate(doc.updatedAt).formattedDate }}
                      {{ convertDate(doc.updatedAt).formattedTime }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="chat-user-group mb-0 d-flex align-items-center">
                <div v-if="doc.access && doc.access.length">
                  <i style="margin: 20px 10px 0 0" class="fa fa-link align-self-end"></i>
                </div>
                <div class="chat-users">
                  <div class="user-text">
                    <p>Members</p>
                  </div>
                  <div class="user-titles">
                    <h4>{{ doc.access.length || "Only you" }}</h4>
                  </div>
                </div>
              </div>

              <div class="chat-user-group mb-0 d-flex gap-4 align-items-center">
                <a-popover trigger="click" placement="bottom">
                  <template #content>
                    <div class="d-flex flex-column">
                      <span
                        @click="
                          $router.push({
                            path: `/docs/edit-doc`,
                            query: { id: doc._id, type: doc.docType },
                          })
                        "
                        class="hover-effect p-1"
                        >Edit</span
                      >
                      <span
                        @click="
                          () => {
                            editDocId = doc._id;
                            showShare = true;
                          }
                        "
                        class="hover-effect p-1"
                        >Share</span
                      >
                      <span class="hover-effect p-1">Archive</span>
                    </div>
                  </template>
                  <button style="background: none; border: none">
                    <i class="fa fa-ellipsis-h"></i>
                  </button>
                </a-popover>
              </div>
              <!-- <div class="chat-search-list">
                <ul>
                  <li>
                    <router-link to="video-call"
                      ><img src="@/assets/img/icons/chat-icon-01.svg" alt="img"
                    /></router-link>
                  </li>
                  <li>
                    <router-link to="voice-call"
                      ><img src="@/assets/img/icons/chat-icon-02.svg" alt="img"
                    /></router-link>
                  </li>
                  <li>
                    <a href="javascript:;"
                      ><img src="@/assets/img/icons/chat-icon-03.svg" alt="img"
                    /></a>
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div v-show="!list" class="card-box">
        <div class="card">
          <div style="padding: 30px 0" class="row chat-box">
            <div v-for="doc in visibleData" :key="doc._id" class="col-sm-3 col-md-3 col-xl-2 chat-search-group">
              <a-popover trigger="hover" placement="top">
                <template #content>
                  <div class="d-flex flex-column">
                    <span
                      @click="
                        $router.push({
                          path: `/docs/edit-doc`,
                          query: { id: doc._id, type: doc.docType },
                        })
                      "
                      class="hover-effect p-1"
                      >Edit</span
                    >
                    <span
                      @click="
                        () => {
                          editDocId = doc._id;
                          showShare = true;
                        }
                      "
                      class="hover-effect p-1"
                      >Share</span
                    >
                    <span class="hover-effect p-1">Archive</span>
                  </div>
                </template>
                <div class="chat-user-group mb-0 d-flex flex-column justify-content-center align-items-center">
                  <div class="img-users call-user">
                    <router-link :to="`/docs/edit-doc/?id=${doc._id}`">
                      <img :src="docIcon[doc.docType]" alt=""
                    /></router-link>
                  </div>
                  <div class="chat-users">
                    <div style="margin-top: 20px" class="user-titles">
                      <h5 style="text-align: center">{{ doc.docName }}</h5>
                    </div>

                    <div class="user-text">
                      <p style="text-align: center; font-size: 10px">
                        {{ convertDate(doc.updatedAt).formattedDate }}
                        {{ convertDate(doc.updatedAt).formattedTime }}
                      </p>
                    </div>
                  </div>
                </div>
              </a-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";

import { useProfileStore } from "@/stores/profile.js";
import Share from "@/components/docs/share-modal.vue";
import pdf from "@/assets/img/icons/pdf-icon-01.svg";
import txt from "@/assets/img/icons/pdf-icon-02.svg";
import csv from "@/assets/img/icons/pdf-icon-03.svg";
import xlsx from "@/assets/img/icons/pdf-icon-04.svg";
import { helperFunctions } from "@/composables/helperFunctions";

const profile = useProfileStore();
const { readUser } = profile;
const { searchArrayOfObjects } = helperFunctions;

const docIcon = {
  word: txt,
  xlsx,
  excel: xlsx,
};
const title = ref("App");
const text = ref("Docs");
const list = ref(true);
const showShare = ref(false);
const editDocId = ref("");
const allDocuments = computed(() => profile.getDocuments);
const visibleData = ref([]);
const searchStr = ref("");
async function fetchDocs() {
  try {
    const res = await readUser({}, "FetchDocs", "documents");
    if (res) {
      visibleData.value = res;
      //   console.log(res, "res");
    }
  } catch (error) {
    console.log(error);
  }
}
function getFormattedDate() {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
function convertDate(str) {
  const dateStr = str;
  const date = new Date(dateStr);
  //   const bool = isToday(date);
  //   const yearBool = isSameYear(date);
  const formattedFullDate = date.toLocaleDateString("en-US", {
    // weekday: "long",
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  //   const formattedDate = date.toLocaleDateString("en-US", {
  //     // weekday: "long",
  //     month: "short",
  //     day: "numeric",
  //     // year: "numeric",
  //   });
  const formattedDate = getFormattedDate(date);
  const formattedTime = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return { formattedDate, formattedTime, formattedFullDate };
  // return { date: formattedDate, time: formattedTime, bool, yearBool };
}

function searchFunction() {
  if (!searchStr.value) {
    visibleData.value = allDocuments.value;
    return;
  }
  visibleData.value = searchArrayOfObjects(allDocuments.value, searchStr.value, "docName");
}
onMounted(async () => {
  await fetchDocs();
});
</script>

<style scoped>
.hover-effect {
  transition: background-color 0.3s, color 0.3s;
  font-size: 12px;
  cursor: pointer;
}

.hover-effect:hover {
  background-color: #009efb;
  color: white;
}
</style>
