<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="staff-list" />
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="createLeave">
                <div class="row">
                  <div class="col-12">
                    <div class="form-heading">
                      <h4>Add Leave Request</h4>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Leave Type <span class="login-danger">*</span></label>
                      <vue-select :options="Loss" id="loss" placeholder="Select Leave Type" v-model="args.leaveType" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Number of Days <span class="login-danger">*</span></label>
                      <input :value="numberOfDays" readonly class="form-control" type="text" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms cal-icon">
                      <label>From <span class="login-danger">*</span></label>
                      <datepicker
                        v-model="args.startDate"
                        :input-format="dateFormat"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms cal-icon">
                      <label>To <span class="login-danger">*</span></label>
                      <datepicker
                        v-model="args.endDate"
                        :input-format="dateFormat"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-12 col-sm-12">
                    <div class="input-block local-forms">
                      <label>Leave Reason <span class="login-danger">*</span></label>
                      <textarea class="form-control" v-model="args.reason" rows="3" cols="30"></textarea>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="input-block local-forms">
                      <input class="form-control" type="text" readonly value="Remainig Leaves: 12" />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="doctor-submit text-end">
                      <button type="submit" class="btn btn-primary submit-form me-2">Submit</button>
                      <button type="submit" class="btn btn-primary cancel-form">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { router } from "@/router";
import { useProfileStore } from "@/stores/profile.js";

import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-right",
  showCloseButton: true,
  duration: 5000,
});
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
  setup() {
    const profile = useProfileStore();
    const { createUser } = profile;
    const user = JSON.parse(window.localStorage.getItem("user"));
    return {
      createUser,
      user,
    };
  },
  data() {
    return {
      title: "Staffs",
      text: "Add Leave Request",
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      Loss: ["Select Leave Type", "Medical Leave", "Casual Leave", "Loss of Pay"],
      args: {
        leaveType: "",
        staff: this.user.staffId,
        startDate: currentDate,
        endDate: currentDateOne,
        reason: "",
        status: "PENDING",
      },
      daysNumber: 0,
    };
  },
  computed: {
    numberOfDays() {
      if (!this.args.startDate || !this.args.endDate) {
        return;
      }
      let count = 0;
      let currentDate = new Date(this.args.startDate);
      let endDate = new Date(this.args.endDate);

      while (currentDate <= endDate) {
        const dayOfWeek = currentDate.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          // Exclude Sunday (0) and Saturday (6)
          count++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return count;
    },
  },
  methods: {
    async createLeave() {
      try {
        const res = await this.createUser({}, { input: this.args }, "CreateLeave");
        if (res) {
          toaster.success(`Leave created successfully.`);

          router.push("/staff/staff-leave");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
