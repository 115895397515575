<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="compose" />

      <!-- /Page Header -->
      <div v-if="mailData && mailData.length" class="row">
        <email-sidebar :unread="unreadMessages"></email-sidebar>

        <email-list
          v-show="!readSingleMessage"
          @read="readSingleMessage = $event"
          @fetch="fetchMails"
          :emailData="mailData"
        ></email-list>
        <email-view @back="readSingleMessage = $event" v-show="readSingleMessage"></email-view>
      </div>

      <div v-else class="auth-box" @click="getAuthLink">
        <div class="social-login">
          <a href="javascript:;"><img src="@/assets/img/icons/login-icon-01.svg" alt="" /></a>
          <!-- <a href="javascript:;"><img src="@/assets/img/icons/login-icon-02.svg" alt="" /></a>
          <a href="javascript:;"><img src="@/assets/img/icons/login-icon-03.svg" alt="" /></a> -->
        </div>
        <div class="social-login">
          <span>Connect your google account</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { useProfileStore } from "@/stores/profile.js";
import { useRoute } from "vue-router";

import EmailList from "./email-list.vue";
import EmailView from "./email-view.vue";

const route = useRoute();
const title = ref("App");
const text = ref("Inbox");
const profile = useProfileStore();
const { createUser, readUser } = profile;

const readSingleMessage = ref(false);

async function getAuthLink() {
  try {
    const res = await readUser({}, "GetAuthLink", null);
    if (res) {
      window.location.href = res.url;
    }
  } catch (error) {
    toaster.error(`Error: ${error}`);
  }
}

function sortByDatesDescending(datesArray) {
  // Ensure input is an array
  if (!Array.isArray(datesArray)) {
    return;
  }

  // Sort the array in descending order
  datesArray.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });

  return datesArray;
}
const mailData = computed(() => (profile.getMailData && sortByDatesDescending(profile.getMailData)) || []);

const unreadMessages = computed(() => {
  const data = mailData.value.filter((item) => item.read === false);
  return data.length;
});

async function fetchMails() {
  try {
    const res = await readUser({}, "FetchMail", "mailData");
    console.log(res);
  } catch (error) {
    toaster.error(`Error: ${error}`);
  }
}

onMounted(async () => {
  if (route.query.oauth_token) {
    window.localStorage.setItem("oauth_token", route.query.oauth_token);
    await fetchMails();
    // await getAuthLink(); fetch mail would be here
  }
});
</script>

<style scoped>
.auth-box {
  background: white;
  padding: 24px;
  margin: 20% auto;
  border-radius: 23px;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
</style>
