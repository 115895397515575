<template>
  <div class="invoice-item">
    <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-6">
        <div class="invoice-info">
          <strong class="customer-text"
            >Invoice From
            <router-link class="small" to="/accounts/edit-invoice"
              >Edit Address</router-link
            ></strong
          >
          <p class="invoice-details invoice-details-two">
            Darren Elder <br />
            806 Twin Willow Lane, Old Forge,<br />
            Newyork, USA <br />
          </p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-6">
        <div class="invoice-info">
          <strong class="customer-text">Invoice To</strong>
          <p class="invoice-details invoice-details-two">
            Walter Roberson <br />
            299 Star Trek Drive, Panama City, <br />
            Florida, 32405, USA <br />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
