import { MUTATE, QUERY } from "../api/index.js";

//write a crud class that uses the MUTATE and QUERY functions to perform CRUD operations
export class CRUD {
  constructor(service = "GENERAL") {
    this.service = service;
  }

  async create(endpoint, data) {
    try {
      return await MUTATE(endpoint, data, this.service);
    } catch (e) {
      console.log(e);
    }
  }

  async read(endpoint, data) {
    try {
      return await QUERY(endpoint, data, this.service);
    } catch (e) {
      console.log(e);
    }
  }

  async update(endpoint, data) {
    try {
      return await MUTATE(endpoint, data, this.service);
    } catch (e) {
      console.log(e);
    }
  }

  async delete(endpoint, data) {
    try {
      return await MUTATE(endpoint, data, this.service);
    } catch (e) {
      console.log(e);
    }
  }
}
