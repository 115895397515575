<template>
  <div class="notification-box">
  <div class="msg-sidebar notifications msg-noti">
    <div class="topnav-dropdown-header">
      <span>Messages</span>
    </div>
    <div class="drop-scroll msg-list-scroll" id="msg_list">
      <perfect-scrollbar
        class="scroll-area"
        :settings="settings"
        @ps-scroll-y="scrollHanle"
      >
      <ul class="list-box">
        <li>
          <a href="chat.html">
            <div class="list-item">
              <div class="list-left">
                <span class="avatar">R</span>
              </div>
              <div class="list-body">
                <span class="message-author">Richard Miles </span>
                <span class="message-time">12:28 AM</span>
                <div class="clearfix"></div>
                <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="chat.html">
            <div class="list-item new-message">
              <div class="list-left">
                <span class="avatar">J</span>
              </div>
              <div class="list-body">
                <span class="message-author">John Doe</span>
                <span class="message-time">1 Aug</span>
                <div class="clearfix"></div>
                <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="chat.html">
            <div class="list-item">
              <div class="list-left">
                <span class="avatar">T</span>
              </div>
              <div class="list-body">
                <span class="message-author"> Tarah Shropshire </span>
                <span class="message-time">12:28 AM</span>
                <div class="clearfix"></div>
                <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="chat.html">
            <div class="list-item">
              <div class="list-left">
                <span class="avatar">M</span>
              </div>
              <div class="list-body">
                <span class="message-author">Mike Litorus</span>
                <span class="message-time">12:28 AM</span>
                <div class="clearfix"></div>
                <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="chat.html">
            <div class="list-item">
              <div class="list-left">
                <span class="avatar">C</span>
              </div>
              <div class="list-body">
                <span class="message-author"> Catherine Manseau </span>
                <span class="message-time">12:28 AM</span>
                <div class="clearfix"></div>
                <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="chat.html">
            <div class="list-item">
              <div class="list-left">
                <span class="avatar">D</span>
              </div>
              <div class="list-body">
                <span class="message-author"> Domenic Houston </span>
                <span class="message-time">12:28 AM</span>
                <div class="clearfix"></div>
                <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="chat.html">
            <div class="list-item">
              <div class="list-left">
                <span class="avatar">B</span>
              </div>
              <div class="list-body">
                <span class="message-author"> Buster Wigton </span>
                <span class="message-time">12:28 AM</span>
                <div class="clearfix"></div>
                <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="chat.html">
            <div class="list-item">
              <div class="list-left">
                <span class="avatar">R</span>
              </div>
              <div class="list-body">
                <span class="message-author"> Rolland Webber </span>
                <span class="message-time">12:28 AM</span>
                <div class="clearfix"></div>
                <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="chat.html">
            <div class="list-item">
              <div class="list-left">
                <span class="avatar">C</span>
              </div>
              <div class="list-body">
                <span class="message-author"> Claire Mapes </span>
                <span class="message-time">12:28 AM</span>
                <div class="clearfix"></div>
                <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="chat.html">
            <div class="list-item">
              <div class="list-left">
                <span class="avatar">M</span>
              </div>
              <div class="list-body">
                <span class="message-author">Melita Faucher</span>
                <span class="message-time">12:28 AM</span>
                <div class="clearfix"></div>
                <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="chat.html">
            <div class="list-item">
              <div class="list-left">
                <span class="avatar">J</span>
              </div>
              <div class="list-body">
                <span class="message-author">Jeffery Lalor</span>
                <span class="message-time">12:28 AM</span>
                <div class="clearfix"></div>
                <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="chat.html">
            <div class="list-item">
              <div class="list-left">
                <span class="avatar">L</span>
              </div>
              <div class="list-body">
                <span class="message-author">Loren Gatlin</span>
                <span class="message-time">12:28 AM</span>
                <div class="clearfix"></div>
                <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
              </div>
            </div>
          </a>
        </li>
        <li>
          <a href="chat.html">
            <div class="list-item">
              <div class="list-left">
                <span class="avatar">T</span>
              </div>
              <div class="list-body">
                <span class="message-author">Tarah Shropshire</span>
                <span class="message-time">12:28 AM</span>
                <div class="clearfix"></div>
                <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
      </perfect-scrollbar>
    </div>
    <div class="topnav-dropdown-footer">
      <a href="chat.html">See all messages</a>
    </div>
  </div>
  </div>
</template>
<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
    };
  },
  methods: {
    scrollHanle(evt) { },
  },
};
</script>
