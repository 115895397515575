<template>
  <div class="page-wrapper" style="width: 95%">
    <div class="content">
      <breadcrumb :title="title" :text="text" path="/docs/list" />
      <Share v-if="showShare" @close="showShare = false" :docId="editDocId" />

      <div class="row">
        <div class="card">
          <div class="card-body">
            <ul class="nav nav-tabs nav-tabs-bottom">
              <li class="nav-item">
                <a
                  @click="word = true"
                  class="nav-link active"
                  id="tab1"
                  href="#bottom-tab1"
                  data-bs-toggle="tab"
                  >Word doc</a
                >
              </li>
              <li class="nav-item">
                <a
                  @click="word = false"
                  class="nav-link"
                  id="tab2"
                  href="#bottom-tab2"
                  data-bs-toggle="tab"
                  >Spreadsheet</a
                >
              </li>
              <!-- <li class="nav-item">
              <a class="nav-link" href="#bottom-tab3" data-bs-toggle="tab">Messages</a>
            </li> -->
            </ul>

            <div class="tab-content">
              <div class="tab-pane show active" id="bottom-tab1">
                <Word @share="showShare = true" />
              </div>
              <div class="tab-pane" id="bottom-tab2"><Excel /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { helperFunctions } from "@/composables/helperFunctions";
import Share from "@/components/docs/share-modal.vue";

import Task from "@/assets/json/task.json";
import Excel from "@/components/docs/Excel.vue";
import Word from "@/components/docs/Word.vue";
export default {
  setup() {
    const { truncateString } = helperFunctions;

    return { truncateString };
  },
  components: { Excel, Word, Share },
  data() {
    return {
      Blog: Task,
      title: "Docs",
      text: "Add",
      showShare: false,
      word: true,
      editDocId: "",
    };
  },
  methods: {
    /* View in fullscreen */
  },
  mounted() {
    if (this.$route.query) {
      if (this.$route.query.type == "word") {
        const el = document.getElementById("tab1");
        console.log(el);
        if (el) {
          el.click();
        }
      } else {
        const el2 = document.getElementById("tab2");
        if (el2) {
          el2.click();
        }
      }
    }
  },
  created() {
    this.editDocId = this.$route.query.id;
  },
};
</script>
