<template>
  <!-- <input v-if="format == false" v-model="maskedValue" :placeholder="getPlaceholder" @input="handleInput" /> -->
  <input step="any" :max="max" :value="formatAmount" :placeholder="placeholder" @input="updateField($event)" />
</template>

<script>
export default {
  setup() {
    function containsMaliciousInput(input) {
      // Regular expressions to match potentially harmful characters or patterns
      var patterns = [
        /<script/i,
        /<\/script/i,
        /[<>]/,
        /on\w+\s*=/i,
        /<[^>]*>/,
        /<\/[^>]*>/,
        /<\/[^>]+>/, // Matches any closing tag (e.g., </div>, </img>)
      ];

      // Check if any of the patterns match the input
      for (var i = 0; i < patterns.length; i++) {
        if (patterns[i].test(input)) {
          return true; // Found a match, input is potentially malicious
        }
      }

      return false; // No potentially harmful patterns found
    }
    return { containsMaliciousInput };
  },
  emits: ["input"],

  name: "MaskedInput",
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    mask: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: [String, Number],
      required: false,
    },
    max: {
      type: [String, Number],
      required: false,
    },
    format: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maskedValue: this.value,
    };
  },
  computed: {
    getPlaceholder() {
      return this.mask && this.mask.replace(/9/g, "_");
    },
    showValue() {
      return this.value;
    },
    formatAmount() {
      if (this.containsMaliciousInput(this.modelValue)) {
        return "";
      }
      if (this.format) {
        let val = this.modelValue;
        val = this.convertFloat(val);
        val = val.toLocaleString();

        if (val === "NaN") {
          return "";
        } else {
          return `₦${val}`;
        }
      } else {
        return this.modelValue;
      }
    },
  },
  methods: {
    convertFloat(num) {
      if (num && (typeof num === "number" || typeof num === "string")) {
        return parseFloat(num);
      }
      return 0;
    },
    updateField(e) {
      let amount = String(e.target.value);
      // console.log(this.holder.concat("", e.target.value));

      if (this.format) {
        amount = this.removeAlphabet(amount);
        e.target.value = amount;

        const firstChar = amount && amount.length ? amount.charAt(0) : "0";
        if (firstChar === "₦") {
          amount = amount.substring(1);
        }

        if (amount !== null) {
          amount = amount && amount.split(",").join("");
          amount = this.convertFloat(amount);
        }
      }

      this.$emit("update:modelValue", amount);
    },

    removeAlphabet(arg) {
      if (arg) {
        arg = arg.toString();
        // const regex = /[a-zA-Z]/g;
        let regex = /[^0-9.]/g; // Include decimal point in the regex
        arg = arg.replace(regex, "");
        this.$emit("update:modelValue", this.convertFloat(arg));
        return arg;
      }
    },
    handleInput(event) {
      const input = event.target;
      const unmaskedValue = input.value.replace(/[^0-9]/g, "");

      let maskedValue = "";
      let i = 0;

      for (const char of String(this.mask)) {
        if (char === "9") {
          if (unmaskedValue.length > i) {
            maskedValue += unmaskedValue.charAt(i);
            i++;
          } else {
            maskedValue += "_";
          }
        } else {
          maskedValue += char;
        }
      }

      this.maskedValue = maskedValue;
      this.$emit("input", unmaskedValue);
    },
  },
};
</script>
