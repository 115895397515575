//store can be defined similar to the composition API setup, but it doesn't work as expected.
import { defineStore } from "pinia";
//import the crud class
import { CRUD } from "../helpers/CRUD.js";

export const useProfileStore = defineStore("profile", {
  state: () => ({
    inputs: {
      signUp: {
        verified_email: false,
        pictureUrl: "",
        password: "",
        fullName: "",
        confirmPassword: "",
        email: "",
        accountType: "",
      },
      login: {
        email: "",
        password: "",
      },
    },
    exempt: ["attendance", "mark"],
    departments: [],
    staffs: [],
    tasks: [],
    task: {},
    leaves: [],
    singleStaff: {},
    singleDepartment: {},
    appointments: [],
    singleAppointment: {},
    messages: [],
    attendance: [],
    isLoading: [],
    mailData: [],
    documents: [],
    comments: [],
    mark: "",
    companyDetails: {},
  }),

  getters: {
    getIsLoading: (state) => state.isLoading,
    getDocuments: (state) => state.documents,
    getAllMessages: (state) => state.messages,
    getAllDepartments: (state) => state.departments,
    getAllStaff: (state) => state.staffs,
    getSingleStaff: (state) => state.singleStaff,
    getSingleDepartment: (state) => state.singleDepartment,
    getAppointments: (state) => state.appointments,
    getSingleAppointment: (state) => state.singleAppointment,
    getAllLeave: (state) => state.leaves,
    getAllAttendance: (state) => state.attendance,
    getMailData: (state) => state.mailData,
    getAllTasks: (state) => state.tasks,
    getSingleTask: (state) => state.task,
    getComments: (state) => state.comments,
    getCompanyDetails: (state) => state.companyDetails,
  },

  actions: {
    //use the crud class to create a new user
    async createUser(state = {}, signUpPayload, endpoint) {
      if (endpoint !== "SendMessage" && endpoint !== "ScheduleDemo" && endpoint !== "CreateCommentTask") {
        this.isLoading.push("true");
      }

      try {
        const crud = new CRUD("GENERAL");
        return await crud.create(endpoint, signUpPayload);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading.splice(0, 1);
      }
    },

    resetVariable(variableName) {
      // Get the initial value based on the variable name
      if (Array.isArray(this[variableName])) {
        this[variableName] = [];
      } else if (typeof this[variableName] === "object") {
        this[variableName] = {};
      } else if (typeof this[variableName] === "string") {
        this[variableName] = "";
      } else if (typeof this[variableName] === "number") {
        this[variableName] = 0;
      }

      // Set the variable to its initial value
    },

    markReadInStore(messageId) {
      // Get the initial value based on the variable name
      //  this.mailData.map((mail)=>{
      //   if(mail.messageId===messageId){
      //     mail.read = true
      //   }
      //   return mail
      //  })
      try {
        for (let mail of this.mailData) {
          if (mail.messageId === messageId) {
            mail.read = true;
            break;
          }
        }
      } catch (e) {
        console.log(e);
      }

      // Set the variable to its initial value
    },

    async readUser(payload, endpoint, storeKey) {
      if (!this.exempt.includes(storeKey)) {
        this.isLoading.push("true");
      }
      try {
        const crud = new CRUD("GENERAL");
        const data = await crud.read(endpoint, payload);
        if (data) {
          this[storeKey] = data;
        }
        return data;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading.splice(0, 1);
      }

      //access the store using the storeKey

      return data;
    },
  },

  persist: true,
});
