<template>
  <div class="col-lg-4">
    <div class="doctor-personals-grp">
      <div class="card">
        <div class="card-body">
          <div class="heading-detail">
            <h4 class="mb-3">About me</h4>
            <p>
              Hello I am Dr. Bruce Willis a Gynaecologist in Sanjivni Hospital Surat. I
              love to work with all my hospital staff and seniour doctors.
            </p>
          </div>
          <div class="about-me-list">
            <ul class="list-space">
              <li>
                <h4>Gender</h4>
                <span>Female</span>
              </li>
              <li>
                <h4>Operation Done</h4>
                <span>30+</span>
              </li>
              <li>
                <h4>Designation</h4>
                <span>Sr. Doctor</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="doctor-personals-grp">
      <div class="card">
        <div class="card-body">
          <div class="heading-detail">
            <h4>Skills:</h4>
          </div>
          <div class="skill-blk">
            <div class="skill-statistics">
              <div class="skills-head">
                <h5>Operations</h5>
                <p>45%</p>
              </div>
              <div class="progress mb-0">
                <div
                  class="progress-bar bg-operations"
                  role="progressbar"
                  style="width: 45%"
                  aria-valuenow="45"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div class="skill-statistics">
              <div class="skills-head">
                <h5>Patient Care</h5>
                <p>85%</p>
              </div>
              <div class="progress mb-0">
                <div
                  class="progress-bar bg-statistics"
                  role="progressbar"
                  style="width: 85%"
                  aria-valuenow="85"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div class="skill-statistics">
              <div class="skills-head">
                <h5>Endoscopic</h5>
                <p>65%</p>
              </div>
              <div class="progress mb-0">
                <div
                  class="progress-bar bg-endoscopic"
                  role="progressbar"
                  style="width: 65%"
                  aria-valuenow="65"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div class="skill-statistics">
              <div class="skills-head">
                <h5>Patient Visit</h5>
                <p>90%</p>
              </div>
              <div class="progress mb-0">
                <div
                  class="progress-bar bg-visit"
                  role="progressbar"
                  style="width: 90%"
                  aria-valuenow="90"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="doctor-personals-grp">
      <div class="card">
        <div class="card-body">
          <div class="heading-detail">
            <h4>Speciality</h4>
          </div>
          <div class="personal-activity">
            <div class="personal-icons status-grey">
              <img src="@/assets/img/icons/medal-01.svg" alt="" />
            </div>
            <div class="views-personal">
              <h4>Proffesionals</h4>
              <h5>Certified Skin Treatment</h5>
            </div>
          </div>
          <div class="personal-activity">
            <div class="personal-icons status-green">
              <img src="@/assets/img/icons/medal-02.svg" alt="" />
            </div>
            <div class="views-personal">
              <h4>Certified</h4>
              <h5>Cold Laser Operation</h5>
            </div>
          </div>
          <div class="personal-activity mb-0">
            <div class="personal-icons status-orange">
              <img src="@/assets/img/icons/medal-03.svg" alt="" />
            </div>
            <div class="views-personal">
              <h4>Medication Laser</h4>
              <h5>Hair Lose Product</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-8">
    <div class="doctor-personals-grp">
      <div class="card">
        <div class="card-body">
          <div class="tab-content-set">
            <ul class="nav">
              <li>
                <router-link to="doctor-profile"
                  ><span class="set-about-icon me-2"
                    ><img src="@/assets/img/icons/menu-icon-02.svg" alt="" /></span
                  >About me</router-link
                >
              </li>
              <li>
                <router-link to="doctor-setting" class="active"
                  ><span class="set-about-icon me-2"
                    ><img src="@/assets/img/icons/menu-icon-16.svg" alt="" /></span
                  >Settings</router-link
                >
              </li>
            </ul>
          </div>
          <div class="setting-form-blk">
            <form>
              <div class="row">
                <div class="col-12">
                  <div class="form-heading">
                    <h4>Account Setting</h4>
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <div class="input-block local-forms">
                    <label>Username </label>
                    <input class="form-control" type="text" value="brucewillis@29" />
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <div class="input-block local-forms">
                    <label>Mobile </label>
                    <input class="form-control" type="text" value="+1 23 456890" />
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <div class="input-block local-forms">
                    <label>Email </label>
                    <input
                      class="form-control"
                      type="email"
                      value="brucewillis@info.com"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-12">
                  <div class="input-block local-forms">
                    <label>Address <span class="login-danger">*</span></label>
                    <textarea class="form-control" rows="3" cols="30">
101, Elanxa Apartments, 340 N Madison Avenue</textarea
                    >
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-heading">
                  <h4>Security Setting</h4>
                </div>
              </div>
              <div class="col-12 col-sm-12">
                <div class="input-block local-forms">
                  <label>Current Password </label>
                  <input class="form-control" type="password" value="12345" />
                </div>
              </div>
              <div class="col-12 col-sm-12">
                <div class="input-block local-forms">
                  <label>New Password </label>
                  <input class="form-control" type="password" placeholder="" />
                </div>
              </div>
              <div class="col-12 col-sm-12">
                <div class="input-block local-forms">
                  <label>Confirm Password </label>
                  <input class="form-control" type="password" placeholder="" />
                </div>
              </div>
              <div class="col-12 col-sm-12">
                <div class="profile-check-blk input-block">
                  <div class="remember-me">
                    <label class="custom_check mr-2 mb-0 d-inline-flex remember-me">
                      Profile Visibility For Everyone
                      <input type="checkbox" name="radio" checked />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="remember-me">
                    <label class="custom_check mr-2 mb-0 d-inline-flex remember-me">
                      New task notifications
                      <input type="checkbox" name="radio" checked />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="remember-me">
                    <label class="custom_check mr-2 mb-0 d-inline-flex remember-me">
                      New friend request notifications
                      <input type="checkbox" name="radio" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="doctor-submit">
                  <button type="submit" class="btn btn-primary submit-form me-2">
                    Submit
                  </button>
                  <button type="submit" class="btn btn-primary cancel-form">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
