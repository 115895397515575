<template>
  <div class="page-wrapper">
    <div class="content">
      <b-button
        @click="$router.push({ path: '/task/add-task', query: { id: this.$route.query.id } })"
        variant="primary"
        class="float-end"
      >
        Edit
      </b-button>
    </div>
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="task" />

      <!-- /Page Header -->
      <div class="row">
        <Details />
        <side />
        <div class="col-md-7">
          <div class="widget new-comment clearfix">
            <h3 style="font-size: 14px">Leave a Comment</h3>
            <form @submit.prevent="createCommentTask">
              <div class="row">
                <div class="col-12 col-sm-12">
                  <div class="input-block local-forms">
                    <label>Comments <span class="login-danger">*</span></label>
                    <textarea v-model="args.commentText" class="form-control" rows="3" cols="30"></textarea>
                  </div>
                </div>
                <div class="col-12"></div>
                <div class="col-12">
                  <div class="doctor-submit text-end">
                    <!-- <button type="submit" class="btn btn-primary submit-form me-2">Submit</button> -->
                    <b-button type="submit" variant="primary me-1"
                      ><span
                        :class="loading ? 'spinner-border spinner-border-sm' : undefined"
                        class="me-2"
                        role="status"
                      ></span
                      >Submit</b-button
                    >
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Comments from "@/assets/json/comments.json";
import Details from "@/views/pages/task/task-details/details-list.vue";
import side from "@/views/pages/task/task-details/task-side.vue";
import { helperFunctions } from "@/composables/helperFunctions";
import { useProfileStore } from "@/stores/profile.js";
import { computed } from "vue";
import Task from "@/assets/json/task.json";
import Excel from "@/components/docs/Excel.vue";
import Word from "@/components/docs/Word.vue";
import { Tooltip } from "ant-design-vue";

export default {
  setup() {
    const profile = useProfileStore();
    const { readUser, createUser } = profile;
    const { truncateString, formatDateString, getAvatar, checkTaskStatus } = helperFunctions;

    const singleTask = computed(() => profile.getSingleTask);

    return { truncateString, formatDateString, getAvatar, checkTaskStatus, readUser, createUser, singleTask };
  },
  components: { Excel, Word, "a-tooltip": Tooltip, Details, side },
  data() {
    return {
      Comments: Comments,
      args: {
        taskId: "",
        commentText: "",
      },
      Blog: Task,
      title: "Task",
      text: "View Task",
      loading: false,
    };
  },
  methods: {
    async readTask() {
      try {
        const res = await this.readUser({ fetchSingleTaskId: this.$route.query.id }, "FetchSingleTask", "task");
        if (res) {
          console.log(res);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
    async readComments() {
      try {
        const res = await this.readUser({ taskId: this.$route.query.id }, "FetchComments", "comments");
        if (res) {
          console.log(res);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
    async createCommentTask() {
      this.args.taskId = this.$route.query.id;
      this.loading = true;
      try {
        const res = await this.createUser({}, { input: this.args }, "CreateCommentTask");
        if (res) {
          await this.readComments();
          this.args.commentText = "";
          toaster.success(`Comment sent.`);

          // this.$emit("close");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },

  async created() {
    await this.readTask();
  },
};
</script>
