<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="staff-list" />

      <div class="row">
        <div class="col-md-12">
          <div class="card bg-white">
            <div class="card-body">
              <ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
                <li class="nav-item">
                  <a class="nav-link active" href="#solid-rounded-tab1" data-bs-toggle="tab">Profile</a>
                </li>
                <li class="nav-item">
                  <a ref="salaryTab" class="nav-link" href="#solid-rounded-tab2" data-bs-toggle="tab">Salary</a>
                </li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane show active row" id="solid-rounded-tab1">
                  <div class="col-sm-12">
                    <div class="card">
                      <div class="card-body">
                        <form @submit.prevent="createStaff">
                          <div class="row">
                            <div class="col-12">
                              <div class="form-heading">
                                <h4>Staffs Details</h4>
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-4">
                              <div class="input-block local-forms">
                                <label>First Name <span class="login-danger">*</span></label>
                                <input
                                  required
                                  class="form-control"
                                  v-model="staffArgs.personalInformation.firstName"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-4">
                              <div class="input-block local-forms">
                                <label>Last Name <span class="login-danger">*</span></label>
                                <input
                                  required
                                  v-model="staffArgs.personalInformation.lastName"
                                  class="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-4">
                              <div class="input-block local-forms">
                                <label>User Name/Staff ID <span class="login-danger">*</span></label>
                                <input
                                  required
                                  v-model="staffArgs.personalInformation.userName"
                                  class="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-6">
                              <div class="input-block local-forms">
                                <label>Mobile <span class="login-danger">*</span></label>
                                <input
                                  required
                                  class="form-control"
                                  v-model="staffArgs.contactInformation.phoneNumber"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-6">
                              <div class="input-block local-forms">
                                <label>Email <span class="login-danger">*</span></label>
                                <input
                                  required
                                  class="form-control"
                                  v-model="staffArgs.contactInformation.email"
                                  type="email"
                                />
                              </div>
                            </div>
                            <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Password <span class="login-danger">*</span></label>
                      <input
                      required class="form-control" type="password" />
                    </div>
                  </div> -->
                            <!-- <div class="col-12 col-md-6 col-xl-6">
                    <div class="input-block local-forms">
                      <label>Confirm Password <span class="login-danger">*</span></label>
                      <input
                      required class="form-control" type="password" />
                    </div>
                  </div> -->
                            <div class="col-12 col-md-6 col-xl-6">
                              <div class="input-block local-forms cal-icon">
                                <label>Date Of Birth <span class="login-danger">*</span></label>
                                <datepicker
                                  v-model="staffArgs.personalInformation.birthDate"
                                  :input-format="dateFormat"
                                  class="form-control datetimepicker"
                                  :editable="true"
                                  :clearable="false"
                                  :required="true"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-6">
                              <div class="input-block select-gender">
                                <label class="gen-label">Gender<span class="login-danger">*</span></label>
                                <div class="form-check-inline">
                                  <label class="form-check-label">
                                    <input
                                      required
                                      type="radio"
                                      name="gender"
                                      class="form-check-input"
                                      value="MALE"
                                      @change="($event) => (staffArgs.personalInformation.gender = $event.target.value)"
                                    />Male
                                  </label>
                                </div>
                                <div class="form-check-inline">
                                  <label class="form-check-label">
                                    <input
                                      required
                                      type="radio"
                                      name="gender"
                                      class="form-check-input"
                                      value="FEMALE"
                                      @change="($event) => (staffArgs.personalInformation.gender = $event.target.value)"
                                    />Female
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-4">
                              <div class="input-block local-forms">
                                <label>Degree <span class="login-danger">*</span></label>
                                <input
                                  required
                                  v-model="staffArgs.educationInformation[0].degree"
                                  class="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-4">
                              <div class="input-block local-forms">
                                <label>Institution <span class="login-danger">*</span></label>
                                <input
                                  required
                                  v-model="staffArgs.educationInformation[0].university"
                                  class="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-6">
                              <div class="input-block local-forms cal-icon">
                                <label>Start Year <span class="login-danger">*</span></label>
                                <datepicker
                                  v-model="staffArgs.educationInformation[0].startYear"
                                  :input-format="dateFormat"
                                  class="form-control datetimepicker"
                                  :editable="true"
                                  :clearable="false"
                                  :required="true"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-6">
                              <div class="input-block local-forms cal-icon">
                                <label>End Year <span class="login-danger">*</span></label>
                                <datepicker
                                  v-model="staffArgs.educationInformation[0].graduationYear"
                                  :input-format="dateFormat"
                                  class="form-control datetimepicker"
                                  :editable="true"
                                  :clearable="false"
                                  :required="true"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-4">
                              <div class="input-block local-forms">
                                <label>Result <span class="login-danger">*</span></label>
                                <input
                                  required
                                  v-model="staffArgs.educationInformation[0].result"
                                  class="form-control"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-4">
                              <div class="input-block local-forms">
                                <label>Designation <span class="login-danger">*</span></label>
                                <input required v-model="staffArgs.position" class="form-control" type="text" />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-4">
                              <div class="input-block local-forms">
                                <label>Department <span class="login-danger">*</span></label>
                                <vue-select
                                  :options="allDepartments"
                                  placeholder="Select Department"
                                  v-model="staffArgs.department"
                                />
                              </div>
                            </div>

                            <div class="col-12 col-md-6 col-xl-6">
                              <div class="input-block local-forms cal-icon">
                                <label>Start Date <span class="login-danger">*</span></label>
                                <datepicker
                                  v-model="staffArgs.dateOfJoining"
                                  :input-format="dateFormat"
                                  class="form-control datetimepicker"
                                  :editable="true"
                                  :clearable="false"
                                  :required="true"
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div class="col-12 col-sm-12">
                              <div class="input-block local-forms">
                                <label>Address <span class="login-danger">*</span></label>
                                <textarea
                                  class="form-control"
                                  rows="3"
                                  cols="30"
                                  v-model="staffArgs.contactInformation.address"
                                ></textarea>
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-3">
                              <div class="input-block local-forms">
                                <label>City <span class="login-danger">*</span></label>
                                <vue-select
                                  :options="StaffCity"
                                  id="Staffcity"
                                  placeholder="Select City"
                                  v-model="staffArgs.contactInformation.city"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-3">
                              <div class="input-block local-forms">
                                <label>Country <span class="login-danger">*</span></label>
                                <vue-select
                                  :options="StaffCountry"
                                  id="Staffcountry"
                                  placeholder="Select Country"
                                  v-model="staffArgs.contactInformation.country"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-3">
                              <div class="input-block local-forms">
                                <label>State/Province <span class="login-danger">*</span></label>
                                <vue-select
                                  :options="StaffState"
                                  id="Staffstate"
                                  placeholder="Select State"
                                  v-model="staffArgs.contactInformation.state"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-3">
                              <div class="input-block local-forms">
                                <label>Postal Code <span class="login-danger">*</span></label>
                                <input
                                  required
                                  class="form-control"
                                  v-model="staffArgs.contactInformation.postalCode"
                                  type="text"
                                />
                              </div>
                            </div>
                            <div class="col-12 col-sm-12">
                              <div class="input-block local-forms">
                                <label>Start Biography <span class="login-danger">*</span></label>
                                <textarea
                                  class="form-control"
                                  rows="3"
                                  cols="30"
                                  v-model="staffArgs.personalInformation.aboutMe"
                                ></textarea>
                              </div>
                            </div>
                            <!-- <div class="col-12 col-md-6 col-xl-6">
                              <div class="input-block local-top-form">
                                <label class="local-top">Avatar </label>
                                <div class="settings-btn upload-files-avator">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    name="image"
                                    id="file"
                                    onchange="loadFile(event)"
                                    class="hide-input"
                                  />
                                  <label for="file" class="upload">Choose File</label>
                                </div>
                              </div>
                            </div> -->

                            <div class="col-12 col-md-6 col-xl-6">
                              <div class="input-block local-top-form">
                                <!-- <label class="local-top">Bank name </label> -->
                                <div class="input-block local-forms">
                                  <vue-select :options="bankOptions" placeholder="Select Bank" />
                                </div>
                              </div>
                            </div>
                            <div class="col-12 col-md-6 col-xl-6">
                              <div class="input-block local-top-form">
                                <div class="input-block local-forms">
                                  <label for="">Account number</label>
                                  <input class="form-control" type="text" />
                                </div>
                              </div>
                            </div>

                            <div class="col-12 col-md-6 col-xl-6">
                              <div class="input-block local-top-form">
                                <div class="input-block local-forms">
                                  <label for="">Account name</label>
                                  <input class="form-control" type="text" />
                                </div>
                              </div>
                            </div>

                            <div class="col-12 col-md-6 col-xl-6">
                              <div class="input-block select-gender">
                                <label class="gen-label">Status <span class="login-danger">*</span></label>
                                <div class="form-check-inline">
                                  <label class="form-check-label">
                                    <input required type="radio" name="gender" class="form-check-input" />Active
                                  </label>
                                </div>
                                <div class="form-check-inline">
                                  <label class="form-check-label">
                                    <input required type="radio" name="gender" class="form-check-input" />In Active
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="doctor-submit text-end">
                                <button type="submit" class="btn btn-primary submit-form me-2">Submit</button>
                                <button @click="$router.go(-1)" type="button" class="btn btn-primary cancel-form">
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <Salary @input="handleInput($event)" class="tab-pane" id="solid-rounded-tab2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Header -->
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useProfileStore } from "@/stores/profile.js";
import Salary from "@/components/payroll/Salary.vue";
import bankList from "@/utils/banks.js";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-right",
  showCloseButton: true,
  duration: 5000,
});
const currentDate = new Date();
export default {
  components: { Salary },
  setup() {
    const profile = useProfileStore();
    const { createUser, readUser } = profile;

    const allDepartments = computed(() => {
      // const data =
      //   profile.getAllDepartments &&
      //   profile.getAllDepartments.map((item) => {
      //     return {
      //       id: item.name,
      //       text: item._id,
      //     };
      //   });

      const data =
        profile.getAllDepartments &&
        profile.getAllDepartments.map((item) => {
          return { id: item._id, text: item.name };
        });

      return data;
    });
    return {
      createUser,
      readUser,
      allDepartments,
    };
  },
  data() {
    return {
      bankList,
      title: "Staffs",
      text: "Add Staffs",
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      StaffDepartment: ["Select Department", "Orthopedics", "Radiology", "Dentist"],
      StaffCountry: ["Select County", "Usa", "Uk", "Italy"],
      StaffCity: ["Select City", "Alaska", "Los Angeles"],
      StaffState: ["Select State", "Alaska", "California"],
      salaryArgs: {},
      staffArgs: {
        personalInformation: {
          firstName: "",
          lastName: "",
          userName: "",
          gender: "",
          aboutMe: "",
          birthDate: currentDate,
        },
        educationInformation: [
          {
            degree: "",
            university: "",
            startYear: currentDate,
            graduationYear: currentDate,
            result: "",
          },
        ],
        contactInformation: {
          address: "",
          phoneNumber: "",
          email: "",
          city: "",
          state: "",
          country: "",
          postalCode: "",
        },
        department: "",
        position: "",
        // salary: {},
        dateOfJoining: currentDate,
        accountType: "STAFF",
        addedSalary: false,
      },
    };
  },
  computed: {
    bankOptions() {
      const data = this.bankList.map((bank) => {
        return {
          id: bank.code,
          text: bank.name,
        };
      });
      return data;
    },
  },

  methods: {
    async handleInput(e) {
      this.salaryArgs = e;
      this.addedSalary = true;
      await this.createStaff();
    },
    async createStaff() {
      try {
        if (!this.addedSalary) {
          this.$refs.salaryTab.click();
          return;
        }
        const res = await this.createUser({}, { salary: this.salaryArgs, input: this.staffArgs }, "CreateStaff");
        if (res) {
          toaster.success(`Staff created successfully.`);
          this.$router.push("/staff/staff-list");
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },

    async readDepartment() {
      try {
        const res = await this.readUser({}, "GetAllDepartment", "departments");
        if (res) {
          console.log(res);
          // toaster.success(`Staff read successfully.`);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
  },

  async created() {
    await this.readDepartment();
  },
};
</script>
