<template>
  <main>
    <div class="navbar">
      <div class="container flex">
        <h1 class="logo"><a href="index.html">Thuffice.</a></h1>
        <nav>
          <ul>
            <li><router-link to="/login">Login</router-link></li>
            <li><a href="#features-section">Features</a></li>
            <!-- <li><a href="docs.html">Docs</a></li> -->
          </ul>
        </nav>
      </div>
    </div>
    <!-- Showcase -->
    <section style="margin-bottom: 100px; background-image: white" class="showcase">
      <div class="l-container grid">
        <div class="showcase-text">
          <h1>Easier Workflow</h1>
          <p>
            Explore the possibility of an easy work environment <br />
            by using our powerful tools to keep all <br />
            processes organized
          </p>
          <a href="#features-section" class="btn btn-outline">Learn More</a>
        </div>

        <div class="showcase-form card">
          <h4>Request a Demo</h4>
          <!--  <form name="contact" netlify-honeypot="bot-field" method="POST" data-netlify="true">
                    <input type="hidden" name="form-name" value="contact">
                    <p class="hidden">
                        <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                      </p>
                    <div class="form-control">
                        <input type="text" name="name" placeholder="Name" required>
                    </div>
                    <div class="form-control">
                        <input type="text" name="company" placeholder="Company Name" required>
                    </div>
                    <div class="form-control">
                        <input type="email" name="email" placeholder="Email" required>
                    </div>
                    <input type="submit" value="Send" class="btn btn-primary">
                </form> -->
          <form @submit.prevent="submitForm()">
            <div class="form-control">
              <input v-model="args.payload.name" type="text" name="name" placeholder="Name" required />
            </div>
            <div class="form-control">
              <input type="text" name="company" placeholder="Company Name" v-model="args.payload.companyName" />
            </div>
            <div class="form-control">
              <input type="email" v-model="args.payload.email" name="email" placeholder="Email" required />
            </div>
            <!-- <input type="submit" value="Send" class="btn btn-primary" /> -->
            <b-button type="submit" variant="primary me-1"
              ><span :class="loading ? 'spinner-border spinner-border-sm' : undefined" class="me-2" role="status"></span
              >Send</b-button
            >
          </form>
        </div>
      </div>
    </section>

    <!-- Sub head -->
    <section id="features-section" style="background: white" class="features-sub-head py-3">
      <div style="margin-bottom: 90px" class="l-container grid">
        <div>
          <h1 class="md">The Thuffice Platform</h1>
          <p style="line-height: 1.6" class="sm">
            A one-stop tool for team collaboration and management
            <br class="hidden-item" />
            that boosts time and cost efficiency by centralizing and enabling the performance of all team activities.
          </p>
          <b-button @click="$router.push({ path: '/login' })" style="margin-top: 20px" variant="primary me-1"
            ><span class="me-2" role="status"></span>Get started</b-button
          >
        </div>
        <img src="@/assets/icons/dash2.jpg" alt="" />
      </div>

      <div style="margin-bottom: 90px; gap: 40px; width: 100%" class="l-container grid">
        <img src="@/assets/icons/thuffice-payroll.png" alt="" />

        <div class="m-left">
          <h1 class="md">Simplify Payroll & Operations</h1>

          <p style="line-height: 1.6; width: 100%" class="sm">
            Streamline your payroll and operations with Thufffice's comprehensive tools. Automate payroll processing,
            manage employee benefits, and handle compliance effortlessly. Integrate seamlessly with your existing
            systems to ensure accuracy and efficiency in all operational tasks
          </p>

          <b-button @click="$router.push({ path: '/login' })" style="margin-top: 20px" variant="primary me-1"
            ><span class="me-2" role="status"></span>Get started</b-button
          >
        </div>
      </div>

      <div style="margin-bottom: 90px; gap: 40px; width: 100%" class="l-container grid">
        <img class="reverse-hidden" style="object-fit: contain" src="@/assets/icons/attendance.jpg" alt="" />

        <div>
          <h1 class="md">Track Staff Performance</h1>
          <p style="line-height: 1.6" class="sm">
            Efficiently monitor your team's attendance and performance with Thuffice's real-time tracking, performance
            insights, and customizable dashboards, all seamlessly integrated with your existing tools.
          </p>

          <b-button @click="$router.push({ path: '/login' })" style="margin-top: 20px" variant="primary me-1"
            ><span class="me-2" role="status"></span>Get started</b-button
          >
        </div>
        <img class="hidden-item" style="object-fit: contain" src="@/assets/icons/attendance.jpg" alt="" />
      </div>

      <div style="margin-bottom: 90px; gap: 40px; width: 100%" class="l-container grid">
        <img class="hidden-item" style="object-fit: contain" src="@/assets/icons/thuffice-docs.jpg" alt="" />
        <div class="m-left">
          <h1 class="md">Collabrate with others</h1>
          <p style="line-height: 1.6" class="sm">
            Maximize your collaboration efforts by leveraging
            <br class="hidden-item" />
            Thuffice's shared workspaces, task management
            <br class="hidden-item" />
            tools, and seamless communication features. Ensure streamlined teamwork by utilizing built-in document
            collaboration and version control functionalities for efficient project completion.
          </p>

          <b-button @click="$router.push({ path: '/login' })" style="margin-top: 20px" variant="primary me-1"
            ><span class="me-2" role="status"></span>Get started</b-button
          >
        </div>

        <img class="reverse-hidden" style="object-fit: contain" src="@/assets/icons/thuffice-docs.jpg" alt="" />
      </div>

      <!-- <div style="margin-bottom: 40px" class="l-container grid">
        <div>
          <h1 class="md">Collabrate with others</h1>
          <p style="line-height: 1.6" class="sm">
            Maximize your collaboration efforts by leveraging
            <br class="hidden-item" />
            Thuffice's shared workspaces, task management
            <br class="hidden-item" />
            tools, and seamless communication features. Ensure streamlined teamwork by utilizing built-in document
            collaboration and version control functionalities <br class="hidden-item" />
            for efficient project completion.
          </p>

          <b-button @click="$router.push({ path: '/login' })" style="margin-top: 20px" variant="primary me-1"
            ><span class="me-2" role="status"></span>Get started</b-button
          >
        </div>
        <img src="@/assets/icons/thuffice-docs.jpg" alt="" />
      </div> -->
    </section>

    <!-- Footer -->
    <footer class="footer bg-dark py-3">
      <div class="l-container grid">
        <div>
          <h3>Thuffice</h3>
          <p>Copyright &copy; 2024</p>
        </div>
        <nav>
          <ul>
            <li class="py-2"><router-link to="/login">Login</router-link></li>
            <li class="py-2"><a href="#features-section">Features</a></li>
            <!-- <li><a href="docs.html">Docs</a></li> -->
          </ul>
        </nav>
        <div class="social">
          <a href="#"><i class="fab fa-github"></i></a>
          <a href="#"><i class="fab fa-facebook"></i></a>
          <a href="#"><i class="fab fa-instagram"></i></a>
          <a href="#"><i class="fab fa-twitter"></i></a>
        </div>
      </div>
    </footer>
  </main>
</template>

<script setup>
import axios from "axios";

import { onMounted, ref } from "vue";
import { helperFunctions } from "@/composables/helperFunctions";
import { createToaster } from "@meforma/vue-toaster";
import { useProfileStore } from "@/stores/profile.js";

const profile = useProfileStore();
const { createUser, readUser } = profile;

const { makeApiRequest } = helperFunctions;

const toaster = createToaster({
  position: "top-right",
  showCloseButton: true,
  duration: 5000,
});
const loading = ref(false);
const args = ref({
  payload: {
    name: "",
    email: "",
    companyName: "",
  },
});
function containsMaliciousInput(input) {
  // Regular expressions to match potentially harmful characters or patterns
  var patterns = [
    /<script/i,
    /<\/script/i,
    /[<>]/,
    /on\w+\s*=/i,
    /<[^>]*>/,
    /<\/[^>]*>/,
    /<\/[^>]+>/, // Matches any closing tag (e.g., </div>, </img>)
  ];

  // Check if any of the patterns match the input
  for (var i = 0; i < patterns.length; i++) {
    if (patterns[i].test(input)) {
      return true; // Found a match, input is potentially malicious
    }
  }

  return false; // No potentially harmful patterns found
}

async function schedule() {
  try {
    const res = await createUser({}, args.value, "ScheduleDemo");
    if (res) {
      return res;

      // router.push("/departments/department-list");
    }
  } catch (error) {
    console.log(error);
  }
}

async function submitForm() {
  console.log(args.value);
  loading.value = true;
  try {
    // const response = await makeApiRequest(
    //   "POST",
    //   "https://thufficehealth.onrender.com/demo",
    //   args.value
    // );
    const response = await schedule();
    args.value = {
      payload: {
        name: "",
        email: "",
        companyName: "",
      },
    };
    toaster.success(`<span>🎉Demo request received!</span><span><small>Check your inbox soon. 🚀</small></span>`);
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
}

async function logVisit() {
  try {
    await axios.get("https://thufficehealth.onrender.com/visit", {
      headers: {
        "Content-Type": "application/json",
        "Apollo-Require-Preflight": "false",
      },
    });
  } catch (e) {
    console.log(e);
  }
}

onMounted(async () => {
  await logVisit();
});
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

/* :root {
  --primary-color: #2e37a4;
  --secondary-color: #1c3fa8;
  --dark-color: #002240;
  --light-color: #f4f4f4;
  --success-color: #5cb85c;
  --error-color: #d9534f;
} */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* body {
  font-family: "Lato", sans-serif;
  color: #333;
  line-height: 1.6;
} */

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #333;
}
h1,
h2 {
  font-weight: 300;
  line-height: 1.2;
  margin: 10px 0;
}

p {
  margin: 10px 0;
}

img {
  width: 100%;
}

code,
pre {
  background: #333;
  color: #fff;
  padding: 10px;
}

.hidden {
  visibility: hidden;
  height: 0;
}

/* Navbar */
.navbar {
  background-color: #2e37a4;
  color: #fff;
  height: 70px;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 10px;
  margin: 0 5px;
}

.navbar ul a:hover {
  border-bottom: 2px #fff solid;
}

.navbar .flex {
  justify-content: space-between;
}

/* Showcase */
.showcase {
  height: 400px;
  background-color: #2e37a4;
  /* background-color: #2e37a4; */
  color: #fff;
  position: relative;
}

.showcase h1 {
  font-size: 40px;
}

.showcase p {
  margin: 20px 0;
}

.showcase .grid {
  overflow: visible;
  grid-template-columns: 55% auto;
  gap: 30px;
}

.showcase-text {
  animation: slideInFromLeft 1s ease-in;
}

.showcase-form {
  position: relative;
  top: 60px;
  height: 350px;
  width: 400px;
  padding: 40px;
  z-index: 100;
  justify-self: flex-end;
  animation: slideInFromRight 1s ease-in;
}

.showcase-form .form-control {
  margin: 20px 0;
}

.showcase-form input[type="text"],
.showcase-form input[type="email"] {
  border: 0;
  border-bottom: 1px solid #b4becb;
  width: 100%;
  padding: 3px;
  font-size: 12px !important;
}

.showcase-form input:focus {
  outline: none;
}

.showcase::before,
.showcase::after {
  content: "";
  position: absolute;
  height: 100px;
  bottom: -70px;
  right: 0;
  left: 0;
  background: #fff;
  transform: skewY(-3deg);
  -webkit-transform: skewY(-3deg);
  -moz-transform: skewY(-3deg);
  -ms-transform: skewY(-3deg);
}

/* Stats */
.stats {
  padding-top: 100px;
  animation: slideInFromBottom 1s ease-in;
}

.stats-heading {
  max-width: 500px;
  margin: auto;
}

.stats .grid h3 {
  font-size: 35px;
}

.stats .grid p {
  font-size: 20px;
  font-weight: bold;
}

/* Cli */
.cli .grid {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.cli .grid > *:first-child {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
}

/* Cloud */
.cloud .grid {
  grid-template-columns: 4fr 3fr;
}

/* Languages */

.languages .flex {
  flex-wrap: wrap;
}

.languages .card {
  text-align: center;
  margin: 18px 10px 40px;
  transition: transform 0.2s ease-in;
}

.languages .card h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.languages .card:hover {
  transform: translateY(-15px);
}

/* Features */
.features-head img,
.docs-head img {
  width: 200px;
  justify-self: flex-end;
}

.features-sub-head img {
  width: 100%;
  justify-self: flex-end;
  object-fit: cover;
}

.features-main .card > i {
  margin-right: 20px;
}

.features-main .grid {
  padding: 30px;
}

.features-main .grid > *:first-child {
  grid-column: 1 / span 3;
}

.features-main .grid > *:nth-child(2) {
  grid-column: 1 / span 2;
}

/* Docs */
.docs-main h3 {
  margin: 20px 0;
}

.docs-main .grid {
  grid-template-columns: 1fr 2fr;
  align-items: flex-start;
}

.docs-main nav li {
  font-size: 17px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px #ccc solid;
}

.docs-main a:hover {
  font-weight: bold;
}

/* Footer */
.footer .social a {
  margin: 0 10px;
}

.fa-github:hover {
  color: #000000;
}

.fa-facebook:hover {
  color: #1773ea;
}

.fa-instagram:hover {
  color: #b32e87;
}

.fa-twitter:hover {
  color: #1c9cea;
}

.hidden-item {
  display: grid;
}

.reverse-hidden {
  display: none;
}

.m-left {
  margin-left: 80px;
}

/* Animations */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateX(0);
  }
}
@media (max-width: 1000px) {
  .showcase-form {
    width: 340px;
  }
}

/* Tablets and under */
@media (max-width: 768px) {
  .grid,
  .showcase .grid,
  .stats .grid,
  .cli .grid,
  .cloud .grid,
  .features-main .grid,
  .features-sub-head .grid,
  .docs-main .grid,
  .grid-3 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  /* .grid-3 {
    grid-template-columns: 1fr;
  } */
  .hidden-item {
    display: none;
  }
  .reverse-hidden {
    display: block;
  }
  .m-left {
    margin-left: 0px;
  }
  .showcase {
    height: auto;
  }

  .showcase-text {
    text-align: center;
    margin-top: 40px;
    animation: slideInFromTop 1s ease-in;
  }

  .showcase-form {
    justify-self: center;
    margin: auto;
    animation: slideInFromBottom 1s ease-in;
  }

  .cli .grid > *:first-child {
    grid-column: 1;
    grid-row: 1;
  }

  .features-head,
  .features-sub-head,
  .docs-head {
    text-align: center;
  }

  .features-head img,
  .features-sub-head img,
  .docs-head img {
    justify-self: center;
  }

  .features-main .grid > *:first-child,
  .features-main .grid > *:nth-child(2) {
    grid-column: 1;
  }

  .showcase-form input[type="text"],
  .showcase-form input[type="email"] {
    border: 0;
    border-bottom: 1px solid #b4becb;
    width: 100%;
    padding: 3px;
    font-size: 16px !important;
  }
}

/* Mobile */
@media (max-width: 500px) {
  .navbar {
    height: 110px;
  }

  .navbar .flex {
    flex-direction: column;
  }

  .navbar ul {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .showcase-form {
    width: 300px;
  }
}
.l-container {
  max-width: 1100px;
  margin: 0 auto;
  overflow: auto;
  padding: 0 40px;
}

.card {
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 10px;
}

.btn {
  display: inline-block;
  padding: 10px 30px;
  cursor: pointer;
  background: #2e37a4;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.btn-outline {
  background-color: transparent;
  border: 1px #fff solid;
}

.btn:hover {
  transform: scale(0.98);
}

/* Backgrounds & colored buttons */
.bg-primary,
.btn-primary {
  background-color: #2e37a4;
  color: #fff;
}

.bg-secondary,
.btn-secondary {
  background-color: var(--secondary-color);
  color: #fff;
}

.bg-dark,
.btn-dark {
  background-color: var(--dark-color);
  color: #fff;
}

.bg-light,
.btn-light {
  background-color: var(--light-color);
  color: #333;
}

.bg-primary a,
.btn-primary a,
.bg-secondary a,
.btn-secondary a,
.bg-dark a,
.btn-dark a {
  color: #fff;
}

/* Text colors */
.text-primary {
  color: #2e37a4;
}

.text-secondary {
  color: var(--secondary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-light {
  color: var(--light-color);
}

/* Text sizes */
.lead {
  font-size: 20px;
}

.sm {
  font-size: 1rem;
}

.md {
  font-size: 2rem;
}

.lg {
  font-size: 3rem;
}

.xl {
  font-size: 4rem;
}

.text-center {
  text-align: center;
}

/* Alert */
.alert {
  background-color: var(--light-color);
  padding: 10px 20px;
  font-weight: bold;
  margin: 15px 0;
}

.alert i {
  margin-right: 10px;
}

.alert-success {
  background-color: var(--success-color);
  color: #fff;
}

.alert-error {
  background-color: var(--error-color);
  color: #fff;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

/* Margin */
.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 1.5rem 0;
}

.my-3 {
  margin: 2rem 0;
}

.my-4 {
  margin: 3rem 0;
}

.my-5 {
  margin: 4rem 0;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 1.5rem;
}

.m-3 {
  margin: 2rem;
}

.m-4 {
  margin: 3rem;
}

.m-5 {
  margin: 4rem;
}

/* Padding */
.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 1.5rem 0;
}

.py-3 {
  padding: 2rem 0;
}

.py-4 {
  padding: 3rem 0;
}

.py-5 {
  padding: 4rem 0;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 1.5rem;
}

.p-3 {
  padding: 2rem;
}

.p-4 {
  padding: 3rem;
}

.p-5 {
  padding: 4rem;
}
</style>
