const lifeInsuranceBodies = [
  "African Alliance Insurance Company Ltd",
  "AIICO Insurance Plc",
  "Alliance & General Insurance Plc",
  "ALLIANZ NIGERIA INSURANCE PLC",
  "Anchor Insurance Company Ltd",
  "ARM Life Plc",
  "AXA Mansard Insurance plc",
  "Capital Express Assurance Limited",
  "Consolidated Hallmark Insurance Plc",
  "Continental Reinsurance Company Plc",
  "Cornerstone Insurance Plc",
  "Custodian & Allied Insurance Limited",
  "Custodian Life Assurance Limited",
  "FBN General Insurance Limited",
  "FBN Insurance Limited",
  "Fin Insurance Company Limited",
  "Great Nigeria Insurance Plc",
  "Guinea Insurance Plc",
  "GoldLink Insurance Plc",
  "International Energy Insurance Plc",
  "Industrial & General Insurance Plc",
  "JAIZ TAKAFUL INSURANCE PLC",
  "KBL Insurance Limited",
  "LASACO Assurance Plc",
  "Law Union & Rock Insurance  Plc",
  "Leadway Assurance Company Limited",
  "Linkage Assurance Plc",
  "Tangerine Life Insurance  Ltd",
  "Mutual Benefits Assurance Plc",
  "Mutual Benefits Life Assurance Ltd",
  "NEM Insurance Plc",
  "NICON Insurance Plc",
  "Niger Insurance Plc",
  "Nigeria Reinsurance Corporation",
  "Nigerian Agricultural Insurance Corporation",
  "NSIA Insurance Ltd",
  "Old Mutual Nigeria General Insurance Company Limited",
  "Old Mutual NigeriaLife Assurance Company Limited",
  "Prestige Assurance Plc",
  "Prudential Zenith Life Assurance Company Limited",
  "Regency Alliance Insurance Plc",
  "Royal Exchange General Insurance Company Ltd",
  "Royal Exchange Prudential Life",
  "Unitrust Insurance Nigeria Limited",
  "Sovereign Trust Insurance Plc",
  "STACO Insurance Plc",
  "Standard Alliance Insurance Plc",
  "Sterling Assurance Nigeria Ltd",
  "SUNU Assurance Plc",
  "UNIC Insurance Plc",
  "Universal Insurance Plc",
  "Veritas Kapital Assurance Plc",
  "Coronation Insurance Plc",
  "Coronation Life Assurance Ltd",
  "Zenith General Insurance Company Limited",
  "GOXI Microinsurance",
  "NOOR Takaful Plc",
  "Corenerstone Takaful Insurance Co. Ltd",
];

export default lifeInsuranceBodies;
