<template>
  <div class="col-md-7">
    <div class="blog-view">
      <article class="blog blog-single-post">
        <h3 class="blog-title">{{ singleTask.taskName || "N/A" }}</h3>

        <div class="blog-info clearfix">
          <div class="post-right read-blks">
            <a href="#.">Created by: {{ singleTask && singleTask.createdBy && singleTask.createdBy.fullName }}</a>
            <ul class="mt-2 bullets">
              <li :class="checkTaskStatus(singleTask.projectEnd).class">
                {{ singleTask && singleTask.projectEnd && checkTaskStatus(singleTask.projectEnd).text }}
              </li>
            </ul>
          </div>
          <div class="post-left date-blks">
            <ul>
              <li>
                <a href="#."
                  ><i class="feather-calendar"></i> <span>{{ formatDateString(singleTask.projectStart) }}</span></a
                >
              </li>
              <li>-</li>
              <li>
                <a href="#."
                  ><i class="feather-calendar"></i> <span>{{ formatDateString(singleTask.projectEnd) }}</span></a
                >
              </li>
              <!-- <li>
                <a href="#."><i class="feather-message-square"></i> <span>58</span></a>
              </li> -->
              <!-- <li>
                <a href="#."><i class="feather-eye"></i> <span>2.8k</span></a>
              </li> -->
            </ul>
          </div>
        </div>
        <div class="blog-image">
          <a href="#."
            ><img
              alt=""
              :src="singleTask && singleTask.attachFile && singleTask.attachFile.full"
              style="height: 450px"
              class="img-fluid"
          /></a>
        </div>
        <div class="blog-content">
          <p>
            {{ singleTask.taskSummary }}
          </p>
        </div>

        <div v-if="singleTask && singleTask.documents && singleTask.documents.length" class="mail-attach">
          <h2>Attachments</h2>
          <ul class="msg-sub-list nav">
            <li v-for="doc in singleTask.documents" :key="doc._id">
              <router-link :to="`/docs/edit-doc/?id=${doc._id}`" target="_blank">
                <img style="width: 20px" :src="docIcon[doc.docType]" alt="" class="me-1" />{{ doc.docName }}
              </router-link>
            </li>
            <!-- <li>
              <img src="@/assets/img/icons/gallery-icon.svg" alt="" class="me-1" />Expense.img
              <span class="ms-1">4.0 MB</span><img src="@/assets/img/icons/chat-icon-07.svg" alt="" class="ms-2" /><i
                class="feather-x ms-2"
              ></i>
            </li> -->
          </ul>
        </div>
        <div class="blog-share">
          <!-- <div class="share-blogs d-flex align-items-center">
            <a href="javascript:;"><img alt="" src="@/assets/img/icons/social-01.svg" /></a>
            <span class="ms-2">2.8k</span>
          </div> -->
          <ul class="social-share nav">
            <div v-for="person in singleTask.assignedTo" :key="person._id" class="avatar-group">
              <!-- <div class="avatar">
                      <img
                        class="avatar-img rounded-circle border border-white"
                        alt="User Image"
                        src="@/assets/img/profiles/avatar-03.jpg"
                      />
                    </div> -->
              <div class="popover-list">
                <a-popover trigger="hover" placement="top">
                  <template #content>
                    <span>{{ `${person.personalInformation.firstName} ${person.personalInformation.lastName}` }}</span>
                  </template>

                  <div
                    :style="`background-color:${getAvatar(person.personalInformation.firstName).color}`"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      justify-self: end;
                      cursor: pointer;
                    "
                    class="avatar border border-white"
                  >
                    <span class="avatar-title rounded-circle">
                      {{ getAvatar(person.personalInformation.firstName).name }}</span
                    >
                  </div>
                </a-popover>
              </div>
            </div>
          </ul>
        </div>
      </article>

      <div v-if="allComments && allComments.length" class="widget blog-comments clearfix">
        <h3>Comments</h3>
        <ul class="comments-list">
          <li v-for="record in allComments" :key="record._id">
            <div class="comment">
              <div class="comment-author">
                <router-link to="/profile"
                  ><img class="avatar" alt="" :src="require(`@/assets/img/profiles/avatar-01.jpg`)"
                /></router-link>
              </div>
              <div class="comment-block">
                <div class="comment-by">
                  <div class="week-group">
                    <h5 class="blog-author-name">{{ record.createdBy.fullName }}</h5>
                    <span class="week-list">{{ getRelativeTime(record.createdAt) }}</span>
                  </div>
                  <span class="float-end">
                    <span class="blog-reply"
                      ><a href="#."><i class="fa fa-reply"></i> Reply</a></span
                    >
                  </span>
                </div>
                <p>
                  {{ record.commentText }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Comments from "@/assets/json/comments.json";

import { helperFunctions } from "@/composables/helperFunctions";
import { useProfileStore } from "@/stores/profile.js";
import { computed } from "vue";
import Task from "@/assets/json/task.json";
import Excel from "@/components/docs/Excel.vue";
import Word from "@/components/docs/Word.vue";
import { Tooltip } from "ant-design-vue";

import pdf from "@/assets/img/icons/pdf-icon-01.svg";
import txt from "@/assets/img/icons/pdf-icon-02.svg";
import csv from "@/assets/img/icons/pdf-icon-03.svg";
import xlsx from "@/assets/img/icons/pdf-icon-04.svg";

export default {
  props: ["data"],
  setup() {
    const profile = useProfileStore();
    const { readUser } = profile;
    const { truncateString, formatDateString, getAvatar, checkTaskStatus, getRelativeTime } = helperFunctions;

    const singleTask = computed(() => profile.getSingleTask);
    const allComments = computed(() => profile.getComments);
    const docIcon = {
      word: txt,
      xlsx,
      excel: xlsx,
    };
    return {
      truncateString,
      formatDateString,
      getAvatar,
      checkTaskStatus,
      readUser,
      singleTask,
      allComments,
      getRelativeTime,
      docIcon,
    };
  },
  components: { Excel, Word, "a-tooltip": Tooltip },
  data() {
    return {
      Comments: Comments,

      Blog: Task,
      title: "Task",
      text: "Tasks",
    };
  },
  methods: {
    async readComments() {
      try {
        const res = await this.readUser({ taskId: this.$route.query.id }, "FetchComments", "comments");
        if (res) {
          console.log(res);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
  },
  async created() {
    await this.readComments();
  },
};
</script>

<style></style>
