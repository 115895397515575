<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="about-info">
          <h4>
            Doctor Profile
            <span
              ><a href="javascript:;"><i class="feather-more-vertical"></i></a
            ></span>
          </h4>
        </div>
        <div class="doctor-profile-head">
          <div class="profile-bg-img">
            <img src="@/assets/img/profile-bg.jpg" alt="Profile" />
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <div class="profile-user-box">
                <div class="profile-user-img">
                  <img src="@/assets/img/profile-user.jpg" alt="Profile" />
                  <div class="input-block doctor-up-files profile-edit-icon mb-0">
                    <div class="uplod d-flex">
                      <label class="file-upload profile-upbtn mb-0">
                        <img src="@/assets/img/icons/camera-icon.svg" alt="Profile" />
                        <input type="file" />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="names-profiles">
                  <h4>Dr. Bruce Willis</h4>
                  <h5>Senior Doctor</h5>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 d-flex align-items-center">
              <div class="follow-group">
                <div class="doctor-follows">
                  <h5>Followers</h5>
                  <h4>850</h4>
                </div>
                <div class="doctor-follows">
                  <h5>Following</h5>
                  <h4>18K</h4>
                </div>
                <div class="doctor-follows">
                  <h5>Posts</h5>
                  <h4>250</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 d-flex align-items-center">
              <div class="follow-btn-group">
                <button type="submit" class="btn btn-info follow-btns">Follow</button>
                <button type="submit" class="btn btn-info message-btns">Message</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
