<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="chat" />
      <!-- /Page Header -->

      <div class="row">
        <div class="col-md-3">
          <div class="bg-white mb-2">
            <!-- <div class="card-header"></div> -->
            <div style="cursor: pointer" class="card-body">
              <ul class="nav nav-tabs nav-tabs-top w-full">
                <li @click="switchView('chat')" lass="nav-item " style="width: 50%">
                  <a class="nav-link active" data-bs-toggle="tab">Chats</a>
                </li>
                <li @click="switchView('contact')" class="nav-item" style="width: 50%">
                  <a class="nav-link" data-bs-toggle="tab">Contacts</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="height: 200px">
        <!--Chat box start-->
        <div v-if="activeView === 'chat'" :class="currentConvo && openDialog ? 'col-xl-4' : 'col-xl-9'" class="d-flex">
          <div class="card chat-box-clinic" style="height: auto; overflow-y: auto">
            <div class="chat-widgets">
              <div class="chat-user-group-head d-flex align-items-center">
                <div class="img-users call-user">
                  <router-link to="/profile"><img src="@/assets/img/profiles/avatar-01.jpg" alt="img" /></router-link>
                  <span class="active-users"></span>
                </div>
                <div class="chat-users user-main">
                  <div class="user-titles">
                    <h5 v-if="user">{{ user.fullName }}</h5>
                    <div class="chat-user-time">
                      <p>{{ user.accountType }}</p>
                    </div>
                  </div>
                  <div class="drop-item chat-menu user-dot-list">
                    <a href="javascript:;" data-bs-toggle="dropdown" aria-expanded="false" class="">
                      <i class="feather-more-vertical"></i>
                    </a>
                    <div class="dropdown-menu" style="">
                      <a class="dropdown-item" href="javascript:;"
                        ><i class="feather-user me-2 text-primary"></i> Profile</a
                      >
                      <a class="dropdown-item" href="javascript:;"
                        ><i class="feather-plus-circle me-2 text-success"></i> Archive</a
                      >
                      <a class="dropdown-item" href="javascript:;"
                        ><i class="feather-trash-2 me-2 text-danger"></i> Delete</a
                      >
                      <a class="dropdown-item" href="javascript:;"
                        ><i class="feather-slash me-2 text-secondary"></i> Block</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="top-liv-search top-chat-search">
                <form>
                  <div class="chat-search">
                    <div class="input-block me-2 mb-0">
                      <input type="text" class="form-control" placeholder="Search here" />
                      <a class="btn"><img src="@/assets/img/icons/search-normal.svg" alt="" /></a>
                    </div>
                    <div @click="switchView('contact')" class="add-search">
                      <a href="javascript:;"><i class="feather-plus"></i></a>
                    </div>
                  </div>
                </form>
              </div>
              <div
                v-for="(key, i) in sortedKeys"
                :key="i"
                :class="key === selectedUser ? 'bg-custom-primary' : undefined"
                class="chat-user-group d-flex align-items-center"
                style="cursor: pointer"
                @click="selectConversation(messages[key], key)"
              >
                <div class="img-users call-user">
                  <router-link to="/profile"><img src="@/assets/img/profiles/avatar-05.jpg" alt="img" /></router-link>
                  <span class="active-users bg-info"></span>
                </div>
                <div class="chat-users">
                  <div class="user-titles d-flex">
                    <h5 :class="key === selectedUser ? 'text-white' : undefined">
                      {{ fetchStaffById(key).Name }}
                    </h5>
                    <div class="chat-user-time">
                      <p
                        v-if="
                          messages[key] && messages[key].length && messages[key][messages[key].length - 1].createdAt
                        "
                        :class="key === selectedUser ? 'text-white' : undefined"
                      >
                        {{ convertTime(messages[key][messages[key].length - 1].createdAt) }}
                      </p>
                    </div>
                  </div>
                  <div class="user-text d-flex">
                    <p
                      v-if="messages[key] && messages[key].length"
                      :class="key === selectedUser ? 'text-white' : undefined"
                    >
                      {{ truncateString(messages[key][messages[key].length - 1].message, 50) }}
                    </p>
                    <div class="chat-user-count">
                      <span>{{ messages[key][messages[key].length - 1].message.length }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Chat box end-->

        <!--Contacts box start-->
        <div
          v-if="activeView === 'contact'"
          :class="currentConvo && openDialog ? 'col-xl-4' : 'col-xl-9'"
          class="d-flex"
        >
          <div class="card chat-box-clinic" style="height: auto; overflow-y: auto">
            <div class="chat-widgets">
              <div class="top-liv-search top-chat-search">
                <form>
                  <div class="chat-search">
                    <div class="input-block me-2 mb-0">
                      <input type="text" class="form-control" placeholder="Search here" />
                      <a class="btn"><img src="@/assets/img/icons/search-normal.svg" alt="" /></a>
                    </div>
                    <div class="add-search">
                      <a href="javascript:;"><i class="feather-plus"></i></a>
                    </div>
                  </div>
                </form>
              </div>
              <div
                v-for="(staff, i) in allStaff"
                :key="i"
                :class="staff.id === selectedUser ? 'bg-custom-primary' : undefined"
                class="chat-user-group d-flex align-items-center"
                @click="selectContact(staff.id)"
                style="cursor: pointer"
              >
                <div class="img-users call-user">
                  <router-link to="/profile"><img src="@/assets/img/profiles/avatar-05.jpg" alt="img" /></router-link>
                  <span class="active-users bg-info"></span>
                </div>
                <div class="chat-users">
                  <div class="user-titles d-flex">
                    <h5 :class="staff.id === selectedUser ? 'text-white' : undefined">
                      {{ staff.Name }}
                    </h5>
                    <div class="chat-user-time">
                      <!-- <p>{{ staff.Department }}</p> -->
                    </div>
                  </div>
                  <div class="user-text d-flex">
                    <p :class="staff.id === selectedUser ? 'text-white' : undefined">
                      {{ staff.Department }}
                    </p>
                    <!-- <div class="chat-user-count">
                      <span>{{ messages[key][messages[key].length - 1].message.length }}</span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Contacts box end-->

        <!--Conversation box start-->
        <div v-show="currentConvo && openDialog" class="col-xl-8" style="height: 100vh; overflow-y: auto">
          <div class="card chat-box">
            <div class="chat-search-group">
              <div class="chat-user-group mb-0 d-flex align-items-center">
                <div class="img-users call-user">
                  <router-link to="/profile"><img src="@/assets/img/profiles/avatar-05.jpg" alt="img" /></router-link>
                  <span class="active-users bg-info"></span>
                </div>
                <div class="chat-users">
                  <div class="user-titles">
                    <h5 style="text-transform: capitalize">
                      {{ fetchStaffById(this.selectedUser).Name }}
                    </h5>
                  </div>
                  <!-- <div class="user-text">
                    <p>Lorem ipsum dolor sit amet...</p>
                  </div> -->
                </div>
              </div>
              <div class="chat-search-list">
                <ul>
                  <li>
                    <router-link to="/call/video-call"
                      ><img src="@/assets/img/icons/chat-icon-01.svg" alt="img"
                    /></router-link>
                  </li>
                  <li>
                    <router-link to="/call/voice-call"
                      ><img src="@/assets/img/icons/chat-icon-02.svg" alt="img"
                    /></router-link>
                  </li>
                  <li>
                    <a href="javascript:;"><img src="@/assets/img/icons/chat-icon-03.svg" alt="img" /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Chat -->
          <div style="position: relative" class="card chat-message-box">
            <div class="card-body p-0">
              <div class="chat-body">
                <ul v-for="convo in currentConvo" :key="convo.createdAt" class="list-unstyled chat-message">
                  <li v-if="user && convo.sender !== user.staffId" class="media d-flex received">
                    <div class="avatar flex-shrink-0">
                      <img
                        src="@/assets/img/profiles/avatar-01.jpg"
                        alt="User Image"
                        class="avatar-img rounded-circle"
                      />
                    </div>
                    <div class="media-body flex-grow-1">
                      <div class="msg-box">
                        <div class="message-sub-box">
                          <!-- <h4>Williams Bruk</h4> -->
                          <p>{{ convo.message }}</p>
                          <span v-if="convo.createdAt"
                            >{{ convertTime(convo.createdAt) }} {{ getRelativeTime(convo.createdAt) }}</span
                          >
                          <!-- <span>{{ getRelativeTime(convo.createdAt) }}</span> -->
                        </div>
                      </div>
                    </div>
                  </li>

                  <li v-else class="media d-flex sent">
                    <div class="media-body flex-grow-1">
                      <div class="msg-box">
                        <div class="message-sub-box">
                          <p>{{ convo.message }}</p>
                          <span v-if="convo.createdAt"
                            >{{ convertTime(convo.createdAt) }} {{ getRelativeTime(convo.createdAt) }}</span
                          >
                          <!-- <span> {{ getRelativeTime(convo.createdAt) }}</span> -->
                        </div>
                      </div>
                    </div>
                  </li>

                  <!-- <li class="media d-flex received">
                    <div class="avatar flex-shrink-0">
                      <img
                        src="@/assets/img/profiles/avatar-03.jpg"
                        alt="User Image"
                        class="avatar-img rounded-circle"
                      />
                    </div>
                    <div class="media-body flex-grow-1">
                      <div class="msg-box">
                        <div class="message-sub-box">
                          <h4>Galaviz Lalema</h4>
                          <p>non tellus dignissim</p>
                          <ul class="msg-sub-list">
                            <li>
                              <img src="@/assets/img/icons/chat-icon-04.svg" alt="" class="me-1" />Explainer
                              Video.avi<span class="ms-1">30.0 MB</span
                              ><img src="@/assets/img/icons/chat-icon-07.svg" alt="" class="ms-1 ms-auto" />
                            </li>
                            <li>
                              <img src="@/assets/img/icons/chat-icon-05.svg" alt="" class="me-1" />Ayush
                              Therapy.mp3<span class="ms-1">4.0 MB</span
                              ><img src="@/assets/img/icons/chat-icon-08.svg" alt="" class="ms-1 ms-auto" />
                            </li>
                            <li>
                              <img src="@/assets/img/icons/chat-icon-06.svg" alt="" class="me-1" />The liver.img<span
                                class="ms-1"
                                >520KB</span
                              >
                            </li>
                          </ul>
                          <span>06:32 PM Yesterday</span>
                        </div>
                      </div>
                    </div>
                  </li> -->
                </ul>
              </div>
              <div class="chat-footer-box">
                <div class="discussion-sent">
                  <div class="row gx-2">
                    <div class="col-lg-12">
                      <div class="footer-discussion">
                        <div ref="msgBox" class="inputgroups">
                          <input
                            v-model="typedMessage"
                            type="text"
                            @keydown.enter="addMessage"
                            placeholder="Type your Message here..."
                          />
                          <div v-show="pickEmoji" style="position: absolute; right: 0; bottom: 0">
                            <EmojiPicker
                              :native="true"
                              @select="onSelectEmoji"
                              disable-skin-tones
                              hide-group-names
                              :display-recent="true"
                            />
                          </div>
                          <!-- <div style="cursor: pointer" class="micro-text position-icon">
                            <img src="@/assets/img/icons/chat-foot-icon-04.svg" alt="" />
                          </div> -->
                          <div @click="addMessage" style="cursor: pointer" class="send-chat position-icon comman-flex">
                            <a href="javascript:;">
                              <img src="@/assets/img/icons/chat-foot-icon-03.svg" alt="" />
                            </a>
                          </div>
                          <div class="symple-text position-icon">
                            <ul>
                              <!-- <li>
                                <a href="javascript:;">
                                  <img src="@/assets/img/icons/chat-foot-icon-01.svg" class="me-2" alt="" />
                                </a>
                              </li> -->
                              <li style="cursor: pointer" @click="pickEmoji = true">
                                <a href="javascript:;">
                                  <img src="@/assets/img/icons/chat-foot-icon-02.svg" alt="" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Chat -->
        </div>

        <!--Conversation box end-->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { router } from "@/router";
import { useProfileStore } from "@/stores/profile.js";
import { chatLogic } from "@/composables/chatLogic";
import { helperFunctions } from "@/composables/helperFunctions";
import { createToaster } from "@meforma/vue-toaster";

// import picker compopnent
import EmojiPicker from "vue3-emoji-picker";

// import css
import "vue3-emoji-picker/css";

const toaster = createToaster({
  position: "top-right",
  showCloseButton: true,
  duration: 5000,
});
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
  setup() {
    const profile = useProfileStore();
    const { createUser, readUser } = profile;
    const user = JSON.parse(window.localStorage.getItem("user"));
    const { groupChatMessages } = chatLogic;
    const { convertTime, truncateString, formatDateString, getRelativeTime } = helperFunctions;

    const msgBox = ref(null);
    const typedMessage = ref("");
    const allMessages = computed(() => profile.getAllMessages);

    const messages = ref({});
    const sortedKeys = ref([]);
    function moveUp() {
      const element = msgBox.value;
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
        return;
      }
    }
    profile.$subscribe((mutation, state) => {
      // import { MutationType } from 'pinia'
      // mutation.type // 'direct' | 'patch object' | 'patch function'
      // same as cartStore.$id
      // mutation.storeId // 'cart'
      // only available with mutation.type === 'patch object'
      // patch object passed to cartStore.$patch()

      if (state.messages) {
        const { groupedConversations, sortedKeys: keys } = groupChatMessages(state.messages);
        messages.value = groupedConversations;
        sortedKeys.value = [...keys];

        // console.log(messages.value, "<<message>>", sortedKeys.value, "<<sortedKeys>");
      }

      // persist the whole state to the local storage whenever it changes
      // localStorage.setItem("cart", JSON.stringify(state));
    });

    const allStaff = computed(() => {
      const data =
        profile.getAllStaff &&
        profile.getAllStaff.map((item) => {
          return {
            id: item._id,
            Name: `${item.personalInformation.firstName} ${item.personalInformation.lastName}`,
            StaffID: item.personalInformation?.userName || "N/A",
            Department: (item.department && item.department.name) || "N/A",

            Degree: item.educationInformation[0]?.degree || "N/A",
            Mobile: item.contactInformation?.phoneNumber,
            Email: item.contactInformation?.email,
            JoiningDate: formatDateString(item.dateOfJoining),
            Image: "avatar-01.jpg",
          };
        });

      return data;
    });
    const pickEmoji = ref(false);
    function onSelectEmoji(emoji) {
      typedMessage.value += emoji.i;
      pickEmoji.value = false;
    }

    return {
      pickEmoji,
      typedMessage,
      createUser,
      user,
      readUser,
      allStaff,
      allMessages,
      messages,
      sortedKeys,
      msgBox,
      moveUp,
      groupChatMessages,
      convertTime,
      truncateString,
      formatDateString,
      getRelativeTime,
      onSelectEmoji,
    };
  },
  components: {
    EmojiPicker,
  },
  data() {
    return {
      title: "App",
      text: "Chat",

      currentConvo: [],
      selectedUser: "",
      activeView: "",

      openDialog: false,
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      Loss: ["Select Leave Type", "Medical Leave", "Casual Leave", "Loss of Pay"],
      args: {
        leaveType: "",
        staff: this.user && this.user.staffId,
        startDate: currentDate,
        endDate: currentDateOne,
        reason: "",
        status: "PENDING",
      },
      daysNumber: 0,
      chatMessages: [
        {
          from: "User1",
          to: "User2",
          message: "Hello!",
          createdAt: "2024-03-29T10:00:00",
        },
        {
          from: "User2",
          to: "User1",
          message: "Hi there!",
          createdAt: "2024-03-29T10:01:00",
        },
        {
          from: "User1",
          to: "User3",
          message: "How are you?",
          createdAt: "2024-03-29T10:02:00",
        },
        {
          from: "User3",
          to: "User1",
          message: "I'm good, thanks!",
          createdAt: "2024-03-30T08:03:00",
        },
        {
          from: "User1",
          to: "User2",
          message: "That's great to hear!",
          createdAt: "2024-03-29T10:04:00",
        },
        {
          from: "User2",
          to: "User1",
          message: "Yeah!",
          createdAt: "2024-03-29T10:05:00",
        },
      ],
    };
  },
  computed: {
    numberOfDays() {
      if (!this.args.startDate || !this.args.endDate) {
        return;
      }
      // const date1 = new Date(this.args.startDate);
      // const date2 = new Date(this.args.endDate);
      // const diffTime = Math.abs(date2 - date1);
      // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      // return diffDays || 0;

      let count = 0;
      let currentDate = new Date(this.args.startDate);
      let endDate = new Date(this.args.endDate);

      while (currentDate <= endDate) {
        const dayOfWeek = currentDate.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          // Exclude Sunday (0) and Saturday (6)
          count++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return count;
    },
  },
  methods: {
    addMessage() {
      const newMsg = {
        receiver: this.selectedUser,
        message: this.typedMessage,
        sender: this.user && this.user.staffId,
      };
      // if (this.currentConvo.length === 0) {
      //   this.currentConvo.push(newMsg);
      // }
      this.typedMessage = "";
      if (!newMsg.message || newMsg.message.trim() === "") return;
      this.createUser({}, { input: newMsg }, "SendMessage");
    },
    async scrollToSection() {
      // Get a reference to the section element
      const section = this.$refs.msgBox;

      // Ensure the section element exists
      if (section) {
        // Scroll to the section using scrollIntoView

        await this.$nextTick(() => section.scrollIntoView({ behavior: "smooth", block: "center" }));

        // this.$nextTick(() => section.scrollIntoView({ behavior: "smooth" }));
      }
    },
    switchView(view) {
      this.currentConvo = [];
      this.openDialog = false;
      this.activeView = view;
    },
    selectContact(contact) {
      // console.log(contact);
      const data = this.sortedKeys.find((key) => key === contact);
      this.selectConversation(this.messages[data] || [], contact);
    },
    selectConversation(convo, user) {
      // console.log(convo, "convo");
      this.currentConvo = convo;
      this.selectedUser = user;
      this.scrollToSection();
      this.openDialog = true;
    },
    fetchStaffById(id) {
      // console.log(id, "id");
      if (!id) return { Name: "" };
      const staff = this.allStaff.find((item) => item.id === id);
      return staff || { Name: "" };
    },

    async readAllStaff() {
      try {
        const res = await this.readUser({}, "GetAllStaff", "staffs");
        if (res) {
          // console.log(res);
          // toaster.success(`Staff read successfully.`);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },

    async fetchAllMessages() {
      try {
        const res = await this.readUser({}, "GetAllMessage", "messages");
        if (res) {
          // console.log(res);
          // toaster.success(`Staff read successfully.`);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },

    async createLeave() {
      try {
        const res = await this.createUser({}, { input: this.args }, "CreateLeave");
        if (res) {
          toaster.success(`Appointment created successfully.`);

          router.push("/appointments/appointments-list");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  async created() {
    this.messages = [];
    this.sortedKeys = [];
    await this.fetchAllMessages();
    const { groupedConversations, sortedKeys } = this.groupChatMessages(this.allMessages);
    this.messages = groupedConversations;
    this.sortedKeys = [...sortedKeys];
    // console.log(this.sortedKeys, "skss");
    this.activeView = "chat";
    await this.readAllStaff();
  },
};
</script>
