<template>
  <div v-if="all" style="width: 100%">
    <div class="action">
      <b-button
        id="scrollable-modal-button"
        variant="primary mt-1"
        data-bs-toggle="modal"
        data-bs-target="#scrollable-modal"
      >
        Create new
      </b-button>
    </div>
    <vue-excel-editor
      width="4000%"
      height="100%"
      v-model="jsonData"
      :row-style="() => 'width: 900px'"
      :cellStyle="() => 'width: 900px'"
      filter-row
    />

    <!-- Long Content Scroll Modal -->
    <div
      class="modal fade"
      id="scrollable-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="scrollableModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <form style="width: 800px" class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="scrollableModalTitle">Define fields</h4>
            <button class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="personal-list-out">
              <!-- <h4>Personal Information</h4> -->
              <div class="row">
                <div class="mt-2 mb-4 col-xl-12 col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div v-for="(field, i) in inputFields" :key="i" class="row">
                        <!-- <div class="col-12">
                          <div class="form-heading">
                            <h4>Task Details</h4>
                          </div>
                        </div> -->
                        <div class="col-12 col-md-6 col-xl-6">
                          <div class="input-block local-forms">
                            <label>Field {{ i + 1 }} <span class="login-danger">*</span></label>
                            <input v-model="field.name" class="form-control" type="text" placeholder="" />
                          </div>
                        </div>

                        <div class="col-12 col-md-6 col-xl-6">
                          <div class="input-block local-forms">
                            <label>Type <span class="login-danger">*</span></label>
                            <vue-select
                              v-model="field.type"
                              :options="fieldTypes"
                              id="input-type"
                              placeholder="Choose field type"
                            />
                          </div>
                        </div>

                        <div v-if="field.type == 'select'" class="col-12 col-md-6 col-xl-6">
                          <div class="input-block local-forms">
                            <label>Type <span class="login-danger">*</span></label>
                            <vue-select
                              v-model="field.options"
                              :options="fieldTypes"
                              id="input-type"
                              placeholder="Choose field type"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <b-button variant="secondary me-1" data-bs-dismiss="modal"> Close </b-button>
            <b-button variant="primary"> Verify Details </b-button>
          </div>
        </form>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
  </div>
  <main>
    <div v-if="showAlert" class="row">
      <div class="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Holy guacamole!</strong> You should check in on some of those fields below.
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    </div>
    <div class="row">
      <div style="margin-bottom: 10px; position: relative" class="col-12 gap-5">
        <input
          style="position: absolute; bottom: -10; text-transform: capitalize; border: none; background: none"
          type="text"
          v-model="args.docName"
        />

        <div class="doctor-submit text-end">
          <button @click="$emit('share')" style="border: none; background: none" class="icons-items" type="button">
            <ul class="list-unstyled icons-list">
              <li>
                <i class="fa fa-share-alt"></i>
              </li>
            </ul>
          </button>
          <button
            @click="openFullscreen"
            style="border: none; background: none; text-align: right"
            class="icons-items"
            type="button"
          >
            <ul class="list-unstyled icons-list">
              <li>
                <i class="fa fa-expand"></i>
              </li>
            </ul>
          </button>
          <button type="button" class="btn btn-primary cancel-form me-2">Cancel</button>
          <button @click="saveData" name="submitbtn" type="submit" class="btn btn-primary submit-form">Save</button>
        </div>
      </div>
    </div>
    <div ref="doc" id="x-spreadsheet-demo"></div>
  </main>
</template>

<!-- <script>
export default {
  data() {
    return {
      jsonData: [],
      inputFields: [{ name: "", type: "", options: [] }],
      fieldTypes: ["text", "number", "select"],
    };
  },
  methods: {
    toExcelColumn(col) {
      let letters = "";
      while (col > 0) {
        let remainder = (col - 1) % 26;
        letters = String.fromCharCode(65 + remainder) + letters;
        col = Math.floor((col - remainder) / 26);
      }
      return letters;
    },
    //  generateRow(numCells) {
    //         const row = {};
    //         let column = 0;
    //         for (let i = 0; i < numCells; i++) {
    //             let columnName = '';
    //             let remainder = i % 26;
    //             if (i >= 26) {
    //                 columnName += String.fromCharCode(65 + Math.floor(i / 26) - 1);
    //             }
    //             columnName += String.fromCharCode(65 + remainder);
    //             row[columnName] = ''; // Initialize cell with empty string
    //         }
    //         return row;
    //     }
    generateRow(numCells) {
      const row = {};
      for (let i = 0; i < numCells; i++) {
        let columnName = "";
        let remainder = i % 26;
        if (i >= 26) {
          columnName += String.fromCharCode(65 + Math.floor(i / 26) - 1);
        }
        columnName += String.fromCharCode(65 + remainder);
        row[columnName] = ""; // Initialize cell with empty string
      }
      return row;
    },
  },
  created() {
    console.log(this.generateRow(50), "row");
  },
};
</script> -->

<script>
import Spreadsheet from "x-data-spreadsheet";
import { useProfileStore } from "@/stores/profile.js";
import { createToaster } from "@meforma/vue-toaster";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";

export default {
  setup() {
    const profile = useProfileStore();
    const { createUser, readUser } = profile;
    const router = useRouter();
    const route = useRoute();
    const toaster = createToaster({
      position: "top-right",
      showCloseButton: true,
      duration: 5000,
    });
    const args = ref({
      docType: "xlsx",
      docNumber: "",
      docName: "untitled",
      content: { xlsx: [] },
      // access: [
      //   {
      //     staffId: "",
      //     accessType: "",
      //   },
      // ],
    });
    const isChanged = ref(false);
    async function createDoc() {
      try {
        const res = await createUser({}, { input: args.value }, "CreateDocument");
        if (res) {
          isChanged.value = false;
          toaster.success(`FIle saved successfully.`);
          router.push({
            path: "/docs/edit-doc",
            query: { id: res._id, type: res.docType },
          });

          // router.push("/departments/department-list");
        }
      } catch (error) {
        console.log(error);
      }
    }
    async function editDoc() {
      try {
        const res = await createUser({}, { updateDocumentId: route.query.id, input: args }, "UpdateDocument");
        if (res) {
          isChanged.value = false;
          toaster.success(`FIle saved successfully.`);

          // router.push("/departments/department-list");
        }
      } catch (error) {
        console.log(error);
      }
    }
    // const allDocuments = computed(() => profile.getDocuments);
    const singleDoc = ref({});
    async function fetchDoc() {
      try {
        const res = await readUser({ fetchSingleDocId: route.query.id }, "FetchSingleDoc", null);
        if (res) {
          singleDoc.value = res;
        }
      } catch (error) {
        console.log(error);
      }
    }
    // const loadedData = ref([
    //   {
    //     name: "sheet1",
    //     freeze: "A1",
    //     styles: [],
    //     merges: [],
    //     rows: {},
    //     cols: {
    //       len: 26,
    //     },
    //     validations: [],
    //     autofilter: {},
    //   },
    // ]);

    // onMounted(async () => {
    //   if (route.query.id) {
    //     await fetchDoc();
    //     args.docType = singleDoc.value.docType;
    //     args.docNumber = singleDoc.value.docNumber;
    //     args.docName = singleDoc.value.docName;
    //     args.content = singleDoc.value.content;
    //     args.access = singleDoc.value.access;
    //     loadedData.value = args.content.xlsx;
    //     console.log(loadedData.value, "single-doc");
    //   }
    // });
    const showAlert = ref(false);
    onBeforeRouteLeave((to, from, next) => {
      if (isChanged.value) {
        const answer = window.confirm("You have unsaved changes. Do you really want to leave?");
        if (answer) {
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    });

    return {
      isChanged,
      createDoc,
      editDoc,
      args,
      singleDoc,
      // loadedData,
      showAlert,
      fetchDoc,
    };
  },
  data() {
    return {
      all: false,
      s: {},
      loadedData: [
        {
          name: "sheet1",
          freeze: "A1",
          styles: [],
          merges: [],
          rows: {},
          cols: {
            len: 26,
          },
          validations: [],
          autofilter: {},
        },
      ],
    };
  },
  methods: {
    /* View in fullscreen */
    openFullscreen() {
      const elem = document.getElementById("x-spreadsheet-demo");

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    transformSpreadsheetData(sheet) {
      const transformed = [];

      const rowObjects = [];
      const rows = sheet.rows;
      const rowCount = rows.len;

      for (let i = 0; i < rowCount; i++) {
        if (rows[i] && rows[i].cells) {
          const row = {};
          const cells = rows[i].cells;
          for (const cellKey in cells) {
            if (cells.hasOwnProperty(cellKey)) {
              row[`cell_${cellKey}`] = cells[cellKey].text;
            }
          }
          rowObjects.push(row);
        }
      }
      transformed.push(...rowObjects);

      return transformed;
    },
    storeDataInArgs(data) {
      let found = false;
      for (let i = 0; i < this.args.content.xlsx.length; i++) {
        if (this.args.content.xlsx[i].name == data.name) {
          this.args.content.xlsx[i] = data;
          found = true;
        }
      }
      if (!found) {
        this.args.content.xlsx.push(data);
      }
    },
    async saveData() {
      this.args.content.xlsx = this.s.getData();

      try {
        if (this.$route.query.id) {
          await this.editDoc();
        } else {
          await this.createDoc();
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    const el_id = document.getElementById("x-spreadsheet-demo");
    if (this.$route.query && this.$route.query.id) {
      await this.fetchDoc();
      this.args.docType = this.singleDoc.docType;
      this.args.docNumber = this.singleDoc.docNumber;
      this.args.docName = this.singleDoc.docName;
      this.args.content = this.singleDoc.content;
      this.args.access = this.singleDoc.access;
      this.loadedData = this.args.content.xlsx.map((item) => item);
    }
    this.s = new Spreadsheet(el_id, {
      mode: "edit", // edit | read
      showToolbar: true,
      showGrid: true,
      showContextmenu: true,
      view: {
        height: () => document.documentElement.clientHeight,
        width: () => document.documentElement.clientWidth / 1.1,
      },
      row: {
        len: 100,
        height: 25,
      },
      col: {
        len: 26,
        width: 100,
        indexWidth: 60,
        minWidth: 60,
      },
      style: {
        bgcolor: "#ffffff",
        align: "left",
        valign: "middle",
        textwrap: false,
        strike: false,
        underline: false,
        color: "#0a0a0a",
        font: {
          name: "Helvetica",
          size: 10,
          bold: false,
          italic: false,
        },
      },
    })
      .loadData(this.loadedData) // load data
      .change((data) => {
        this.isChanged = true;
        // console.log(this.transformSpreadsheetData(data));
        // console.log(data);
        // this.storeDataInArgs(data);
        // save data to db
      });

    // data validation
    this.s.validate();
    // this.openFullscreen();
  },
};

// const doc = ref(null);
// If you need to override the default options, you can set the override
// const options = {};
// new Spreadsheet('#x-spreadsheet-demo', options);
</script>

<style scoped>
.action {
  display: flex;
  gap: 20px;
  align-items: center;
}
</style>
