const benefits = [
  {
    label: "PAYE",
    value: "paye",
  },
  {
    label: "Pension",
    value: "pension",
  },
  {
    label: "HMO",
    value: "hmo",
  },
  {
    label: "Life Insurance",
    value: "lifeInsurance",
  },
  {
    label: "Life Assurance",
    value: "lifeAssurance",
  },
  {
    label: "WHT",
    value: "wht",
  },
  {
    label: "NSITF",
    value: "nsitf",
  },
  {
    label: "ITF",
    value: "itf",
  },
  // {
  //   label: "Gratuity",
  //   value: "gratuity",
  // },
  {
    label: "NHF",
    value: "nhf",
  },
  // {
  //   label: "Business Expense",
  //   value: "businessExpense",
  // },
];
export default benefits;
