<template>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <breadcrumb :title="title" :text="text" path="/dashboard" />
      <div class="row">
        <!-- Editor -->
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">Editor</h5>
            </div>
            <div class="card-body">
              <editor api-key="qbuwhmfmq7n3lbo04fxnroyl623by920cmrotjyhe1v6vvwy" v-model="content" />
            </div>
          </div>
        </div>
        <!-- /Editor -->
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  components: {
    editor: Editor,
  },
  data() {
    return {
      title: "Dashboard",
      content: "Description",
    };
  },
  name: "text-editor",
};
</script>
