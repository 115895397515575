<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="staff-list" />
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card card-table show-entire">
            <div class="card-body">
              <!-- Table Header -->
              <div class="page-table-header mb-2">
                <div class="row align-items-center">
                  <div class="col">
                    <div class="doctor-table-blk">
                      <h3>Attandance Sheet</h3>
                      <div class="doctor-search-blk">
                        <div class="top-nav-search table-search-blk">
                          <form>
                            <input type="text" class="form-control" placeholder="Search here" />
                            <a class="btn"><img src="@/assets/img/icons/search-normal.svg" alt="" /></a>
                          </form>
                        </div>
                        <div class="add-group">
                          <a href="javascript:;" class="btn btn-primary add-pluss ms-2"
                            ><img src="@/assets/img/icons/plus.svg" alt=""
                          /></a>
                          <a href="javascript:;" class="btn btn-primary doctor-refresh ms-2"
                            ><img src="@/assets/img/icons/re-fresh.svg" alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-end float-end ms-auto download-grp">
                    <a href="javascript:;" class="me-2"><img src="@/assets/img/icons/pdf-icon-01.svg" alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="@/assets/img/icons/pdf-icon-02.svg" alt="" /></a>
                    <a href="javascript:;" class="me-2"><img src="@/assets/img/icons/pdf-icon-03.svg" alt="" /></a>
                    <a href="javascript:;"><img src="@/assets/img/icons/pdf-icon-04.svg" alt="" /></a>
                  </div>
                </div>
              </div>
              <!-- /Table Header -->
              <div class="staff-search-table">
                <form>
                  <div class="row">
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms">
                        <label>Employee Name </label>
                        <input class="form-control" type="text" />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms">
                        <label>Year </label>
                        <vue-select :options="Years" id="selectyear" placeholder="Select Year" />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms">
                        <label>Month </label>
                        <vue-select :options="Month" id="selectmonth" placeholder="Select Month" />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms cal-icon">
                        <label>From </label>
                        <datepicker
                          v-model="startdate"
                          :input-format="dateFormat"
                          class="form-control datetimepicker"
                          :editable="true"
                          :clearable="false"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="input-block local-forms cal-icon">
                        <label>To </label>
                        <datepicker
                          v-model="startdateOne"
                          :input-format="dateFormat"
                          class="form-control datetimepicker"
                          :editable="true"
                          :clearable="false"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                      <div class="doctor-submit">
                        <button type="submit" class="btn btn-primary submit-list-form me-2">Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="table-responsive">
                <table class="table border-0 custom-table attent-table datatable mb-0">
                  <thead>
                    <tr>
                      <th
                        v-for="header in headers"
                        :key="header"
                        :class="isDayWeekend(header) ? 'week-days' : undefined"
                      >
                        {{ typeof header === "string" ? header : String(header).padStart(2, "0") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key) in staffAttendance" :key="key">
                      <td class="month-table">
                        <h5>{{ fetchStaffById(key) }}</h5>
                      </td>
                      <td v-for="i in value" :key="i" @click="markAttendance(key, i.date)">
                        <span
                          :class="
                            i.isWeekend
                              ? undefined
                              : checkIfPresent(key, i.date)
                              ? 'present-table attent-status'
                              : 'absent-table attent-status'
                          "
                          ><i
                            v-if="!i.isWeekend"
                            :class="checkIfPresent(key, i.date) ? 'feather-check' : 'feather-x'"
                          ></i
                        ></span>
                        <!-- <span class="present-table attent-status"><i class="feather-check"></i></span> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useProfileStore } from "@/stores/profile.js";

import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-right",
  showCloseButton: true,
  duration: 5000,
});
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentMonth = currentDate.value.getMonth();
const currentYear = currentDate.value.getFullYear();
export default {
  setup() {
    const profile = useProfileStore();
    const { readUser, createUser } = profile;

    const allAttendance = computed(() => {
      return profile.getAllAttendance;
    });
    const allStaff = computed(() => {
      return profile.getAllStaff;
    });

    profile.$subscribe((mutation, state) => {
      if (state.attendance && state.isLoading.length === 0) {
        readUser({}, "GetAllAttendance", "attendance");
      }

      // persist the whole state to the local storage whenever it changes
      // localStorage.setItem("cart", JSON.stringify(state));
    });

    return {
      readUser,
      allAttendance,
      allStaff,
      createUser,
    };
  },
  data() {
    return {
      title: "Staffs",
      text: "Attandance Sheet",
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      Years: ["Select Year", "2022", "2021", "2020", "2019", "2018", "2017"],
      Month: ["Select Month", "December", "November", "October", "September"],
      dateArray: [],
      staffAttendance: {},
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      headers: [],
      args: { staff: "", month: currentMonth, year: currentYear, day: "", date: "", isPresent: false },
    };
  },
  methods: {
    checkIfPresent(emp, dte) {
      const data = this.allAttendance.find(
        (att) =>
          att.staff._id === emp &&
          att.month === currentMonth &&
          att.year === currentYear &&
          att.day === new Date(dte).getDate()
      );
      if (!data) return false;
      return data.isPresent;
    },
    async markAttendance(emp, dt) {
      this.args.staff = emp;
      this.args.date = dt;
      this.args.day = new Date().getDate();
      this.args.isPresent = true;
      try {
        const res = await this.createUser({}, { input: this.args }, "CreateAttendance");
        if (res) {
          await this.readAllAttendance();
          toaster.success(`Attendance logged successfully.`);

          // router.push("/staff/staff-leave");
        }
      } catch (error) {
        console.log(error);
      }
    },
    fetchStaffById(id) {
      if (!id) return {};
      const staff = this.allStaff.find((item) => item._id === id);
      return staff ? `${staff.personalInformation.firstName} ${staff.personalInformation.lastName}` : "";
    },
    getDateNameAndNumber(dateString) {
      const date = new Date(dateString);

      // Get the name of the day
      const dayName = date.toLocaleString("en-US", { weekday: "long" });

      // Get the day of the month
      const dayOfMonth = date.getDate();
      return { dayName, dayOfMonth };
    },
    getWorkingDaysInMonth(year, month) {
      const result = [];
      const startDate = new Date(year, month - 1, 1); // Month is zero-based
      const endDate = new Date(year, month, 0); // Get the last day of the month

      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        const dayOfWeek = currentDate.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          // Exclude Sunday (0) and Saturday (6)
          result.push({
            date: new Date(currentDate),
            isWeekend: false,
          });
        } else {
          result.push({
            date: new Date(currentDate),
            isWeekend: true,
          });
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return result;
    },
    async readAllAttendance() {
      try {
        const res = await this.readUser({}, "GetAllAttendance", "attendance");
        if (res) {
          console.log(res);
          // toaster.success(`Staff read successfully.`);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
    async readAllStaff() {
      try {
        const res = await this.readUser({}, "GetAllStaff", "staffs");
        if (res) {
          console.log(res);
          // toaster.success(`Staff read successfully.`);
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
    isDayWeekend(day) {
      const dayOfWeek = this.dateArray.find((date) => date.date.getDate() === day);
      if (!dayOfWeek) {
        return false;
      }
      return dayOfWeek.isWeekend;
    },
  },

  async created() {
    await this.readAllAttendance();
    await this.readAllStaff();
    //get current month and current year

    this.dateArray = this.getWorkingDaysInMonth(currentYear, currentMonth + 1);
    const staffAttendance = {};
    this.allStaff.forEach((staff) => {
      const staffId = staff._id;
      if (!staffAttendance[staffId]) {
        staffAttendance[staffId] = [];
      }
      staffAttendance[staffId] = this.dateArray;
    });
    this.staffAttendance = staffAttendance;
    // console.log(this.staffAttendance, "staffAttendance");

    //get headers for the table
    this.headers = [this.months[currentMonth]];
    const headers = this.dateArray.map((date) => {
      const { dayOfMonth } = this.getDateNameAndNumber(date.date);
      return dayOfMonth;
    });

    this.headers = [...this.headers, ...headers];
  },
};
</script>
