<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="title" :text="text" path="staff-list" />
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="about-info">
                    <h4>
                      Staffs Profile
                      <span
                        ><a href="javascript:;"><i class="feather-more-vertical"></i></a
                      ></span>
                    </h4>
                  </div>
                  <div class="doctor-profile-head">
                    <div class="profile-bg-img">
                      <img src="@/assets/img/profile-bg.jpg" alt="Profile" />
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-4">
                        <div class="profile-user-box">
                          <div class="profile-user-img">
                            <img src="@/assets/img/profile-user-02.jpg" alt="Profile" />
                            <div class="input-block doctor-up-files profile-edit-icon mb-0">
                              <div class="uplod d-flex">
                                <label class="file-upload profile-upbtn mb-0">
                                  <img src="@/assets/img/icons/camera-icon.svg" alt="Profile" /><input type="file" />
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="names-profiles">
                            <h4>
                              {{ staffArgs.personalInformation.firstName }}
                              {{ staffArgs.personalInformation.lastName }}
                            </h4>
                            <h5>{{ staffArgs.position }}</h5>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-lg-4 col-md-4 d-flex align-items-center">
                        <div class="follow-group">
                          <div class="doctor-follows">
                            <h5>Followers</h5>
                            <h4>850</h4>
                          </div>
                          <div class="doctor-follows">
                            <h5>Following</h5>
                            <h4>18K</h4>
                          </div>
                          <div class="doctor-follows">
                            <h5>Posts</h5>
                            <h4>250</h4>
                          </div>
                        </div>
                      </div> -->
                      <div class="col-lg-4 col-md-4 d-flex align-items-center">
                        <div class="follow-btn-group">
                          <button
                            @click="
                              $router.push({
                                name: 'EditStaff',
                                query: { id: $route.query.id },
                              })
                            "
                            type="submit"
                            class="btn btn-info follow-btns"
                          >
                            Edit Profile
                          </button>
                          <button type="submit" class="btn btn-info message-btns">Message</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class="doctor-personals-grp">
                <div class="card">
                  <div class="card-body">
                    <div class="heading-detail">
                      <h4 class="mb-3">About me</h4>
                      <p>
                        {{ staffArgs.personalInformation.aboutMe }}
                      </p>
                    </div>
                    <div class="about-me-list">
                      <ul class="list-space">
                        <li>
                          <h4>Gender</h4>
                          <span>{{ staffArgs.personalInformation.gender }}</span>
                        </li>
                        <!-- <li>
                          <h4>Operation Done</h4>
                          <span>30+</span>
                        </li> -->
                        <li>
                          <h4>Designation</h4>
                          <span>{{ staffArgs.position }}</span>
                        </li>
                        <li>
                          <h4>Staff ID</h4>
                          <span>{{ staffArgs.personalInformation.userName }}</span>
                        </li>
                        <li>
                          <h4>Date of Birth</h4>
                          <span>{{
                            formatDateString(new Date(staffArgs.personalInformation.birthDate).toISOString(), "name")
                          }}</span>
                        </li>
                        <li>
                          <h4>Net Salary</h4>
                          <span>{{ formatAmount(staffArgs && staffArgs.salary && staffArgs.salary.grossSalary) }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="doctor-personals-grp">
                <div class="card">
                  <div class="card-body">
                    <div class="heading-detail">
                      <h4>Skills:</h4>
                    </div>
                    <div class="skill-blk">
                      <div class="skill-statistics">
                        <div class="skills-head">
                          <h5>Operations</h5>
                          <p>45%</p>
                        </div>
                        <div class="progress mb-0">
                          <div
                            class="progress-bar bg-operations"
                            role="progressbar"
                            style="width: 45%"
                            aria-valuenow="45"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="skill-statistics">
                        <div class="skills-head">
                          <h5>Patient Care</h5>
                          <p>85%</p>
                        </div>
                        <div class="progress mb-0">
                          <div
                            class="progress-bar bg-statistics"
                            role="progressbar"
                            style="width: 85%"
                            aria-valuenow="85"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="skill-statistics">
                        <div class="skills-head">
                          <h5>Endoscopic</h5>
                          <p>65%</p>
                        </div>
                        <div class="progress mb-0">
                          <div
                            class="progress-bar bg-endoscopic"
                            role="progressbar"
                            style="width: 65%"
                            aria-valuenow="65"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="skill-statistics">
                        <div class="skills-head">
                          <h5>Patient Visit</h5>
                          <p>90%</p>
                        </div>
                        <div class="progress mb-0">
                          <div
                            class="progress-bar bg-visit"
                            role="progressbar"
                            style="width: 90%"
                            aria-valuenow="90"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="doctor-personals-grp">
                <div class="card">
                  <div class="card-body">
                    <div class="heading-detail">
                      <h4>Speciality</h4>
                    </div>
                    <div class="personal-activity">
                      <div class="personal-icons status-grey">
                        <img src="@/assets/img/icons/medal-01.svg" alt="" />
                      </div>
                      <div class="views-personal">
                        <h4>Proffesionals</h4>
                        <h5>Certified Skin Treatment</h5>
                      </div>
                    </div>
                    <div class="personal-activity">
                      <div class="personal-icons status-green">
                        <img src="@/assets/img/icons/medal-02.svg" alt="" />
                      </div>
                      <div class="views-personal">
                        <h4>Certified</h4>
                        <h5>Cold Laser Operation</h5>
                      </div>
                    </div>
                    <div class="personal-activity mb-0">
                      <div class="personal-icons status-orange">
                        <img src="@/assets/img/icons/medal-03.svg" alt="" />
                      </div>
                      <div class="views-personal">
                        <h4>Medication Laser</h4>
                        <h5>Hair Lose Product</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="col-lg-8">
              <div class="doctor-personals-grp">
                <div class="card">
                  <div class="card-body">
                    <div class="tab-content-set">
                      <ul class="nav">
                        <li>
                          <router-link to="staff-profile" class="active"
                            ><span class="set-about-icon me-2"
                              ><img src="@/assets/img/icons/menu-icon-02.svg" alt="" /></span
                            >About me</router-link
                          >
                        </li>
                        <!-- <li>
                          <router-link to="staff-setting"
                            ><span class="set-about-icon me-2"
                              ><img
                                src="@/assets/img/icons/menu-icon-16.svg"
                                alt="" /></span
                            >Settings</router-link
                          >
                        </li> -->
                      </ul>
                    </div>
                    <div class="personal-list-out">
                      <div class="row">
                        <div class="col-xl-4 col-md-6">
                          <div class="detail-personal">
                            <h2>Full Name</h2>
                            <h3>
                              {{ staffArgs.personalInformation.firstName }}
                              {{ staffArgs.personalInformation.lastName }}
                            </h3>
                          </div>
                        </div>
                        <div class="col-xl-3 col-md-6">
                          <div class="detail-personal">
                            <h2>Mobile</h2>
                            <h3>
                              {{ staffArgs.contactInformation.phoneNumber }}
                            </h3>
                          </div>
                        </div>
                        <div class="col-xl-4 col-md-6">
                          <div class="detail-personal">
                            <h2>Email</h2>
                            <h3>{{ staffArgs.contactInformation.email }}</h3>
                          </div>
                        </div>
                        <div style="margin-top: 20px" class="col-xl-6 col-md-6">
                          <div class="detail-personal">
                            <h2>Location</h2>
                            <h3>{{ staffArgs.contactInformation.address }}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="hello-park">
                      <p>
                        Completed my graduation in Gynaecologist Medicine from
                        the well known and renowned institution of India –
                        SARDAR PATEL MEDICAL COLLEGE, BARODA in 2000-01, which
                        was affiliated to M.S. University. I ranker in
                        University exams from the same university from 1996-01.
                      </p>
                      <p>
                        Worked as Professor and Head of the department ;
                        Community medicine Department at Sterline Hospital,
                        Rajkot, Gujarat from 2003-2015
                      </p>
                    </div> -->

                    <div class="hello-park mb-2">
                      <h5>Education</h5>
                      <div class="table-responsive">
                        <table class="table mb-0 border-0 custom-table profile-table">
                          <thead>
                            <th>Year</th>
                            <th>Degree</th>
                            <th>Institute</th>
                            <th>Result</th>
                          </thead>
                          <tbody>
                            <tr v-for="(ed, i) in staffArgs.educationInformation" :key="i">
                              <td>
                                {{ formatDateString(new Date(ed.startYear).toISOString()) }}
                                -
                                {{ formatDateString(new Date(ed.graduationYear).toISOString()) }}
                              </td>
                              <td>{{ ed.degree }}</td>
                              <td>{{ ed.university }}</td>
                              <td>
                                <button class="custom-badge status-green">
                                  {{ ed.result }}
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- <div class="hello-park mb-2">
                      <h5>Experience</h5>
                      <div class="table-responsive">
                        <table
                          class="table mb-0 border-0 custom-table profile-table"
                        >
                          <thead>
                            <th>Year</th>
                            <th>Position</th>
                            <th>Hospital</th>
                            <th>Feedback</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>2002-2005</td>
                              <td>Senior doctor</td>
                              <td>Midtown Medical Clinic</td>
                              <td>
                                <button class="custom-badge status-orange">
                                  Good
                                </button>
                              </td>
                            </tr>
                            <tr>
                              <td>2005-2014</td>
                              <td>Associate Prof.</td>
                              <td>Netherland Medical College</td>
                              <td>
                                <button class="custom-badge status-green">
                                  Excellence
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="hello-park">
                      <h5>Conferences, Cources & Workshop Attended</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </p>
                      <p>
                        Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                      </p>
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam
                      </p>
                      <p class="mb-0">
                        Ut enim ad minima veniam, quis nostrum exercitationem
                        ullam corporis suscipit laboriosam, nisi ut aliquid ex
                        ea commodi consequatur? Quis autem vel eum iure
                        reprehenderit qui in ea voluptate velit esse quam nihil
                        molestiae consequatur
                      </p>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useProfileStore } from "@/stores/profile.js";
import { helperFunctions } from "@/composables/helperFunctions";

const currentDate = new Date();
export default {
  setup() {
    const profile = useProfileStore();
    const { formatDateString, formatAmount } = helperFunctions;
    const { readUser } = profile;

    return {
      readUser,
      formatDateString,
      formatAmount,
    };
  },
  data() {
    return {
      title: "Staffs",
      text: "Staffs Profile",
      staffArgs: {
        personalInformation: {
          firstName: "",
          lastName: "",
          userName: "",
          gender: "",
          aboutMe: "",
          birthDate: currentDate,
        },
        educationInformation: [
          {
            degree: "",
            university: "",
            startYear: currentDate,
            graduationYear: currentDate,
            result: "",
          },
        ],
        contactInformation: {
          address: "",
          phoneNumber: "",
          email: "",
          city: "",
          state: "",
          country: "",
          postalCode: "",
        },
        department: "",
        position: "",
        salary: {},
        dateOfJoining: currentDate,
        accountType: "STAFF",
      },
    };
  },
  methods: {
    async readStaff() {
      try {
        const res = await this.readUser({ getStaffId: this.$route.query.id }, "GetStaff", "singleStaff");
        if (res) {
          const staffData = res;
          this.staffArgs.personalInformation.firstName = staffData.personalInformation.firstName;
          this.staffArgs.personalInformation.lastName = staffData.personalInformation.lastName;
          this.staffArgs.personalInformation.userName = staffData.personalInformation.userName;
          this.staffArgs.personalInformation.gender = staffData.personalInformation.gender;
          this.staffArgs.personalInformation.aboutMe = staffData.personalInformation.aboutMe;
          this.staffArgs.personalInformation.birthDate = new Date(staffData.personalInformation.birthDate);
          this.staffArgs.educationInformation[0].degree = staffData.educationInformation[0].degree;
          this.staffArgs.educationInformation[0].university = staffData.educationInformation[0].university;
          this.staffArgs.educationInformation[0].startYear = new Date(staffData.educationInformation[0].startYear);
          this.staffArgs.educationInformation[0].graduationYear = new Date(
            staffData.educationInformation[0].graduationYear
          );
          // console.log(
          //   this.staffArgs.educationInformation[0].graduationYear,
          //   "year"
          // );
          this.staffArgs.educationInformation[0].result = staffData.educationInformation[0].result;
          this.staffArgs.contactInformation.address = staffData.contactInformation.address;
          this.staffArgs.contactInformation.phoneNumber = staffData.contactInformation.phoneNumber;
          this.staffArgs.contactInformation.email = staffData.contactInformation.email;
          this.staffArgs.contactInformation.city = staffData.contactInformation.city;
          this.staffArgs.contactInformation.state = staffData.contactInformation.state;
          this.staffArgs.contactInformation.country = staffData.contactInformation.country;
          this.staffArgs.contactInformation.postalCode = staffData.contactInformation.postalCode;
          this.staffArgs.department = staffData.department;
          this.staffArgs.position = staffData.position;
          this.staffArgs.salary = staffData.salary;
          this.staffArgs.dateOfJoining = new Date(staffData.dateOfJoining);
          this.staffArgs.accountType = staffData.accountType;
        }
      } catch (error) {
        toaster.error(`Error: ${error}`);
      }
    },
  },
  async created() {
    await this.readStaff();
  },
};
</script>
