const pensionProviders = [
  "AIICO Pension Managers Limited",
  "APT Pension Fund Managers Limited",
  "ARM Pension Managers Limited",
  "CrusaderSterling Pensions Limited",
  "FCMB Pensions Limited",
  "Fidelity Pension Managers",
  "First Guarantee Pension Limited",
  "IEI Anchor Pension Managers Limited",
  "Investment One Pension Managers Limited",
  "Leadway Pensure PFA Limited",
  "Nigerian University Pension Management Company",
  "NLPC Pension Fund Administrators Limited",
  "NPF Pensions Limited",
  "OAK Pensions Limited",
  "Pensions Alliance Limited",
  "Premium Pension Limited",
  "Radix Pension Managers Limited",
  "Sigma Pensions Limited",
  "Stanbic IBTC Pension Managers Limited",
  "Tangerine Pensions Limited",
  "Trustfund Pensions Limited",
  "Veritas Glanvills Pensions Limited",
];

export default pensionProviders;
